import React from 'react'
import ReactDOM from 'react-dom';
import axios from 'axios';
import './styles/main.css';
import ReactTable from 'react-table-6'
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom'
import 'react-table-6/react-table.css'
import { array } from 'prop-types';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './styles/navbar.css';
import Modal from "react-bootstrap/Modal";
import moment, { relativeTimeThreshold, duration } from 'moment'
import swal from '@sweetalert/with-react';
import date from "date-and-time";
import Spinner from 'react-bootstrap/Spinner'
import { white } from 'color-name';
import { formatDate } from 'tough-cookie';
import { Popover, OverlayTrigger } from "react-bootstrap"
import { Chart } from 'react-charts'
import GlobalFunctions from './controllers/globalFunctions'


export default class App extends React.Component {
  constructor(props) {
    super(props);
    GlobalFunctions.extractToken();
    GlobalFunctions.requestUserFromAPI();

  }
  componentDidMount() {
    GlobalFunctions.extractToken();
    GlobalFunctions.requestUserFromAPI();
  }
  render() {
    return (
      <BrowserRouter>
        <Navbar />
        <Switch>
          <Route path="/" component={DashboardPage} exact={true} />
          <Route path="/people" component={PeoplePage} />
          <Route path="/schedule" component={Calendar} />
          <Route path="/timesheets" component={TimesheetsPage} />
          <Route path="/invoicing" component={InvoicePage} />
          <Route path="/clientRoster" component={ClientRoster} />
          <Route path="/reports" component={ReportsPage} />
          <Route component={ErrorPage} />
        </Switch>
        <Footer/>
      </BrowserRouter>
    )
  }
}

//ERROR PAGE COMPONENT
class ErrorPage extends React.Component {
  render() {
    return (
      <div>
        Error page
      </div>
    )
  }
}
//ERROR PAGE COMPONENT
class PeoplePage extends React.Component {
  render() {
    return (
      <div>
        People page
      </div>
    )
  }
}
//ERROR PAGE COMPONENT
class Footer extends React.Component {
  render() {
    return (
      <div>
        <footer class="footer mt-auto py-3 bg-light">
          <div class="container">
            <span class="text-muted">
            approved shifts <span class="badge bg-warning mr-4">5</span>
            unaproved shifts <span class="badge bg-info mr-4">10</span>
            completed shifts <span class="badge bg-success mr-4">30</span>
            Uncompleted shifts <span class="badge bg-danger mr-4">18</span>
            confirmed shifts <span class="badge bg-info mr-4">45</span>
            Unconfirmed shifts <span class="badge bg-warning mr-4">45</span>
            
            </span>
          </div>
        </footer>
      </div>
    )
  }
}

class ReportsPage extends React.Component {
  render() {
    return (
      <div>
        Reports page
      </div>
    )
  }
}

class InvoicePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      loading: true,
      loadingClients: true,
      selectedClientId: 0,
      selectedClientName: '',
      loadingInvoices: false,
      invoices: [],
      weekStart: '',
      weekEnd: ''
    };
    this.getClients = this.getClients.bind(this);
    
    this.sendInvoiceToXero = this.sendInvoiceToXero.bind(this);
    this.invoiceAll = this.invoiceAll.bind(this);
    this.getUnsyncronizedClientInvoices = this.getUnsyncronizedClientInvoices.bind(this);
    this.calculateWeekRange = this.calculateWeekRange.bind(this)
    this.getPreviousWeek = this.getPreviousWeek.bind(this);
    this.getNextWeek = this.getNextWeek.bind(this);
  }

  componentDidMount() {
    this.calculateWeekRange();

    GlobalFunctions.extractToken();
    this.getClients();
  }
 


  getClients() {
    this.setState({ loadingClients: true })
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/clients?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      this.setState({ clients: client.data, loadingClients: false });
    })

  }
  sendInvoiceToXero(event) {
    var url1 = `${GlobalFunctions.extractToken().API_URL}/invoices/insertInvoice?start=${event.weekStart}&end=${event.weekEnd}&clientId=${event.clientId}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.post(url1).then(client => {
      swal("successfully sent!", " ", "success")
    }).catch(err => {
      swal("Not sent!", " ", "warning")
    })

  }
  getUnsyncronizedClientInvoices(event) {
    this.setState({ loadingInvoices: true })
    var url1 = `${GlobalFunctions.extractToken().API_URL}/invoices/invoicesToBeSendToXero?start=${event.weekStart}&end=${event.weekEnd}&clientId=${event.clientId}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      this.setState({ loadingInvoices: false, invoices: client.data })
    }).catch(err => {

    })
  }

  invoiceAll(event) {
    var url1 = `${GlobalFunctions.extractToken().API_URL}/invoices/insertInvoice?start=${this.state.weekStart}&end=${this.state.weekEnd}&all=${true}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.post(url1).then(client => {
      swal("successfully sent!", " ", "success")
    }).catch(err => {
      swal("Not sent!", " ", "warning")
    })
  }

  calculateWeekRange() {
    let now = moment();
    const dayName = moment(now).format('dddd');
    var day = Number(now.weekday())
    var start = now.subtract(day - 1, 'days').format('MM-DD-YYYY');
    var end = now.add(6, 'days').format('MM-DD-YYYY');
    if(dayName=='Sunday'){
      end = moment(start).format('MM-DD-YYYY');
      end = moment(end).subtract(1, 'days').format('MM-DD-YYYY');
      start = moment(end).subtract(6, 'days').format('MM-DD-YYYY'); 
    }
    this.setState({ weekStart: start, weekEnd: end })
  }

  getNextWeek() {
    var start = moment(this.state.weekEnd).format('MM-DD-YYYY');
    start = moment(start).add(1, 'days').format('MM-DD-YYYY');
    var end = moment(start).add(6, 'days').format('MM-DD-YYYY')
    //Getting Data from the API after navigating to nextweek
    this.getUnsyncronizedClientInvoices({ clientId: this.state.selectedClientId, weekStart: start, weekEnd: end });
    this.setState({ weekStart: start, weekEnd: end })
  }

  getPreviousWeek() {
    var end = moment(this.state.weekStart).format('MM-DD-YYYY');
    end = moment(end).subtract(1, 'days').format('MM-DD-YYYY');
    var start = moment(end).subtract(6, 'days').format('MM-DD-YYYY');
    //Getting Data from the API after navigating to previousweek
    this.getUnsyncronizedClientInvoices({ clientId: this.state.selectedClientId, weekStart: start, weekEnd: end });
    this.setState({ weekStart: start, weekEnd: end })
  }
  render() {

    const columns = [{
      Header: () => (
        <button className="btn text-white  btn-warning btn-block" type="button">
          Date
        </button>
      ),
      minWidth: 50,
      Cell: row => {
        var date = row.original.date;
        date = moment(date).format('llll');
        date = date.split(" ");
        return (
          <div>
            {
              date[0] + " " + date[1] + " " + date[2] + " " + date[3]
            }
          </div>
        )
      }

    }, {
      Header: () => (
        <button className="btn text-white  btn-warning btn-block" type="button">
          Start
        </button>
      ),
      accessor: 'startTime',
      minWidth: 30

    }, {
      Header: () => (
        <button className="btn text-white  btn-warning btn-block" type="button">
          End
        </button>
      ),
      accessor: 'endTime',
      minWidth: 30
    }, {

      Header: () => (
        <button className="btn text-white  btn-warning btn-block" type="button">
          Service
        </button>
      ),
      Cell: row => {

        return (
          <div>
            {row.original.tbl_support_service.support_service}
          </div>
        )
      }
    }, {

      Header: () => (
        <button className="btn text-white  btn-warning btn-block" type="button">
          Price
        </button>
      ),
      accessor: 'costPerHour',
      minWidth: 20
    }, {

      Header: () => (
        <button className="btn text-white  btn-warning btn-block" type="button">
          Status
        </button>
      ),
      maxWidth: 120,
      Cell: row => {

        return (
          <div>
            {row.original.isXeroSync ?
              <span class="badge badge-success">Syncronized</span> :
              <span class="badge badge-danger">Unsyncronized</span>
            }
          </div>
        )
      }
    }]


    return (
      <div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 ml-2 mr-2">
          <h1 className="h2">{this.state.selectedClientName}</h1>
          <div className="btn-toolbar mb-2 mb-md-0">
            <div className="btn-group mr-2">
              <button onClick={this.getPreviousWeek} type="button" className="btn btn-sm btn-outline-secondary">&#10094;</button>
              <button type="button" className="btn btn-sm btn-outline-secondary">
                <span data-feather="calendar"></span>{moment(this.state.weekStart).format('DD-MM-YYYY')} - {moment(this.state.weekEnd).format('DD-MM-YYYY')}</button>
              <button onClick={this.getNextWeek} type="button" className="btn btn-sm btn-outline-secondary">&#10095;</button>
            </div>
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary mr-2"
              onClick={() => {
                this.invoiceAll();
              }}
            >
              Bulk invoice
          </button>
            <a
              href="${GlobalFunctions.extractToken().API_URL}/invoices/connect"
              type="button"
              className="btn btn-sm btn-success mr-2"
            >
              Xero connect
          </a>

          </div>
        </div>
        <div className="form-row">
          <div className="col-md-4" >
            <div style={{ height: "450px", overflowY: "scroll" }}>
              {
                this.state.loadingClients ?
                  <Spinner animation="border" role="status" size="lg" className="text-center" style={{ width: "4rem", height: "4rem", marginLeft: "10rem", marginTop: "8rem" }}>
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                  :
                  this.state.clients.map((client) =>
                    <div>
                      <div
                        // onMouseOver={() => {
                        //   this.setState({ selectedClientId: client.id })
                        // }}
                        onClick={() => {
                          var index = this.state.clients.findIndex(x => x.id == client.id);
                          this.setState({ selectedClientId: client.id, selectedClientName: this.state.clients[index].clientname });
                          this.getUnsyncronizedClientInvoices({ clientId: client.id, weekStart: this.state.weekStart, weekEnd: this.state.weekEnd });
                        }}
                        className="btn text-white btn-warning btn-block mt-2 ml-1 text-left"
                        style={{ 'backgroundColor': client.id == this.state.selectedClientId ? '#AFAD12' : '#f7c133' }}
                        type="button"
                        key={client.id}
                      >
                        <small>{`${client.clientname}`}</small>
                        <button
                          type="button"
                          className="btn btn-sm btn-info mr-1 mb-2 float-right "
                          onClick={() => {
                            this.sendInvoiceToXero({ clientId: client.id, weekStart: this.state.weekStart, weekEnd: this.state.weekEnd })
                          }}
                        >
                          Send to xero
                        </button>

                      </div>


                    </div>

                  )
              }
            </div>
          </div>
          <div className="col-md-8">
            <ReactTable
              data={this.state.invoices}
              loading={this.state.loadingInvoices}
              columns={columns}
              defaultPageSize={10}
            />
          </div>
        </div>

        {/* <div className="col-md-12">
          <ReactTable
            data={this.state.clients}
            loading={this.state.loading}
            columns={columns}
            defaultPageSize={10}
          />
        </div> */}
      </div>
    )
  }
}

class TimesheetsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DatabaseData: [],
      clients: [],
      finSupport: [],
      supportServices: [],
      linkedSupportAreas: [],
      linkedSupportService: [],
      linkedFinancialPackages: [],
      clientFin: [],
      servicePrice: 0,
      serviceCost: 0,

      clientId: 0,
      packageId: 0,
      supportAreaId: 0,
      supportServicesId: 0,
      staffId: 0,
      selectedStaffId: 0,
      selectedStaffName: "",
      selectedShiftId: 0,
      weekStart: '',
      weekEnd: '',
      selectedShiftDetails: {},
      financialPackageId: 0,
      staffList: [],
      timesheets: [],
      selectedTimesheetId: [],
      userDetails: {},
      loadingData: false,
      loadingStaff: true,
      savingData: false,
      totalTimesheetHours: 0,
      shiftsList: [],
      filteredData: [],
      searchInput: ""

    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.editRow = this.editRow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.setTitle = this.setTitle.bind(this);
    this.getStaff = this.getStaff.bind(this);
    this.showAddModal = this.showAddModal.bind(this);
    this.hideAddModal = this.hideAddModal.bind(this);
    this.hideOpenShiftsModal = this.hideOpenShiftsModal.bind(this);
    this.showOpenShiftsModal = this.showOpenShiftsModal.bind(this);
    this.getClients = this.getClients.bind(this);
    this.deleteShift = this.deleteShift.bind(this);
    this.calculateWeekRange = this.calculateWeekRange.bind(this);
    this.getNextWeek = this.getNextWeek.bind(this);
    this.getPreviousWeek = this.getPreviousWeek.bind(this);
    this.handleEditShift = this.handleEditShift.bind(this);
    this.handleApproveTimesheets = this.handleApproveTimesheets.bind(this);
    this.handleConfirmShift = this.handleConfirmShift.bind(this);
    this.pasteShift = this.pasteShift.bind(this);
    this.getTimesheets = this.getTimesheets.bind(this);
    this.saveAlert = this.saveAlert.bind(this);
    this.deleteAlert = this.deleteAlert.bind(this);
    this.approveAlert = this.approveAlert.bind(this);
    
    this.handleConfirmCompletedTimesheets = this.handleConfirmCompletedTimesheets.bind(this);
    this.adminConfirmCompletedAlert = this.adminConfirmCompletedAlert.bind(this);
    this.networkFailureAlert = this.networkFailureAlert.bind(this);
    this.xeroSyncTimesheets = this.xeroSyncTimesheets.bind(this);
    this.hideXeroCalendarDateModal = this.hideXeroCalendarDateModal.bind(this);
    this.showXeroCalendarDateModal = this.showXeroCalendarDateModal.bind(this);
    this.handleDeleteTimesheet = this.handleDeleteTimesheet.bind(this);


  }

  handleChange = event => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput } = this.state;
    let filteredData = this.state.shiftsList.filter(value => {
      return (
        value.staffName.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ staffList: filteredData });
    console.log(filteredData);
  };

 
  networkFailureAlert(event) {
    swal(event, " ", "warning")
  }
  saveAlert() {
    swal("successfully saved!", " ", "success")
      .then(() => {
        this.hideAddModal();
      })
  }
  adminConfirmCompletedAlert() {
    swal("Admin successfully confirmed!", " ", "success")
      .then(() => {
        this.hideModal();
      })
  }
  approveAlert() {
    swal("successfully approved!", " ", "success")
      .then(() => {
        this.hideModal();
      })
  }

  deleteAlert() {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          swal("successfully deleted!", {
            icon: "success",
          });
        } else {
          swal("not deleted!");
        }
      })
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.name === 'isGoing' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    this.setState({ savingData: true })

    event.preventDefault();

    const infor = {
      "staffId": Number(this.state.selectedStaffId),
      "location": this.state.address,
      "Date": this.state.date,
      "startTime": this.state.startTime,
      "endTime": this.state.endTime,
      "breaking": this.state.breakMin,
      "notes": this.state.notes,
      "allowance": 0,

    };

    this.state.selectedStaffId = undefined
    this.state.addres = undefined
    this.state.date = undefined
    this.state.startTime = undefined
    this.state.endTime = undefined
    this.state.breakMin = undefined


    axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/add?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
      .then(res => {
        this.setState({ savingData: false })
        if (res.status == 201) {
          this.saveAlert();
          this.getTimesheets();
        }
      }).catch(error => {
        this.setState({ savingData: false })
        let errorObject = JSON.parse(JSON.stringify(error));
        this.networkFailureAlert(errorObject.message)

      })
  }

  handleEditShift(event) {


    const infor = {
      "clientId": Number(this.state.clientId) == 0 ? Number(this.state.selectedShiftDetails.clientId) : Number(this.state.clientId),
      "location": this.state.address == undefined ? this.state.selectedShiftDetails.clientLocation : this.state.address,
      "Date": this.state.date == undefined ? this.state.selectedShiftDate : this.state.date,
      "startTime": this.state.startTime == undefined ? this.state.selectedShiftDetails.startTime : this.state.startTime,
      "endTime": this.state.endTime == undefined ? this.state.selectedShiftDetails.endTime : this.state.endTime,
      "breaking": this.state.breakMin == undefined ? this.state.selectedShiftDetails.breaking : this.state.breakMin,
      "notes": this.state.notes == undefined ? this.state.selectedShiftDetails.notes : this.state.notes,
      "hourlChage": this.state.serviveCost == undefined ? this.state.selectedShiftDetails.hourlChage : this.state.serviceCost,

      "supportAreaId": Number(this.state.supportAreaId) == 0 ? Number(this.state.selectedShiftDetails.supportAreaId) : Number(this.state.supportAreaId),
      "supportServiceId": Number(this.state.supportServicesId) == 0 ? Number(this.state.selectedShiftDetails.supportServiceId) : Number(this.state.supportServicesId),
      "packageId": Number(this.state.financialPackageId) == 0 ? Number(this.state.selectedShiftDetails.financialPackageId) : Number(this.state.financialPackageId)

    };




    axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/edit/${this.state.selectedShiftId}?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
      .then(res => {

        this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
        this.hideModal();

      }).catch(error => {
        let errorObject = JSON.parse(JSON.stringify(error));
        this.networkFailureAlert(errorObject.message)
      });
  }



  showModal() {
    this.setState({ ShowModal: true });
  }

  hideModal() {
    this.setState({ ShowModal: false });
  }
  showAddModal() {
    this.setState({ ShowAddModal: true });
  }
  showXeroCalendarDateModal() {
    this.setState({ pickXeroCalendarDateModal: true });
  }
  hideXeroCalendarDateModal() {
    this.setState({ pickXeroCalendarDateModal: false });
  }

  hideAddModal() {
    this.setState({ ShowAddModal: false });
  }
  showOpenShiftsModal() {
    this.setState({ ShowOpenShiftsModal: true });
  }

  hideOpenShiftsModal() {
    this.setState({ ShowOpenShiftsModal: false });
  }

  setTitle(event) {

    this.setState({ [event.name]: event.value })



  }


  getTimesheets() {
    this.setState({ loadingData: true })
    var url = `${GlobalFunctions.extractToken().API_URL}/timeSheets/timeSheets/one?staffId=${this.state.selectedStaffId}&start=${this.state.weekStart}&end=${this.state.weekEnd}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url).then(timesheet => {

      var total = 0;
      timesheet.data.forEach(element => {
        total += element.duration
      })
      this.setState({ timesheets: timesheet.data, loadingData: false, totalTimesheetHours: total })
    }).catch(e => {

    })
  }


  calculateWeekRange() {
    let now = moment();
    const dayName = moment(now).format('dddd');
    var day = Number(now.weekday())
    var start = now.subtract(day - 1, 'days').format('MM-DD-YYYY');
    var end = now.add(6, 'days').format('MM-DD-YYYY')
    if(dayName=='Sunday'){
      end = moment(start).format('MM-DD-YYYY');
      end = moment(end).subtract(1, 'days').format('MM-DD-YYYY');
      start = moment(end).subtract(6, 'days').format('MM-DD-YYYY'); 
    }
    this.setState({ weekStart: start, weekEnd: end })
  }

  xeroSyncTimesheets(event) {

    event.preventDefault();
    console.log(this.state.xeroCalendarStart)
    console.log(this.state.xeroCalendarEnd)
    var url1 = `${GlobalFunctions.extractToken().API_URL}/invoices/timeSheetToSendToXero?fortnightStart=${moment(this.state.xeroCalendarStart).format('MM-DD-YYYY')}&fortnightEnd=${moment(this.state.xeroCalendarEnd).format('MM-DD-YYYY')}`;
    axios.post(url1).then(client => {
      swal("successfully synced", " ", "success")

    }).catch(err => {
      this.networkFailureAlert(err);
    })
  }
  getClients() {
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/clients?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      this.setState({ clients: client.data });
    })

  }

  getFinSupport() {
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/finsupport?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      this.setState({ finSupport: client.data });
    })

  }

  getSupportServices() {
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/support-services?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      this.setState({ supportServices: client.data });
    })

  }
  getClientFinancials() {
    var url1 = '${GlobalFunctions.extractToken().API_URL}/shifts/clientFin';
    axios.get(url1).then(client => {
      this.setState({ clientFin: client.data });
    })

  }

  deleteShift(selectedShiftId) {
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/delete/${this.state.selectedShiftId}?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      // this.setState({ clients: client.data });
      this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
      this.hideModal();
    })

  }

  handleApproveTimesheets(selectedShiftId) {
    this.state.selectedTimesheetId.forEach(id => {
      if (isNaN(id)) {
        var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/approveAppointment?appointmentId=${id}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
        axios.get(url1).then(client => {
          if (client.status == 200) {
            this.approveAlert();
            this.getTimesheets();
          }
          this.state.selectedTimesheetId = [];
        }).catch(error => {
          let errorObject = JSON.parse(JSON.stringify(error));
          this.networkFailureAlert(errorObject.message)

        })
      } else {
        var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/approve?shiftId=${id}&staffId=${GlobalFunctions.extractToken().STAFF_ID}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
        axios.get(url1).then(client => {
          if (client.status == 200) {
            this.approveAlert();
            this.getTimesheets();
          }
          this.state.selectedTimesheetId = [];
        }).catch(error => {
          let errorObject = JSON.parse(JSON.stringify(error));
          this.networkFailureAlert(errorObject.message)

        })
      }

    }
    )

    this.setState({ selectedTimesheetId: [] })
  }

  handleConfirmShift(selectedShiftId) {
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/confirm?shiftId=${this.state.selectedShiftId}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      // this.setState({ clients: client.data });
      this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
      this.hideModal();
    })

  }

  deleteTimesheetAlert=(event)=>{
    swal({
      title: "Are you sure you want to Delete the Client Appointments?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
       if(willDelete){
        this.handleDeleteTimesheet();
       }
      })
  }

  handleDeleteTimesheet(event) {

    this.setState({ loadingData: true })

    this.state.selectedTimesheetId.forEach(element => {
      var url1 = "";

      if (isNaN(element)) {
        url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/deleteAppointment?appointmentId=${element}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      } else {
        url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/delete/${element}?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      }

      axios.get(url1).then(client => {
        if (client.status == 200) {
          swal("successfully deleted!", " ", "success")
        }

      }).catch(error => {
        console.log(error)
        swal("Not deleted", " ", "warning")
      })

    });

    this.getTimesheets();
    this.setState({ loadingData: false })
  }

  handleConfirmCompletedTimesheets(selectedShiftId) {
    this.state.selectedTimesheetId.forEach(id => {
      if (isNaN(id)) {
        var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/confirmAppointment?appointmentId=${id}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
        axios.get(url1).then(client => {
          if (client.status == 200) {
            this.adminConfirmCompletedAlert();
            this.getTimesheets();
          }
          this.state.selectedTimesheetId = [];
        }).catch(error => {
          let errorObject = JSON.parse(JSON.stringify(error));
          this.networkFailureAlert(errorObject.message)

        })
      } else {
        var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/adminConfirm?shiftId=${id}&staffId=${GlobalFunctions.extractToken().STAFF_ID}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
        axios.get(url1).then(client => {
          if (client.status == 200) {
            this.adminConfirmCompletedAlert();
            this.getTimesheets();
          } else {

          }
          this.state.selectedTimesheetId = [];
        }).catch(error => {
          let errorObject = JSON.parse(JSON.stringify(error));
          this.networkFailureAlert(errorObject.message)

        })
      }

    }
    )
    this.setState({ selectedTimesheetId: [] })
  }

  getNextWeek() {
    var start = moment(this.state.weekEnd).format('MM-DD-YYYY');
    start = moment(start).add(1, 'days').format('MM-DD-YYYY');
    var end = moment(start).add(6, 'days').format('MM-DD-YYYY')

    var url = `${GlobalFunctions.extractToken().API_URL}/timeSheets/timeSheets/one?staffId=${this.state.selectedStaffId}&start=${start}&end=${end}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url).then(timesheet => {
      this.setState({ timesheets: timesheet.data })

    }).catch(e => {

    })
    this.setState({ weekStart: start, weekEnd: end })

  }

  getPreviousWeek() {
    var end = moment(this.state.weekStart).format('MM-DD-YYYY');
    end = moment(end).subtract(1, 'days').format('MM-DD-YYYY');
    var start = moment(end).subtract(6, 'days').format('MM-DD-YYYY');

    var url = `${GlobalFunctions.extractToken().API_URL}/timeSheets/timeSheets/one?staffId=${this.state.selectedStaffId}&start=${start}&end=${end}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url).then(timesheet => {
      this.setState({ timesheets: timesheet.data })

    }).catch(e => {

    })

    this.setState({ weekStart: start, weekEnd: end })

  }

  pasteShift() {


    const infor = {
      "staffId": Number(this.state.selectedStaffId),
      "clientId": Number(this.state.selectedShiftDetails.clientId),
      "location": this.state.selectedShiftDetails.clientLocation,
      "Date": this.state.date,
      "startTime": this.state.selectedShiftDetails.startTime,
      "endTime": this.state.selectedShiftDetails.endTime,
      "breaking": this.state.selectedShiftDetails.breaking,
      "notes": this.state.selectedShiftDetails.notes,
      "allowance": 0
    };




    axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/add?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
      .then(res => {

        this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
        this.hideModal();

      }).catch(error => {


      });
  }

  componentDidMount() {
    GlobalFunctions.extractToken();
    this.getStaff();
    // this.getShifts();
    this.getClients();




    this.calculateWeekRange();
  }

  getStaff() {
    this.setState({ loadingStaff: true })
    //FETCHING ALL STAFF FROM API
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/staff?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(staff => {
      var arr = [];
      staff.data.forEach(element => {
        arr.push({ staffName: `${element.lastname} ${element.firstname}`, id: element.id })
      });


      this.setState({ staffList: arr, loadingStaff: false, shiftsList: arr });


    }).catch(error => {

    })
  }

  editRow(props) {

  }


  render() {

    const columns = [{
      Header: () => (
        <button
          onClick={() => {
            var arr = [];
            arr = this.state.selectedTimesheetId;
            if (arr.length == 0) {
              this.state.timesheets.forEach(element => {
                arr.push(element.shiftId)
              });
              this.setState({ selectedTimesheetId: arr });
            } else {
              this.setState({ selectedTimesheetId: [] });
            }
          }}
          className="btn text-white  btn-warning btn-block"
          type="button">
          &#10003;
        </button>
      ),
      maxWidth: 50,
      Cell: row => {

        return (
          <div
          >
            <input
              onClick={() => {
                var arr = [];
                arr = this.state.selectedTimesheetId;
                var index = arr.findIndex(x => x == row.original.shiftId);
                if (index >= 0) {
                  arr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));
                } else {
                  arr.push(row.original.shiftId)
                }
                this.setState({ selectedTimesheetId: arr });
              }
              }
              type="checkbox" className="ml-2" checked={this.state.selectedTimesheetId.includes(row.original.shiftId) ? true : false}>
            </input>
          </div >
        )
      }

    }, {
      Header: () => (
        <button className="btn text-white  btn-warning btn-block" type="button">
          ClientName
        </button>
      ),
      accessor: 'clientName',
      minWidth: 100,
      Cell : row =>{
        return (
          row.original.isConcelled?
            <del>{row.original.clientName}</del>
            :
            <div>{row.original.clientName}</div>
        )
      }

    }, {
      Header: () => (
        <button className="btn text-white  btn-warning btn-block" type="button">
          Date
        </button>
      ),
      maxWidth: 150,
      Cell: row => {
        var date = row.original.date;
        date = moment(date).format('llll');
        date = date.split(" ");
        return (
          row.original.isConcelled?
          <del>
            <div>
              {
                date[0] + " " + date[1] + " " + date[2] + " " + date[3]
              }
            </div>
          </del>
          :
          <div>
            {
              date[0] + " " + date[1] + " " + date[2] + " " + date[3]
            }
          </div>
        )
      }

    }, {
      Header: () => (
        <button className="btn text-white  btn-warning btn-block" type="button">
          Start
        </button>
      ),
      // accessor: 'startTime',
      maxWidth: 70,
      Cell : row =>{
        return (
          row.original.isConcelled?
            <del>{row.original.startTime}</del>
            :
            <div>{row.original.startTime}</div>
        )
      }

    }, {
      Header: () => (
        <button className="btn text-white  btn-warning btn-block" type="button">
          End
        </button>
      ),
      // accessor: 'endTime',
      Cell : row =>{
        return (
          row.original.isConcelled?
            <del>{row.original.endTime}</del>
            :
            <div>{row.original.endTime}</div>
        )
      },
      maxWidth: 70
    }, {

      Header: () => (
        <button className="btn text-white  btn-warning btn-block" type="button">
          Location
        </button>
      ),
      // accessor: 'location',
      Cell : row =>{
        return (
          row.original.isConcelled?
            <del>{row.original.location}</del>
            :
            <div>{row.original.location}</div>
        )
      }
    }, {

      Header: () => (
        <button className="btn text-white  btn-warning btn-block" type="button">
          Duration
        </button>
      ),
      // accessor: 'duration',
      minWidth: 60,
      Cell : row =>{
        return (
          row.original.isConcelled?
            <del>{row.original.duration}</del>
            :
            <div>{row.original.duration}</div>
        )
      }
    }, {

      Header: () => (
        <button className="btn text-white  btn-warning btn-block" type="button">
          Allowance
        </button>
      ),
      // accessor: 'allowance',
      minWidth: 70,
      Cell : row =>{
        return (
          row.original.isConcelled?
            <del>{row.original.allowance}</del>
            :
            <div>{row.original.allowance}</div>
        )
      }
    }, {

      Header: () => (
        <button className="btn text-white  btn-warning btn-block" type="button">
          Status
        </button>
      ),
      minWidth: 150,
      Cell: row => {

        return (
          <div>
            {
              row.original.isApproved ? <a className="label label-info"><small><span className="badge badge-success ml-2">Approved</span></small></a> : <a className="label label-info"><small><span className="badge badge-danger ml-2">Not approved</span></small></a>
            }
            {
              row.original.isCompleted ? <a className="label label-info"><small><span className="badge badge-warning ml-2">Completed</span></small></a> : <a className="label label-info"><small><span className="badge badge-danger ml-2">Uncompleted</span></small></a>
            }
            {
              row.original.isAdminConfirmed ? <a className="label label-info"><small><span className="badge badge-info ml-2">Admin confirmed</span></small></a> : <a className="label label-info"><small><span className="badge badge-danger ml-2">Unconfirmed</span></small></a>
            }
          </div>
        )
      }
    }]

    return (
      <div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 ml-2 mr-2">
          <h1 className="h2">{this.state.selectedStaffName == "" ? 'TimeSheets' : this.state.selectedStaffName}</h1>
          <div className="btn-toolbar mb-2 mb-md-0">
            <button
              onClick={() => {
                this.showXeroCalendarDateModal();
              }}
              type="button"
              className="btn btn-sm btn-info mr-2"
            >
              Xero sync
          </button>
            <a
              href="${GlobalFunctions.extractToken().API_URL}/invoices/connect"
              type="button"
              className="btn btn-sm btn-success mr-2"
            >
              Xero connect
          </a>
            <button
              type="button"
              className="btn btn-sm btn-outline-success mr-2"
            >
              {parseFloat(this.state.totalTimesheetHours).toFixed(2)} total hours
          </button>
            <div className="btn-group mr-2">
              <button onClick={this.getPreviousWeek} type="button" className="btn btn-sm btn-outline-secondary">&#10094;</button>
              <button type="button" className="btn btn-sm btn-outline-secondary">
                <span data-feather="calendar"></span>{moment(this.state.weekStart).format('DD-MM-YYYY')} - {moment(this.state.weekEnd).format('DD-MM-YYYY')}</button>
              <button onClick={this.getNextWeek} type="button" className="btn btn-sm btn-outline-secondary">&#10095;</button>
            </div>

            <button
              onClick={this.showAddModal}
              type="button"
              className="btn btn-sm btn-outline-secondary mr-2"
            >
              &#43; Timesheet
          </button>
            <a href={`${GlobalFunctions.extractToken().API_URL}/timeSheets/excelExport?start=${this.state.weekStart}&end=${this.state.weekEnd}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`}
              type="button"
              className="btn btn-sm btn-outline-secondary mr-2"
            > &#8645; Export</a>
            <button
              onClick={this.handleApproveTimesheets}
              type="button"
              className="btn btn-sm btn-outline-secondary mr-2">
              &#10003; Approve
          </button>
            <button
              onClick={this.handleConfirmCompletedTimesheets}
              type="button"
              className="btn btn-sm btn-outline-secondary mr-2">
              &#10003; Confirm
          </button>
            <button
              onClick={this.deleteTimesheetAlert}
              type="button"
              className="btn btn-sm btn-outline-secondary mr-2">
              &#10005; Delete
          </button>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-3" >
            <form >
              <input
                className="form-control ml-1"
                type="text"
                placeholder="Search"
                defaultValue={this.state.searchInput}
                onChange={this.handleChange}
                autoFocus />
            </form>
            <div style={{ height: "450px", overflowY: "scroll" }}>
              {
                this.state.loadingStaff ?
                  <Spinner animation="border" role="status" size="lg" className="text-center" style={{ width: "4rem", height: "4rem", marginLeft: "10rem", marginTop: "8rem" }}>
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                  :
                  this.state.staffList.map((staff) =>
                    <button

                      onClick={() => {
                        this.state.selectedStaffId = staff.id
                        this.getTimesheets(this.state.selectedStaffId);
                        const index = this.state.staffList.findIndex(x => x.id == staff.id);
                        this.setState({ selectedStaffName: staff.staffName });
                      }}
                      className="btn text-white btn-warning btn-block mt-2 ml-1"
                      type="button"
                      key={staff.id}
                      style={{ 'backgroundColor': staff.id == this.state.selectedStaffId ? 'DodgerBlue' : '#f7c133' }}

                    >
                      {`${staff.staffName}`}
                    </button>
                  )
              }
            </div>
          </div>
          <div className="col-md-9">
            <ReactTable
              data={this.state.timesheets}
              loading={this.state.loadingData}
              columns={columns}
              defaultPageSize={10}
            />
          </div>
          {/* ADD SHIFT MODAL */}
          <Modal show={this.state.ShowAddModal} onHide={true} size="lg" >
            <form onSubmit={this.handleSubmit}>
              <Modal.Header>
                <Modal.Title><h5 className="modal-title" >Add shift on {this.state.selectedShiftDate}</h5></Modal.Title>
                <button type="button" className="close" onClick={this.hideAddModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Header>
              <Modal.Body>
                <div className="row">

                  <div className="col-md-12">

                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="inputEmail4">Who is working?</label>
                        <select
                          required
                          onClick={(event) => {
                            var staffId = event.target.value;
                            this.setState({ selectedStaffId: staffId })


                          }}
                          className="form-control"
                          name="selectedStaffId" >
                          {
                            this.state.staffList.map((staff) =>
                              <option
                                value={staff.id}
                                key={staff.id}
                              >
                                {`${staff.staffName}`}
                              </option>
                            )
                          }
                        </select>

                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="inputAddress">Location</label>
                        <input required type="text" className="form-control" name="address" onChange={event => this.setTitle(event.target)} placeholder="Location or Department" />

                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="inputAddress">Date</label>
                        <input required type="date" className="form-control" name="date" onChange={event => this.setTitle(event.target)} />

                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label htmlFor="inputCity">Start time</label>
                        <input required type="time" className="form-control" onChange={event => this.setTitle(event.target)} name="startTime" />

                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="inputCity">End time</label>
                        <input required type="time" className="form-control" onChange={event => this.setTitle(event.target)} name="endTime" />

                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="inputCity">Break(min)</label>
                        <input type="number" className="form-control" onChange={event => this.setTitle(event.target)} name="breakMin" />

                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleFormControlTextarea1">Notes</label>
                      <textarea className="form-control" onChange={event => this.setTitle(event.target)} name="notes" rows="3"></textarea>

                    </div>

                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {
                  this.state.savingData ?
                    <button
                      className="btn btn-info"
                      type="Submit"
                      disabled
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Saving data...
                </button>
                    :
                    <button
                      className="btn btn-info"
                      type="Submit"
                    >
                      Save shift
                </button>
                }

              </Modal.Footer>
            </form>
          </Modal>

          {/* PICK XERO TIMESHEETS CALENDAR DATE MODAL */}
          <Modal show={this.state.pickXeroCalendarDateModal} onHide={true}  >
            <Modal.Header>
              <Modal.Title><h5 className="modal-title" >Select Xero calendar dates</h5></Modal.Title>
              <button type="button" className="close" onClick={this.hideXeroCalendarDateModal}>
                <span aria-hidden="true">&times;</span>
              </button>
            </Modal.Header>
            <form onSubmit={this.xeroSyncTimesheets}>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12">

                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="inputCity">Start date</label>
                        <input required placeholder="start date" type="date" className="form-control" onChange={event => this.setTitle(event.target)} name="xeroCalendarStart" />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="inputCity">End date</label>
                        <input required placeholder="end date" type="date" className="form-control" onChange={event => this.setTitle(event.target)} name="xeroCalendarEnd" />
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-success" type="submit">Xero sync</button>
              </Modal.Footer>
            </form>
          </Modal>
        </div>

      </div >
    )
  }
}

{/* NAVBAR COMPONENT */ }
class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      userDetails: {}
    }; 
    GlobalFunctions.extractToken();
    GlobalFunctions.requestUserFromAPI();

  }
  componentDidMount() {
    GlobalFunctions.extractToken();
    GlobalFunctions.requestUserFromAPI();
  }

  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-md navbar-dark bg-warning">
          <Link className="navbar-brand" to="/">Hivelink Roster</Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarsExample04">
            <ul className="navbar-nav mr-auto">
              {
                true ? <li className="nav-item">
                  <a className="nav-link" href="https://engage.tarisa.com.au/">Home</a>
                </li> : ""
              }
              {
                true ? <li className="nav-item">
                  <Link className="nav-link" to={`/`}>Dashboard</Link>
                </li> : ""
              }
              {
                GlobalFunctions.extractToken().ACCESS_LEVEL <= 3||GlobalFunctions.extractToken().ACCESS_LEVEL==5? <li className="nav-item">
                  <Link className="nav-link" to={`/schedule`} >Schedule</Link>
                </li> : ""
              }

              {
                GlobalFunctions.extractToken().ACCESS_LEVEL <= 3||GlobalFunctions.extractToken().ACCESS_LEVEL==5 ? <li className="nav-item">
                  <Link className="nav-link" to={`/timesheets`}>Timesheets</Link>
                </li> : ""
              }
              {
                GlobalFunctions.extractToken().ACCESS_LEVEL <= 3||GlobalFunctions.extractToken().ACCESS_LEVEL==5 ? <li className="nav-item">
                  <Link className="nav-link" to={`/invoicing`}>Invoicing</Link>
                </li> : ""
              }
              {/* {
                GlobalFunctions.extractToken().ACCESS_LEVEL <= 3 ? <li className="nav-item">
                  <Link className="nav-link" to={`/clientRoster`}>Client Rostering</Link>
                </li> : ""
              } */}

              {/* <li className="nav-item">
                <Link className="nav-link" to="/reports">Reports</Link>
              </li> */}

            </ul>
          </div>
        </nav>
      </div >
    )
  }
}

{/* CLIENT ROSTERING COMPONENT */ }
class ClientRoster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      schedules: [],
      clients: [],
      finSupport: [],
      supportServices: [],
      linkedSupportAreas: [],
      linkedSupportService: [],
      linkedFinancialPackages: [],
      selectedShiftDate: "",
      clientFin: [],
      servicePrice: 0,
      serviceCost: 0,

      clientId: 0,
      packageId: 0,
      supportAreaId: 0,
      supportServicesId: 0,

      weekStart: '',
      weekEnd: '',
      selectedShiftDetails: {},
      financialPackageId: 0,
      allowance: 0,
      userDetails: {},

      totalApproved: 0,
      totalConfirmed: 0,
      totalAppointments: 0,

      getNextWeeks: [],
      clientLocation: "",
      costPerHour: 0,

      loadingData: false,
      savingData: false,
      appointments: [],
      selectedDay: "",
      selectedServiceName: "",

      editField: {
        shiftId: 0,
        startTime: "",
        endTime: "",
        serviceId: 0,
        location: "",
        clientId: "",
        notes: "",
        price: 0
      },
      appointmentRowData: {},

      isOpenListOfServices: false,
      isSubmitingInProgress: false

    };

    this.handleInputChange = this.handleInputChange.bind(this);

    this.setTitle = this.setTitle.bind(this);

    this.getClients = this.getClients.bind(this);

    this.calculateWeekRange = this.calculateWeekRange.bind(this);
    this.getNextWeek = this.getNextWeek.bind(this);
    this.getPreviousWeek = this.getPreviousWeek.bind(this);

    
    this.dataFormating = this.dataFormating.bind(this);
    this.showAddAppointmentModal = this.showAddAppointmentModal.bind(this);
    this.hideAddAppointmentModal = this.hideAddAppointmentModal.bind(this);
    this.getSupportServices = this.getSupportServices.bind(this);
    this.setTitle = this.setTitle.bind(this);
    this.handleAddAppointment = this.handleAddAppointment.bind(this);
    this.networkFailureAlert = this.networkFailureAlert.bind(this);
    this.editAppointments = this.editAppointments.bind(this);
    this.handleApproveAppointments = this.handleApproveAppointments.bind(this);
    this.handleConfirmAppointments = this.handleConfirmAppointments.bind(this);
    this.handleDeleteAppointments = this.handleDeleteAppointments.bind(this)
  }

  componentDidMount() {
    GlobalFunctions.extractToken();
    this.calculateWeekRange();
    this.dataFormating(this.getClients());
    this.getSupportServices();
  }

  handleApproveAppointments(event) {
    axios.get(`${GlobalFunctions.extractToken().API_URL}/clientRoster/approve?clientRosterId=${event}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`).then(res => {
      this.dataFormating();
      swal("successfully approved!", " ", "success")
    }).catch(error => {
      this.setState({ isSubmitingInProgress: false })
      let errorObject = JSON.parse(JSON.stringify(error));
      this.networkFailureAlert(errorObject.message)
    })
  }

  handleConfirmAppointments(event) {
    axios.get(`${GlobalFunctions.extractToken().API_URL}/clientRoster/confirm?clientRosterId=${event}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`).then(res => {
      this.dataFormating();
      swal("successfully confirmed!", " ", "success")
    }).catch(error => {
      this.setState({ isSubmitingInProgress: false })
      let errorObject = JSON.parse(JSON.stringify(error));
      this.networkFailureAlert(errorObject.message)
    })
  }

  handleDeleteAppointments(event) {
    axios.get(`${GlobalFunctions.extractToken().API_URL}/clientRoster/delete?clientRosterId=${event}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`).then(res => {
      this.dataFormating();
      swal("successfully deleted!", " ", "success")
    }).catch(error => {
      this.setState({ isSubmitingInProgress: false })
      let errorObject = JSON.parse(JSON.stringify(error));
      this.networkFailureAlert(errorObject.message)
    })
  }
  editAppointments() {
    this.setState({ isSubmitingInProgress: true })
    const data = {
      startTime: this.state.editField.startTime ? this.state.editField.startTime : this.state.appointmentRowData.startTime,
      endTime: this.state.editField.endTime ? this.state.editField.endTime : this.state.appointmentRowData.endTime,
      serviceId: this.state.editField.serviceId ? this.state.editField.serviceId : this.state.appointmentRowData.serviceId,
      price: this.state.editField.price ? this.state.editField.price : this.state.appointmentRowData.price,
      location: this.state.editField.location ? this.state.editField.location : this.state.appointmentRowData.location,
      notes: this.state.editField.notes ? this.state.editField.notes : this.state.appointmentRowData.notes,
    }
    axios.post(`${GlobalFunctions.extractToken().API_URL}/clientRoster/update?clientRosterId=${this.state.editField.shiftId}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, data)
      .then(res => {
        this.setState({ isSubmitingInProgress: false })
        if (res.status == 200) {
          this.dataFormating();
          swal("successfully saved!", " ", "success")

        }
      }).catch(error => {
        this.setState({ isSubmitingInProgress: false })
        let errorObject = JSON.parse(JSON.stringify(error));
        this.networkFailureAlert(errorObject.message)
      })

  }

  showAddAppointmentModal() {
    this.setState({ ShowAddAppointmentModal: true });
  }

  hideAddAppointmentModal() {
    this.setState({ staffAppointments: [] })
    this.setState({ ShowAddAppointmentModal: false });
  }

  setTitle(event) {

    this.setState({ [event.name]: event.value })


  }



  calculateDuration(event) {
    var startTime = date.parse(event.startTime, 'HH:mm:ss A', true);
    var endTime = date.parse(event.endTime, 'HH:mm:ss A', true);
    var duration = date.subtract(endTime, startTime).toMinutes();
    duration = duration / 60;
    this.setState({ calculatedDuration: duration })
  }

  

  handleInputChange(event) {
    const target = event.target;
    const value = target.name === 'isGoing' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleAddAppointment(event) {
    this.setState({ savingData: true })
    event.preventDefault();

    const infor = {
      "location": this.state.clientLocation,
      "date": this.state.selectedShiftDate,
      "startTime": this.state.startTime,
      "endTime": this.state.endTime,
      "clientId": Number(this.state.clientId),
      "notes": this.state.notes,
      "serviceId": Number(this.state.clientServiceId),
      "price": this.state.costPerHour

    };

    axios.post(`${GlobalFunctions.extractToken().API_URL}/clientRoster/create?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
      .then(res => {
        this.setState({ savingData: false })
        if (res.status == 201) {
          this.dataFormating();
          swal("successfully saved!", " ", "success")

        }
      }).catch(error => {
        this.setState({ savingData: false })
        let errorObject = JSON.parse(JSON.stringify(error));
        this.networkFailureAlert(errorObject.message)
      })


  }


  setTitle(event) {
    this.setState({ [event.name]: event.value });
  }

  networkFailureAlert(event) {
    swal(event, " ", "warning")
      .then(() => {
        this.hideAddModal();
        this.hideModal();
      })
  }



  handleWeekSubmit() {
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/copyPaste?start=${this.state.weekStart}&end=${this.state.weekEnd}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.post(url1, this.state.copiedWeekShifts).then(client => {
      if (client.status == 201) {
        this.weekCopyAlert();
      }
    }).catch(error => {
      swal("Not pasted!", " ", "warning")
    })
    this.setState({ copiedWeekShifts: [] })
  }




  calculateWeekRange() {
    let now = moment();
    const dayName = moment(now).format('dddd');
    var day = Number(now.weekday())
    var start = now.subtract(day, 'days').format('MM-DD-YYYY');
    var end = now.add(6, 'days').format('MM-DD-YYYY');
    if(dayName=='Sunday'){
      end = moment(start).format('MM-DD-YYYY');
      end = moment(end).subtract(1, 'days').format('MM-DD-YYYY');
      start = moment(end).subtract(6, 'days').format('MM-DD-YYYY'); 
    }
    this.setState({ weekStart: start, weekEnd: end });
    this.state.weekStart = start;
    this.state.weekEnd = end;
  }

  getSupportServices() {
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/support-services?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      this.setState({ supportServices: client.data });
    })
  }


  getClients() {
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/clients?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      this.setState({ clients: client.data });
      return client.data
    })

  }
  dataFormating(event) {
    this.setState({ loadingData: true })
    var fomarted = [];
    var clients = [];
    clients = this.getClients();
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/clients?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(data1 => {
      clients = data1.data;
      var url2 = `${GlobalFunctions.extractToken().API_URL}/clientRoster/all?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&start=${this.state.weekStart}&end=${this.state.weekEnd}`;
      axios.get(url2).then(data => {
        this.setState({ loadingData: false })
        clients.forEach(client => {
          var clientAppointment = [];
          data.data.forEach(appointment => {
            if (client.id == appointment.clientId) {
              clientAppointment.push({
                shiftId: appointment.appointmentId,
                startTime: appointment.startTime,
                endTime: appointment.endTime,
                date: appointment.date,
                serviceName: appointment.serviceName,
                serviceId: appointment.serviceId,
                price: appointment.price,
                day: appointment.day,
                location: appointment.location,
                notes: appointment.notes,
                isApproved: appointment.isApproved,
                isComplete: appointment.isCompleted,
                isConfirmed: appointment.isConfirmed,
              })
            }
          })
          fomarted.push({
            clientId: client.id,
            clientName: client.clientname,
            location: client.chaddress,
            appointments: clientAppointment
          })
          clientAppointment = [];
        })
        this.setState({ schedules: fomarted });
        var list = []
        fomarted.forEach(element => {
          if (element.clientId == this.state.clientId) {
            element.appointments.forEach(app => {
              if (app.day == this.state.selectedDay) {
                list.push(app)
              }
            })
            this.setState({ appointments: list });
          }
        })
      }).catch(err => {

      })
    }).catch(err2 => {

    })


  }



  getNextWeek() {
    var start = moment(this.state.weekEnd).format('MM-DD-YYYY');
    start = moment(start).add(1, 'days').format('MM-DD-YYYY');
    var end = moment(start).add(6, 'days').format('MM-DD-YYYY')
    this.state.weekStart = start;
    this.state.weekEnd = end;
    this.dataFormating();
  }

  getPreviousWeek() {
    var end = moment(this.state.weekStart).format('MM-DD-YYYY');
    end = moment(end).subtract(1, 'days').format('MM-DD-YYYY');
    var start = moment(end).subtract(6, 'days').format('MM-DD-YYYY');
    this.state.weekStart = start;
    this.state.weekEnd = end;
    this.dataFormating();
  }






  render() {

    const columns = [{
      Header: () => (
        <div>
          <form>
            <input className="form-control" type="text" placeholder="Search" />
          </form>
        </div>
      ),
      accessor: 'clientName', // String-based value accessors!
      minWidth: 230,

    }, {
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Sunday
          <br></br>
          {
            moment(moment(this.state.weekStart).format('MM-DD-YYYY')).add(0, 'days').format('DD-MM-YYYY')
          }
        </button>
      ),
      accessor: 'ending_time',
      minWidth: 180,
      Cell: row => {

        var data = row.original.appointments;
        var display = [];
        data.forEach(element => {
          if (element.day == 'Sunday') {
            display.push(element);
          }
        });
        if (display.length != 0) {
          return <div>
            <a className="btn text-white btn-info btn-block btn-sm mb-1 text-left"
            >
              <div className="row">
                <div className="col-md-6">
                  <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{display.length} Client shifts</span></small>
                  <br></br>
                </div>

                <div className="col-md-6">
                  <div className="btn-group">
                    <button
                      className="btn btn-outline-warning btn-sm btn-block dropdown-toggle"
                      type="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={() => {
                        var today = moment(this.state.weekStart).format('MM-DD-YYYY');
                        today = moment(today).add(0, 'days').format('MM-DD-YYYY');
                        this.state.selectedShiftDate = today;
                        var arr = [];
                        const clientId = row.original.clientId;
                        const dayName = "Sunday";
                        row.original.appointments.forEach(element => {
                          if (element.day == dayName) {
                            arr.push(element);
                          }
                        });
                        this.setState({ appointments: arr, clientId: clientId, selectedClientName: row.original.clientName, selectedDay: dayName })
                        this.state.clients.forEach((element) => {
                          if (element.id == clientId) {
                            this.setState({ clientLocation: element.chaddress, })
                          }
                        })
                        this.showAddAppointmentModal();

                      }}
                    >
                      <small>View all</small>
                    </button>
                  </div>
                </div>
              </div>
            </a>
          </div>
        } else {
          return <div>
            <button
              className="btn btn-sm  btn-outline-secondary btn-block"
            >

              <div class="btn-group">
                <button
                  onClick={() => {
                    const clientId = row.original.clientId;
                    const dayName = "Sunday";
                    var today = moment(this.state.weekStart).format('MM-DD-YYYY');
                    today = moment(today).add(0, 'days').format('MM-DD-YYYY');
                    this.state.selectedShiftDate = today;
                    this.state.clients.forEach((element) => {
                      if (element.id == clientId) {
                        this.setState({ appointments: [], clientLocation: element.chaddress, clientId: clientId, selectedClientName: row.original.clientName, selectedDay: dayName })
                      }
                    })
                    this.showAddAppointmentModal();
                  }}
                  class="btn btn-outline-warning btn-full btn-sm mr-1" type="button"

                >
                  +
                  </button>

              </div>

              <button
                className="btn  btn-outline-warning btn-full btn-sm"
              >
                Paste
              </button>

            </button>
          </div>;
        }
      }
    }, {
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Monday
          <br></br>
          {
            moment(moment(this.state.weekStart).format('MM-DD-YYYY')).add(1, 'days').format('DD-MM-YYYY')
          }
        </button>
      ),
      accessor: 'ending_time',
      minWidth: 180,
      Cell: row => {

        var data = row.original.appointments;
        var display = [];
        data.forEach(element => {
          if (element.day == 'Monday') {
            display.push(element);
          }
        });
        if (display.length != 0) {
          return <div>
            <a className="btn text-white btn-info btn-block btn-sm mb-1 text-left"
            >
              <div className="row">
                <div className="col-md-6">
                  <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{display.length} Client shifts</span></small>
                  <br></br>
                </div>

                <div className="col-md-6">
                  <div className="btn-group">
                    <button
                      className="btn btn-outline-warning btn-sm btn-block dropdown-toggle"
                      type="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={() => {
                        var arr = [];
                        const clientId = row.original.clientId;
                        const dayName = "Monday";
                        row.original.appointments.forEach(element => {
                          if (element.day == dayName) {
                            arr.push(element);
                          }
                        });
                        this.setState({ appointments: arr, clientId: clientId, selectedClientName: row.original.clientName, selectedDay: dayName })
                        this.state.clients.forEach((element) => {
                          if (element.id == clientId) {
                            this.setState({ clientLocation: element.chaddress, })
                          }
                        })
                        this.showAddAppointmentModal();

                      }}
                    >
                      <small>View all</small>
                    </button>
                  </div>
                </div>
              </div>
            </a>
          </div>
        } else {
          return <div>
            <button
              className="btn btn-sm  btn-outline-secondary btn-block"
            >

              <div class="btn-group">
                <button
                  onClick={() => {
                    const clientId = row.original.clientId;
                    const dayName = "Monday";
                    var today = moment(this.state.weekStart).format('MM-DD-YYYY');
                    today = moment(today).add(1, 'days').format('MM-DD-YYYY');
                    console.log(today)
                    this.state.selectedShiftDate = today;
                    this.state.clients.forEach((element) => {
                      if (element.id == clientId) {
                        this.setState({ appointments: [], clientLocation: element.chaddress, clientId: clientId, selectedClientName: row.original.clientName, selectedDay: dayName })
                      }
                    })
                    this.showAddAppointmentModal();
                  }}
                  class="btn btn-outline-warning btn-full btn-sm mr-1" type="button"

                >
                  +
                  </button>

              </div>

              <button
                className="btn  btn-outline-warning btn-full btn-sm"
              >
                Paste
              </button>

            </button>
          </div>;
        }
      }
    }, {
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Tuesday
          <br></br>
          {
            moment(moment(this.state.weekStart).format('MM-DD-YYYY')).add(2, 'days').format('DD-MM-YYYY')
          }
        </button>
      ),
      minWidth: 180,
      Cell: row => {

        var data = row.original.appointments;
        var display = [];
        data.forEach(element => {
          if (element.day == 'Tuesday') {
            display.push(element);
          }
        });
        if (display.length != 0) {
          return <div>
            <a className="btn text-white btn-info btn-block btn-sm mb-1 text-left"
            >
              <div className="row">
                <div className="col-md-6">
                  <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{display.length} Client shifts</span></small>
                  <br></br>
                </div>

                <div className="col-md-6">
                  <div className="btn-group">
                    <button
                      className="btn btn-outline-warning btn-sm btn-block dropdown-toggle"
                      type="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={() => {
                        var arr = [];
                        const clientId = row.original.clientId;
                        const dayName = "Tuesday";
                        row.original.appointments.forEach(element => {
                          if (element.day == dayName) {
                            arr.push(element);
                          }
                        });
                        this.setState({ appointments: arr, clientId: clientId, selectedClientName: row.original.clientName, selectedDay: dayName })
                        this.state.clients.forEach((element) => {
                          if (element.id == clientId) {
                            this.setState({ clientLocation: element.chaddress, })
                          }
                        })
                        this.showAddAppointmentModal();

                      }}
                    >
                      <small>View all</small>
                    </button>
                  </div>
                </div>
              </div>
            </a>
          </div>
        } else {
          return <div>
            <button
              className="btn btn-sm  btn-outline-secondary btn-block"
            >

              <div class="btn-group">
                <button
                  onClick={() => {
                    const clientId = row.original.clientId;
                    const dayName = "Tuesday";
                    var today = moment(this.state.weekStart).format('MM-DD-YYYY');
                    today = moment(today).add(2, 'days').format('MM-DD-YYYY');
                    this.state.selectedShiftDate = today;
                    this.state.clients.forEach((element) => {
                      if (element.id == clientId) {
                        this.setState({ appointments: [], clientLocation: element.chaddress, clientId: clientId, selectedClientName: row.original.clientName, selectedDay: dayName })
                      }
                    })
                    this.showAddAppointmentModal();

                  }}
                  class="btn btn-outline-warning btn-full btn-sm mr-1" type="button"

                >
                  +
                  </button>

              </div>

              <button
                className="btn  btn-outline-warning btn-full btn-sm"
              >
                Paste
              </button>

            </button>
          </div>;
        }
      }
    }, {
      id: 'starting_date', // Required because our accessor is not a string
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Wednesday
          <br></br>
          {
            moment(moment(this.state.weekStart).format('MM-DD-YYYY')).add(3, 'days').format('DD-MM-YYYY')
          }
        </button>
      ),
      minWidth: 180,
      Cell: row => {

        var data = row.original.appointments;
        var display = [];
        data.forEach(element => {
          if (element.day == 'Wednesday') {
            display.push(element);
          }
        });
        if (display.length != 0) {
          return <div>
            <a className="btn text-white btn-info btn-block btn-sm mb-1 text-left"
            >
              <div className="row">
                <div className="col-md-6">
                  <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{display.length} Client shifts</span></small>
                  <br></br>
                </div>

                <div className="col-md-6">
                  <div className="btn-group">
                    <button
                      className="btn btn-outline-warning btn-sm btn-block dropdown-toggle"
                      type="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={() => {
                        var arr = [];
                        const clientId = row.original.clientId;
                        const dayName = "Wednesday";
                        row.original.appointments.forEach(element => {
                          if (element.day == dayName) {
                            arr.push(element);
                          }
                        });
                        this.setState({ appointments: arr, clientId: clientId, selectedClientName: row.original.clientName, selectedDay: dayName })
                        this.state.clients.forEach((element) => {
                          if (element.id == clientId) {
                            this.setState({ clientLocation: element.chaddress, })
                          }
                        })
                        this.showAddAppointmentModal();

                      }}
                    >
                      <small>View all</small>
                    </button>
                  </div>
                </div>
              </div>
            </a>
          </div>
        } else {
          return <div>
            <button
              className="btn btn-sm  btn-outline-secondary btn-block"
            >

              <div class="btn-group">
                <button
                  onClick={() => {
                    const clientId = row.original.clientId;
                    const dayName = "Wednesday";
                    var today = moment(this.state.weekStart).format('MM-DD-YYYY');
                    today = moment(today).add(3, 'days').format('MM-DD-YYYY');
                    this.state.selectedShiftDate = today;
                    this.state.clients.forEach((element) => {
                      if (element.id == clientId) {
                        this.setState({ appointments: [], clientLocation: element.chaddress, clientId: clientId, selectedClientName: row.original.clientName, selectedDay: dayName })
                      }
                    })
                    this.showAddAppointmentModal();
                  }}
                  class="btn btn-outline-warning btn-full btn-sm mr-1" type="button"

                >
                  +
                  </button>

              </div>

              <button
                className="btn  btn-outline-warning btn-full btn-sm"
              >
                Paste
              </button>

            </button>
          </div>;
        }
      }
    }, {
      id: 'ending_date', // Required because our accessor is not a string
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Thursday
          <br></br>
          {
            moment(moment(this.state.weekStart).format('MM-DD-YYYY')).add(4, 'days').format('DD-MM-YYYY')
          }
        </button>
      ),
      minWidth: 180,
      Cell: row => {

        var data = row.original.appointments;
        var display = [];
        data.forEach(element => {
          if (element.day == 'Thursday') {
            display.push(element);
          }
        });
        if (display.length != 0) {
          return <div>
            <a className="btn text-white btn-info btn-block btn-sm mb-1 text-left"
            >
              <div className="row">
                <div className="col-md-6">
                  <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{display.length} Client shifts</span></small>
                  <br></br>
                </div>

                <div className="col-md-6">
                  <div className="btn-group">
                    <button
                      className="btn btn-outline-warning btn-sm btn-block dropdown-toggle"
                      type="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={() => {
                        var arr = [];
                        const clientId = row.original.clientId;
                        const dayName = "Thursday";
                        row.original.appointments.forEach(element => {
                          if (element.day == dayName) {
                            arr.push(element);
                          }
                        });
                        this.setState({ appointments: arr, clientId: clientId, selectedClientName: row.original.clientName, selectedDay: dayName })
                        this.state.clients.forEach((element) => {
                          if (element.id == clientId) {
                            this.setState({ clientLocation: element.chaddress, })
                          }
                        })
                        this.showAddAppointmentModal();

                      }}
                    >
                      <small>View all</small>
                    </button>
                  </div>
                </div>
              </div>
            </a>
          </div>
        } else {
          return <div>
            <button
              className="btn btn-sm  btn-outline-secondary btn-block"
            >

              <div class="btn-group">
                <button
                  onClick={() => {
                    const clientId = row.original.clientId;
                    const dayName = "Thursday";
                    var today = moment(this.state.weekStart).format('MM-DD-YYYY');
                    today = moment(today).add(4, 'days').format('MM-DD-YYYY');
                    this.state.selectedShiftDate = today;
                    this.state.clients.forEach((element) => {
                      if (element.id == clientId) {
                        this.setState({ appointments: [], clientLocation: element.chaddress, clientId: clientId, selectedClientName: row.original.clientName, selectedDay: dayName })
                      }
                    })
                    this.showAddAppointmentModal();
                  }}
                  class="btn btn-outline-warning btn-full btn-sm mr-1" type="button"

                >
                  +
                  </button>

              </div>

              <button
                className="btn  btn-outline-warning btn-full btn-sm"
              >
                Paste
              </button>

            </button>
          </div>;
        }
      }
    }, {
      id: 'starting_time', // Required because our accessor is not a string
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Friday
          <br></br>
          {
            moment(moment(this.state.weekStart).format('MM-DD-YYYY')).add(5, 'days').format('DD-MM-YYYY')
          }
        </button>
      ),
      minWidth: 180,
      Cell: row => {
        var data = row.original.appointments;
        var display = [];
        data.forEach(element => {
          if (element.day == 'Friday') {
            display.push(element);
          }
        });
        if (display.length != 0) {
          return <div>
            <a className="btn text-white btn-info btn-block btn-sm mb-1 text-left"
            >
              <div className="row">
                <div className="col-md-6">
                  <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{display.length} Client shifts</span></small>
                  <br></br>
                </div>

                <div className="col-md-6">
                  <div className="btn-group">
                    <button
                      className="btn btn-outline-warning btn-sm btn-block dropdown-toggle"
                      type="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={() => {
                        var arr = [];
                        const clientId = row.original.clientId;
                        const dayName = "Friday";
                        row.original.appointments.forEach(element => {
                          if (element.day == dayName) {
                            arr.push(element);
                          }
                        });
                        this.setState({ appointments: arr, clientId: clientId, selectedClientName: row.original.clientName, selectedDay: dayName })
                        this.state.clients.forEach((element) => {
                          if (element.id == clientId) {
                            this.setState({ clientLocation: element.chaddress, })
                          }
                        })
                        this.showAddAppointmentModal();

                      }}
                    >
                      <small>View all</small>
                    </button>
                  </div>
                </div>
              </div>
            </a>
          </div>
        } else {
          return <div>
            <button
              className="btn btn-sm  btn-outline-secondary btn-block"
            >

              <div class="btn-group">
                <button
                  onClick={() => {
                    const clientId = row.original.clientId;
                    const dayName = "Friday";
                    var today = moment(this.state.weekStart).format('MM-DD-YYYY');
                    today = moment(today).add(5, 'days').format('MM-DD-YYYY');
                    this.state.selectedShiftDate = today;
                    this.state.clients.forEach((element) => {
                      if (element.id == clientId) {
                        this.setState({ appointments: [], clientLocation: element.chaddress, clientId: clientId, selectedClientName: row.original.clientName, selectedDay: dayName })
                      }
                    })
                    this.showAddAppointmentModal();
                  }}
                  class="btn btn-outline-warning btn-full btn-sm mr-1" type="button"

                >
                  +
                  </button>

              </div>

              <button
                className="btn  btn-outline-warning btn-full btn-sm"
              >
                Paste
              </button>

            </button>
          </div>;
        }
      }
    }, {
      id: 'ending_time', // Required because our accessor is not a string
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Saturday
          <br></br>
          {
            moment(moment(this.state.weekStart).format('MM-DD-YYYY')).add(6, 'days').format('DD-MM-YYYY')
          }
        </button>
      ),
      minWidth: 180,
      Cell: row => {
        var data = row.original.appointments;
        var display = [];
        data.forEach(element => {
          if (element.day == 'Saturday') {
            display.push(element);
          }
        });
        if (display.length != 0) {
          return <div>
            <a className="btn text-white btn-info btn-block btn-sm mb-1 text-left"
            >
              <div className="row">
                <div className="col-md-6">
                  <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{display.length} Client shifts</span></small>
                  <br></br>
                </div>

                <div className="col-md-6">
                  <div className="btn-group">
                    <button
                      className="btn btn-outline-warning btn-sm btn-block dropdown-toggle"
                      type="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={() => {
                        var arr = [];
                        const clientId = row.original.clientId;
                        const dayName = "Saturday";
                        row.original.appointments.forEach(element => {
                          if (element.day == dayName) {
                            arr.push(element);
                          }
                        });
                        this.setState({ appointments: arr, clientId: clientId, selectedClientName: row.original.clientName, selectedDay: dayName })
                        this.state.clients.forEach((element) => {
                          if (element.id == clientId) {
                            this.setState({ clientLocation: element.chaddress, })
                          }
                        })
                        this.showAddAppointmentModal();

                      }}
                    >
                      <small>View all</small>
                    </button>
                  </div>
                </div>
              </div>
            </a>
          </div>
        } else {
          return <div>
            <button
              className="btn btn-sm  btn-outline-secondary btn-block"
            >

              <div class="btn-group">
                <button
                  onClick={() => {
                    const clientId = row.original.clientId;
                    const dayName = "Saturday";
                    var today = moment(this.state.weekStart).format('MM-DD-YYYY');
                    today = moment(today).add(6, 'days').format('MM-DD-YYYY');
                    this.state.selectedShiftDate = today;
                    this.state.clients.forEach((element) => {
                      if (element.id == clientId) {
                        this.setState({ appointments: [], clientLocation: element.chaddress, clientId: clientId, selectedClientName: row.original.clientName, selectedDay: dayName })
                      }
                    })
                    this.showAddAppointmentModal();
                  }}
                  class="btn btn-outline-warning btn-full btn-sm mr-1" type="button"

                >
                  +
                  </button>

              </div>

              <button
                className="btn  btn-outline-warning btn-full btn-sm"
              >
                Paste
              </button>

            </button>
          </div>;
        }
      }
    }]

    const tableAppointments = [
      {
        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Start
          </button>
        ),
        accessor: 'startTime',
        maxWidth: 70

      }, {
        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            End
          </button>
        ),
        accessor: 'endTime',
        maxWidth: 70
      }, {
        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Cost/Hr
          </button>
        ),
        accessor: 'price',
        maxWidth: 90
      }, {

        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Location
          </button>
        ),
        accessor: 'location',
        maxWidth: 300

      }, {

        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Notes
          </button>
        ),
        accessor: 'notes',
        maxWidth: 250
      }, {
        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Actions
          </button>
        ),
        Cell: row => {
          return (
            <div>
              {
                row.original.isComplete ? <button
                  type="button"
                  class="btn btn-info btn-sm mr-1"
                >
                  <small>Completed</small>
                </button>
                  :
                  <button
                    type="button"
                    class="btn btn-outline-info btn-sm mr-1"
                  >
                    <small>Uncompleted</small>
                  </button>

              }
              {

                row.original.isApproved ? <button
                  type="button"
                  class="btn btn-success btn-sm mr-1"
                >
                  <small>Approved</small>
                </button>
                  :
                  <button
                    type="button"
                    class="btn btn-outline-success btn-sm mr-1"
                    onClick={() => {
                      var id = row.original.shiftId;
                      this.handleApproveAppointments(id)
                    }}
                  >
                    <small>Approve</small>
                  </button>

              }
              {
                row.original.isConfirmed ?
                  <button
                    type="button"
                    class="btn btn-warning btn-sm mr-1"

                  >
                    <small>Confirmed</small>
                  </button>
                  :
                  <button
                    type="button"
                    class="btn btn-outline-warning btn-sm mr-1"
                    onClick={() => {
                      var id = row.original.shiftId;
                      this.handleConfirmAppointments(id)
                    }}
                  >
                    <small>Confirm</small>
                  </button>
              }
              {
                <button
                  type="button"
                  class="btn btn-outline-danger btn-sm"
                  onClick={() => {
                    var id = row.original.shiftId;
                    this.handleDeleteAppointments(id)
                  }}
                >
                  <small>Delete</small>
                </button>
              }
            </div>
          )
        }
      }]

    const tableEditAppointments = [
      {
        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Start
          </button>
        ),
        Cell: row => {
          return (
            <div>
              {
                <input
                  required
                  type="time"
                  defaultValue={row.original.startTime}
                  className="form-control"
                  onChange={(event) => {
                    if (row.original.shiftId != this.state.editField.shiftId) {
                      this.state.editField.shiftId = row.original.shiftId
                      this.state.editField.startTime = event.target.value
                      this.state.editField.endTime = ""
                      this.state.editField.serviceId = 0
                      this.state.editField.location = ""
                      this.state.editField.client = row.original.clientId
                      this.state.editField.notes = ""

                    } else {
                      this.state.editField.startTime = event.target.value
                    }
                  }}
                  name="startTime" />
              }
            </div>
          )
        },
        minWidth: 51

      }, {
        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            End
          </button>
        ),
        Cell: row => {
          return (
            <div>
              {
                <input
                  required type="time"
                  defaultValue={row.original.endTime}
                  className="form-control"
                  onChange={(event) => {
                    if (row.original.shiftId != this.state.editField.shiftId) {
                      this.state.editField.shiftId = row.original.shiftId
                      this.state.editField.startTime = ""
                      this.state.editField.endTime = event.target.value
                      this.state.editField.serviceId = 0
                      this.state.editField.location = ""
                      this.state.editField.client = row.original.clientId
                      this.state.editField.notes = ""

                    } else {
                      this.state.editField.endTime = event.target.value
                    }

                  }}
                  name="endTime"
                />
              }
            </div>
          )
        },
        minWidth: 51
      }, {
        Header: () => (
          <button
            className="btn text-white btn-warning btn-block"
            type="button"

          >
            Services
          </button>
        ),
        Cell: row => {
          return (
            <div>
              <div
                style={{ "width": "98%", "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis" }}
                onClick={() => {
                  this.state.editField.shiftId = row.original.shiftId
                  this.setState({ isOpenListOfServices: true });
                }}>
                {
                  this.state.selectedServiceName == "" ?
                    row.original.serviceName :
                    row.original.shiftId == this.state.editField.shiftId ?
                      this.state.selectedServiceName : row.original.serviceName}
              </div>
            </div >
          )
        },
      }, {
        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Location
          </button>
        ),
        maxWidth: 250,
        Cell: row => {
          return (
            <div>
              {
                <textarea
                  required
                  type="text"
                  defaultValue={row.original.location}
                  className="form-control"
                  onChange={(event) => {
                    if (row.original.shiftId != this.state.editField.shiftId) {
                      this.state.editField.shiftId = row.original.shiftId
                      this.state.editField.startTime = ""
                      this.state.editField.endTime = ""
                      this.state.editField.serviceId = 0
                      this.state.editField.location = event.target.value
                      this.state.editField.client = row.original.clientId
                      this.state.editField.notes = ""

                    } else {
                      this.state.editField.location = event.target.value
                    }
                  }}
                  name="location"
                />
              }
            </div>
          )
        }
      }, {

        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Notes
          </button>
        ),
        maxWidth: 250,
        Cell: row => {
          return (
            <div>
              {
                <textarea
                  type="text"
                  defaultValue={row.original.notes}
                  className="form-control"
                  onChange={(event) => {
                    if (row.original.shiftId != this.state.editField.shiftId) {
                      this.state.editField.shiftId = row.original.shiftId
                      this.state.editField.startTime = ""
                      this.state.editField.endTime = ""
                      this.state.editField.serviceId = 0
                      this.state.editField.location = ""
                      this.state.editField.client = row.original.clientId
                      this.state.editField.notes = event.target.value

                    } else {
                      this.state.editField.notes = event.target.value
                    }
                  }}
                  name="notes" />
              }
            </div>
          )
        }
      }, {

        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            &nbsp;
          </button>
        ),
        Cell: row => {
          return (
            <div>
              {
                row.original.shiftId == this.state.editField.shiftId
                  &&
                  this.state.isSubmitingInProgress ?
                  <button
                    type="button"
                    className="btn btn-sm btn-success btn-block mr-2"
                    dissabled
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                      Saving data...
                </button>
                  :
                  <button
                    onClick={() => {
                      this.state.appointmentRowData = row.original;
                      this.editAppointments()
                    }
                    }
                    type="button"
                    className="btn btn-sm btn-success btn-block mr-2"
                  >
                    save
                </button>
              }
            </div>
          )
        },
        maxWidth: 60
      }]

    return (
      <div >
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 ml-2 mr-2">
          <h1 className="h2">Client Rostering</h1>
          <div className="btn-toolbar mb-2 mb-md-0">

            <button
              type="button"
              className="btn btn-sm btn-outline-success mr-2"
            >

              0 Admin confirmed
          </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-success mr-2"
            >

              0 Completed

          </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-success mr-2"
            >

              {0} Approved

          </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-success mr-2"
            >

              {0} Shifts

          </button>

            <div className="btn-group mr-2">
              <button onClick={this.getPreviousWeek} type="button" className="btn btn-sm btn-outline-secondary">&#10094;</button>
              <button type="button" className="btn btn-sm btn-outline-secondary">
                <span data-feather="calendar"></span>{moment(this.state.weekStart).format('DD-MM-YYYY')} - {moment(this.state.weekEnd).format('DD-MM-YYYY')}</button>
              <button onClick={this.getNextWeek} type="button" className="btn btn-sm btn-outline-secondary">&#10095;</button>
            </div>
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary mr-2"
              onClick={() => {
                this.showCopyShiftsModal();
                this.calculatePasteWeek();
              }}
            >
              Copy week
          </button>


            <div className="btn-group mr-2">
              <a href={`${GlobalFunctions.extractToken().API_URL}/shifts/exportExcel?start=${this.state.weekStart}&end=${this.state.weekEnd}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`}
                type="button"
                className="btn btn-sm btn-outline-secondary"
              >
                &#8645; Export 
                </a>
            </div>
            <button

              type="button"
              className="btn btn-sm btn-outline-secondary mr-2"
              onClick={() => {
                this.dataFormating();
              }}
            >
              &#8635; Refresh
          </button>


          </div>
        </div>
        <div

        >
          <ReactTable
            data={this.state.schedules}
            loading={this.state.loadingData}
            columns={columns}
            defaultPageSize={10}
            getTdProps={() => {
              return {
                style: {
                  overflow: 'visible'
                }
              }
            }}
          />
        </div>

        {/* List of Services */}
        <Modal show={this.state.isOpenListOfServices} onHide={true} size="lg">
          <Modal.Header>
            <Modal.Title><h5 className="modal-title" >List Of Services</h5></Modal.Title>
            <button type="button" className="close" onClick={() => { this.setState({ isOpenListOfServices: false }) }}>
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row list-group" style={{ 'height': 500, 'overflow': 'scroll' }}>
              {
                this.state.supportServices.map((element) =>
                  <a href="#" className="list-group-item list-group-item-action">
                    <option
                      value={element.supportServiceId}
                      key={element.serviceId}
                      onClick={(event) => {
                        const index = this.state.supportServices.findIndex(x => x.supportServiceId == Number(event.target.value));
                        this.setState({ selectedServiceName: this.state.supportServices[index].supportService })
                        this.state.editField.serviceId = Number(event.target.value);
                        this.state.editField.price = Number(this.state.supportServices[index].supportService)
                        this.setState({ isOpenListOfServices: false })
                      }}
                    >
                      {element.supportService}
                    </option>
                  </a>
                )
              }
            </div>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>



        {/* ADD CLIENT SHIFT MODAL */}
        <Modal show={this.state.ShowAddAppointmentModal} onHide={true} size="xl"  >

          <Modal.Header>
            <Modal.Title><h5 className="modal-title" >Client shifts</h5></Modal.Title>
            <button type="button" className="close" onClick={this.hideAddAppointmentModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Clients shifts</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Add client shift</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Edit client shift</a>
              </li>

            </ul>
            <div class="tab-content" id="myTabContent">

              <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <ReactTable
                  data={this.state.appointments}
                  loading={this.state.loadingData}
                  columns={tableAppointments}
                  defaultPageSize={10}
                />
              </div>


              <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <form onSubmit={this.handleAddAppointment}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-row mt-2">
                        <div className="form-group col-md-6">
                          <label htmlFor="inputEmail4">Clients</label>
                          <input disabled value={this.state.selectedClientName} className="form-control"></input>
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="inputEmail4">Client services</label>
                          <select
                            onChange={(event) => {
                              console.log(arr = this.state.supportServices);
                              this.setTitle(event.target)
                              var serviceId = event.target.value
                              var arr = []
                              arr = this.state.linkedSupportService;
                              arr = this.state.supportServices;
                              var index = arr.findIndex(x => x.supportServiceId == serviceId);
                              if (index >= 0) {
                                this.setState({ costPerHour: arr[index].servicePrice })
                              }
                            }
                            }
                            onFocus={(event) => {
                              this.setTitle(event.target)
                              var serviceId = event.target.value
                              var arr = []
                              arr = this.state.linkedSupportService
                              arr = this.state.supportServices;
                              var index = arr.findIndex(x => x.supportServiceId == serviceId);
                              if (index >= 0) {
                                this.setState({ costPerHour: arr[index].servicePrice })
                              }

                            }
                            }
                            required
                            className="form-control"
                            name="clientServiceId" >
                            {
                              this.state.supportServices.map((element) =>
                                <option value={element.supportServiceId}>{element.supportService}</option>
                              )
                            }

                          </select>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <label htmlFor="inputAddress">Location</label>
                          <input required type="text" className="form-control" name="clientLocation" onChange={event => this.setTitle(event.target)} defaultValue={this.state.clientLocation} placeholder="Location" />

                        </div>

                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <label htmlFor="inputCity">Start time</label>
                          <input required type="time" className="form-control" onChange={event => this.setTitle(event.target)} name="startTime" />

                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="inputCity">End time</label>
                          <input
                            required
                            type="time"
                            className="form-control"
                            onChange={(event) => {
                              this.setTitle(event.target)
                              this.calculateDuration({ startTime: this.state.startTime, endTime: event.target.value })
                            }}
                            name="endTime" />
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="inputCity">Service cost(${this.state.costPerHour}/hour)</label>
                          <input disabled Value={parseFloat(this.state.costPerHour) * this.state.calculatedDuration} type="text" className="form-control" onChange={event => this.setTitle(event.target)} name="costPerHour" />
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleFormControlTextarea1">Notes</label>
                        <textarea className="form-control" onChange={event => this.setTitle(event.target)} name="notes" rows="3"></textarea>

                      </div>
                    </div>
                  </div>
                  {
                    this.state.savingData ?
                      <button
                        className="btn btn-warning"
                        type="Submit"
                        disabled >
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      Saving data...
                   </button>
                      :
                      <button
                        className="btn btn-warning"
                        type="Submit">
                        Save appointment
                   </button>
                  }

                </form>
              </div>

              <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                <ReactTable
                  data={this.state.appointments}
                  loading={this.state.loadingData}
                  columns={tableEditAppointments}
                  defaultPageSize={10}
                  getTdProps={() => {
                    return {
                      style: {
                        overflow: 'visible'
                      }
                    }
                  }}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>

          </Modal.Footer>

        </Modal>

      </div>

    )
  }
}

{/* CALENDAR COMPONENT */ }
class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DatabaseData: [],
      clients: [],
      finSupport: [],
      supportServices: [],
      linkedSupportAreas: [],
      linkedSupportService: [],
      linkedFinancialPackages: [],
      clientFin: [],
      servicePrice: 0,
      serviceCost: 0,

      clientId: 0,
      packageId: 0,
      supportAreaId: 0,
      supportServicesId: 0,
      staffId: 0,
      selectedStaffId: 0,
      selectedShiftId: 0,
      weekStart: '',
      weekEnd: '',
      selectedShiftDetails: {},
      financialPackageId: 0,
      allowance: 0,
      userDetails: {},
      totalShifts: {},
      copiedWeekShifts: [],
      getNextWeeks: [],
      clientLocation: "",
      staffAppointments: [],
      costPerHour: 0,
      calculatedDuration: 0,
      editedAppointments: {
        clientId:0,
        staffId: 0,
        appointmentId: 0,
        startTime: "",
        endTime: "",
        serviceId: 0,
        location: "",
        notes: ""
      },
      isCordinator: false,
      availability: false,
      loadingData: true,
      savingData: false,
      isOpenListOfServices: false,
      selectedServiceName: '',
      isSubmitingInProgress: false,
      clientList: [],
      shiftsList: [],
      filteredData: [],
      searchInput: "",
      selectedListClientAppointmentIds : [],
      selectedStaffIds : [],
      clientShiftOptionsShow : true

    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.getShifts = this.getShifts.bind(this);
    this.editRow = this.editRow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.setTitle = this.setTitle.bind(this);
    this.getStaff = this.getStaff.bind(this);
    this.showAddModal = this.showAddModal.bind(this);
    this.hideAddModal = this.hideAddModal.bind(this);
    this.hideOpenShiftsModal = this.hideOpenShiftsModal.bind(this);
    this.showOpenShiftsModal = this.showOpenShiftsModal.bind(this);
    this.getClients = this.getClients.bind(this);
    this.getFinSupport = this.getFinSupport.bind(this);
    this.getSupportServices = this.getSupportServices.bind(this);
    this.getClientFinancials = this.getClientFinancials.bind(this);
    this.deleteShift = this.deleteShift.bind(this);
    this.calculateWeekRange = this.calculateWeekRange.bind(this);
    this.getNextWeek = this.getNextWeek.bind(this);
    this.getPreviousWeek = this.getPreviousWeek.bind(this);
    this.handleEditShift = this.handleEditShift.bind(this);
    this.handleApproveShift = this.handleApproveShift.bind(this);
    this.handleConfirmShift = this.handleConfirmShift.bind(this);
    this.pasteShift = this.pasteShift.bind(this);
    this.deleteAlert = this.deleteAlert.bind(this);
    this.saveAlert = this.saveAlert.bind(this);
    this.deleteSaveAlert = this.deleteSaveAlert.bind(this);
    this.approveAlert = this.approveAlert.bind(this);
    
    this.countShifts = this.countShifts.bind(this);
    this.calculatePasteWeek = this.calculatePasteWeek.bind(this);
    this.showCopyShiftsModal = this.showCopyShiftsModal.bind(this);
    this.hideCopyShiftsModal = this.hideCopyShiftsModal.bind(this);
    this.weekCopyAlert = this.weekCopyAlert.bind(this);
    this.handleWeekSubmit = this.handleWeekSubmit.bind(this);
    this.showAddAppointmentModal = this.showAddAppointmentModal.bind(this);
    this.hideAddAppointmentModal = this.hideAddAppointmentModal.bind(this);
    this.handleAddAppointment = this.handleAddAppointment.bind(this);
    this.saveAppointmentAlert = this.saveAppointmentAlert.bind(this);
    this.getAppointments = this.getAppointments.bind(this);
    this.handleSubmitEditedAppointment = this.handleSubmitEditedAppointment.bind(this);
    this.calculateDuration = this.calculateDuration.bind(this);
    this.checkAvailability = this.checkAvailability.bind(this);
    this.handleApproveAppointments = this.handleApproveAppointments.bind(this);
    this.handleDeleteAppointments = this.handleDeleteAppointments.bind(this);
    this.deleteAppointmentAlert = this.deleteAppointmentAlert.bind(this);
    this.handleConfirmAppointments = this.handleConfirmAppointments.bind(this);
    this.confirmAppointmentAlert = this.confirmAppointmentAlert.bind(this);
    this.networkFailureAlert = this.networkFailureAlert.bind(this);

  }


  handleChange = event => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput } = this.state;
    let filteredData = this.state.shiftsList.filter(value => {
      return (
        value.staffName.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ DatabaseData: filteredData });
    console.log(filteredData);
  };

  handleApproveTimesheets123 = () => {
    axios.get(`${GlobalFunctions.extractToken().API_URL}/shifts/bulkApprove?start=${this.state.weekStart}&end=${this.state.weekEnd}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`).then(result => {
      swal("Approved Successfully!", " ", "success");
    }).catch(error => {
      console.log(error);
      swal("Not Approved!", " ", "warning");
    })
  }

  calculateDuration(event) {
    var startTime = date.parse(event.startTime, 'HH:mm:ss A', true);
    var endTime = date.parse(event.endTime, 'HH:mm:ss A', true);
    var duration = date.subtract(endTime, startTime).toMinutes();
    duration = duration / 60;
    this.setState({ calculatedDuration: duration })
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.name === 'isGoing' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    this.setState({ savingData: true })

    event.preventDefault();

    const infor = {
      "staffId": Number(this.state.selectedStaffId),
      "location": this.state.address,
      "Date": this.state.selectedShiftDate,
      "startTime": this.state.startTime,
      "endTime": this.state.endTime,
      "breaking": this.state.breakMin,
      "notes": this.state.notes,
      "allowance": 0

    };

    if (Number(this.state.selectedStaffId) == -1) {
      axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/add2?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
        .then(res => {
          this.setState({ savingData: false })
          if (res.status == 201) {
            this.saveAlert();
            this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
          }

        }).catch(error => {
          this.setState({ savingData: false })

        });
    } else {
      axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/add?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
        .then(res => {
          this.setState({ savingData: false })
          if (res.status == 201) {
            this.saveAlert();
            this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
          }

        }).catch(error => {
          let errorObject = JSON.parse(JSON.stringify(error));
          this.networkFailureAlert(errorObject.message)
          this.setState({ savingData: false })

        })
    }


  }
  handleAddAppointment(event) {
    this.setState({ savingData: true })
    event.preventDefault();

    var valid = true;
    const infor = {
      "isCordinator": this.state.isCordinator,
      "staffId": Number(this.state.selectedStaffId)>0?Number(this.state.selectedStaffId):null,
      "location": this.state.clientLocation,
      "date": this.state.selectedShiftDate,
      "shiftId": Number(this.state.selectedShiftId),
      "startTime": this.state.startTime,
      "endTime": this.state.endTime,
      "clientId": Number(this.state.clientId),
      "notes": this.state.notes,
      "serviceId": Number(this.state.clientServiceId),
      "costPerHour": this.state.costPerHour

    };

    if (this.state.clientId == 0 || isNaN(this.state.clientServiceId)) {
      valid = false;
      this.setState({ savingData: false });
      if (this.state.clientId == 0) {
        swal("Select Client Please!", " ", "warning");
      }
      else if (isNaN(this.state.clientServiceId)) {
        swal("Select Service Please!", " ", "warning");
      }
    }
   
    if (valid) {
      axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/appointStaff?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
        .then(res => {
          this.setState({ savingData: false });
          this.setState({
            clientLocation: "",
            clientId: 0,
            startTime: "",
            endTime: "",
            costPerHour: 0,
            notes: "",
          })
          if (res.status == 201) {
            this.getAppointments({ shiftId: this.state.selectedShiftId, isCordinator: this.state.isCordinator })
            this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
            this.saveAppointmentAlert();
          }
        }).catch(error => {
          this.setState({ savingData: false })
          let errorObject = JSON.parse(JSON.stringify(error));
          this.networkFailureAlert(errorObject.message)

        })
    }

  }
  handleSubmitEditedAppointment(event) {
    this.setState({ savingData: true })

    var index = 0;
    var arr = [];
    arr = this.state.staffAppointments
    index = arr.findIndex(x => x.appointmentId === this.state.editedAppointments.appointmentId);
    if (index < 0) {
      index = arr.findIndex(x => x.shiftId === this.state.editedAppointments.appointmentId);
    }


    const infor = {
      "staffId": this.state.editedAppointments.staffId == 0 ? this.state.staffAppointments[index].staffId : this.state.editedAppointments.staffId,
      "location": this.state.editedAppointments.location == "" ? this.state.staffAppointments[index].clientLocaton : this.state.editedAppointments.location,
      "startTime": this.state.editedAppointments.startTime == "" ? this.state.staffAppointments[index].startTime : this.state.editedAppointments.startTime,
      "endTime": this.state.editedAppointments.endTime == "" ? this.state.staffAppointments[index].endTime : this.state.editedAppointments.endTime,
      "serviceId": this.state.editedAppointments.serviceId == 0 ? this.state.staffAppointments[index].serviceId : this.state.editedAppointments.serviceId,
      "notes": this.state.editedAppointments.notes == "" ? this.state.staffAppointments[index].notes : this.state.editedAppointments.notes,
      "clientId": this.state.editedAppointments.clientId == 0 ? this.state.staffAppointments[index].clientId : this.state.editedAppointments.clientId

    };


    axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/updateAppointment?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&appointmentId=${this.state.editedAppointments.appointmentId}`, infor)
      .then(res => {
        this.setState({ savingData: false, isSubmitingInProgress: false })
        if (res.status == 200) {
          this.getAppointments({ shiftId: this.state.selectedShiftId, isCordinator: this.state.isCordinator })
          this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
          this.saveAppointmentAlert();

        }


      }).catch(error => {
        this.setState({ savingData: false, isSubmitingInProgress: false })
        let errorObject = JSON.parse(JSON.stringify(error));
        this.networkFailureAlert(errorObject.message)

      })

    this.state.editedAppointments.startTime = undefined
    this.state.editedAppointments.endTime = undefined
    this.state.editedAppointments.location = undefined
    this.state.editedAppointments.notes = undefined
    this.state.editedAppointments.serviceId = undefined

  }

  handleEditShift(event) {
    this.setState({ savingData: true })

    if (this.state.selectedStaffId == -1) {
      const infor = {
        "location": this.state.address == undefined ? this.state.selectedShiftDetails.clientLocation : this.state.address,
        "Date": this.state.date == undefined ? this.state.selectedShiftDate : this.state.date,
        "startTime": this.state.startTime == undefined ? this.state.selectedShiftDetails.startTime : this.state.startTime,
        "endTime": this.state.endTime == undefined ? this.state.selectedShiftDetails.endTime : this.state.endTime,
        "breaking": this.state.breakMin == undefined ? this.state.selectedShiftDetails.breaking : this.state.breakMin,
        "notes": this.state.notes == undefined ? this.state.selectedShiftDetails.notes : this.state.notes,


      };

      axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/editOpenShift?shiftId=${this.state.selectedShiftId}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
        .then(res => {
          this.setState({ savingData: false })
          if (res.status == 200) {
            this.saveAlert();
            this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
          }


        }).catch(error => {
          this.setState({ savingData: false })
          let errorObject = JSON.parse(JSON.stringify(error));
          this.networkFailureAlert(errorObject.message)

        })

    } else {
      const infor = {
        "location": this.state.address == undefined ? this.state.selectedShiftDetails.clientLocation : this.state.address,
        "Date": this.state.date == undefined ? this.state.selectedShiftDate : this.state.date,
        "startTime": this.state.startTime == undefined ? this.state.selectedShiftDetails.startTime : this.state.startTime,
        "endTime": this.state.endTime == undefined ? this.state.selectedShiftDetails.endTime : this.state.endTime,
        "breaking": this.state.breakMin == undefined ? this.state.selectedShiftDetails.breaking : this.state.breakMin,
        "notes": this.state.notes == undefined ? this.state.selectedShiftDetails.notes : this.state.notes,


      };




      axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/edit/${this.state.selectedShiftId}?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
        .then(res => {
          this.setState({ savingData: false })
          if (res.status == 200) {
            this.saveAlert();
            this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
          }


        }).catch(error => {
          this.setState({ savingData: false })
          let errorObject = JSON.parse(JSON.stringify(error));
          this.networkFailureAlert(errorObject.message)

        })
    }


    this.state.startTime = undefined
    this.state.endTime = undefined
    this.state.address = undefined
    this.state.notes = undefined
    this.state.breakMin = undefined

  }



  showModal() {
    this.setState({ ShowModal: true });
  }

  hideModal() {
    this.setState({ ShowModal: false });
  }
  showAddModal() {
    this.setState({ ShowAddModal: true });
  }
  showAddAppointmentModal() {
    this.setState({ ShowAddAppointmentModal: true });
  }
  showCopyShiftsModal() {
    this.setState({ ShowCopyShiftsModal: true });
  }

  hideCopyShiftsModal() {
    this.setState({ ShowCopyShiftsModal: false });
  }
  hideAddModal() {
    this.setState({ ShowAddModal: false });
  }
  hideAddAppointmentModal() {
    this.setState({ staffAppointments: [] })
    this.setState({ ShowAddAppointmentModal: false });
  }
  showOpenShiftsModal() {
    this.setState({ ShowOpenShiftsModal: true });
  }

  hideOpenShiftsModal() {
    this.setState({ ShowOpenShiftsModal: false });
  }

  setTitle(event) {

    this.setState({ [event.name]: event.value })


  }


  //AN HTTP REQUEST TO THE getAllShifts-endpoint
  getShifts(event) {
    this.setState({ loadingData: true })
    var shiftsList = [];
    var sortedShiftList = [];
    var openShiftObject;
    var staffList = [];
    var temp = [];
    var finalData = [];
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/all?start=${event.start}&end=${event.end}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(shifts => {
      this.setState({ shifts: shifts.data });
      //FETCHING ALL STAFF FROM API
      url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/staff?weekStart=${event.start}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      axios.get(url1).then(staff => {
        url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/all/openShifts?start=${event.start}&end=${event.end}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
        axios.get(url1).then(openShift => {
          shiftsList = shifts.data;
          staffList = staff.data;

          //ADDING OPEN SHIFTS FOR DISPLAY
          var openShiftsAvailability = [{
            "day": "Sunday",
            "avaible": true
          },
          {
            "day": "Monday",
            "avaible": true
          },
          {
            "day": "Tuesday",
            "avaible": true
          },
          {
            "day": "Wednesday",
            "avaible": true
          },
          {
            "day": "Thursday",
            "avaible": true
          },
          {
            "day": "Friday",
            "avaible": true
          },
          {
            "day": "Saturday",
            "avaible": true
          }]
          openShiftObject = { staffId: -1, availability: openShiftsAvailability, staffName: 'Open Shifts', shifts: openShift.data };

          //GROUPING SHIFTS ACCORDING TO STAFF
          staffList.forEach(staffElement => {
            shiftsList.forEach(shiftElement => {
              if (staffElement.id == shiftElement.staffId) {
                temp.push(shiftElement);
              }
            });
            finalData.push({ staffId: staffElement.id, staffName: staffElement.lastname + ' ' + staffElement.firstname, availability: staffElement.availability, shifts: temp });
            temp = [];
          });
          finalData = finalData.sort(function (a, b) {
            if (a.staffName < b.staffName) {
              return -1;
            }
            if (a.staffName > b.staffName) {
              return 1;
            }
            return 0;
          })
          sortedShiftList.push(openShiftObject);
          finalData.forEach(element => {
            sortedShiftList.push(element);
          })
          this.setState({ DatabaseData: sortedShiftList, shiftsList: sortedShiftList, loadingData: false })
          this.countShifts(finalData);

          return finalData
        }).catch(error => {

        })
      }).catch(error => {

      })
    }).catch(error => {

    })
  }

  handleWeekSubmit() {
    if (this.state.selectedStaffIds.length==0) {
        var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/copyPaste?start=${this.state.weekStart}&end=${this.state.weekEnd}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
        axios.post(url1, this.state.copiedWeekShifts).then(client => {
          if (client.status == 201) {
            this.weekCopyAlert();
          }
          this.setState({selectedStaffIds:[]})
        }).catch(error => {
          swal("Not pasted!", " ", "warning")
        })
    }else{
      this.state.selectedStaffIds.forEach(element=>{
        var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/copyPaste?staffId=${element}&start=${this.state.weekStart}&end=${this.state.weekEnd}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
        axios.post(url1, this.state.copiedWeekShifts).then(client => {
          if (client.status == 201) {
            this.weekCopyAlert();
          }
        }).catch(error => {
          swal("Not pasted!", " ", "warning")
        })
      })
    }
    

    this.setState({selectedStaffIds:[]});
    this.setState({ copiedWeekShifts: [] });
  }

  countShifts(event) {
    var shifts = 0;
    var approved = 0;
    var completed = 0;
    var adminApproved = 0;
    event.forEach(element => {
      if (true) {
        element.shifts.forEach(element2 => {
          shifts++
          if (element2.isApproved) {
            approved++
          }
          if (element2.isCompleted || element2.isComplete) {
            completed++
          }
          if (element2.isAdminConfirmed || element2.isConfirmed) {
            adminApproved++
          }
        })
      }

      this.setState({ totalShifts: { totalShifts: shifts, totalApproved: approved, totalAdminConfirmed: adminApproved, totalCompleted: completed } })

    })

  }

  checkAvailability(event) {
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/checkStaffAvailability?date=${event.date}&staffId=${event.staffId}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {

      this.setState({ availability: { date: event.date, staffId: event.staffId, status: client.data } })
    })
  }
  getClients() {
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/clients?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      this.setState({ clients: client.data });
    })

  }

  getAppointments(event) {
    this.setState({ loadingData: true })

    if (event.isCordinator) {
      var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/getAppointments?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&shiftId=${event.shiftId}`;
      axios.get(url1).then(client => {

        this.setState({ staffAppointments: client.data, loadingData: false });
      })
    } else {
      var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/staffclientshift?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&staffId=${this.state.selectedStaffId}&date=${this.state.selectedShiftDate}`;
      axios.get(url1).then(client => {

        this.setState({ staffAppointments: client.data, loadingData: false });
      })
    }
  }

  getFinSupport() {
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/finsupport?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      this.setState({ finSupport: client.data });
    })

  }

  getSupportServices() {
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/support-services?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      this.setState({ supportServices: client.data });
    })

  }
  getClientFinancials() {
    var url1 = '${GlobalFunctions.extractToken().API_URL}/shifts/clientFin';
    axios.get(url1).then(client => {
      this.setState({ clientFin: client.data });
    })

  }

  deleteShift(event) {
    this.setState({ savingData: true })
    if (this.state.selectedStaffId == -1) {
      var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/deleteOpenShift?shiftId=${event}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      axios.get(url1).then(client => {
        this.setState({ savingData: false })
        if (client.status == 200) {
          this.deleteSaveAlert();
          this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
        }
      }).catch(error => {
        this.setState({ savingData: false })
        let errorObject = JSON.parse(JSON.stringify(error));
        this.networkFailureAlert(errorObject.message)
      })

    } else {
      var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/delete/${event}?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      axios.get(url1).then(client => {
        this.setState({ savingData: false })
        if (client.status == 200) {
          this.deleteSaveAlert();
          this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
        }

      }).catch(error => {
        this.setState({ savingData: false })
        let errorObject = JSON.parse(JSON.stringify(error));
        this.networkFailureAlert(errorObject.message)
      })
    }


  }

  handleApproveShift(selectedShiftId) {
    this.setState({ savingData: true })
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/approve?shiftId=${this.state.selectedShiftId}&staffId=${GlobalFunctions.extractToken().STAFF_ID}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.s(url1).then(client => {
      this.setState({ savingData: false })
      if (client.status == 200) {
        this.approveAlert();
        this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
      }
    }).catch(error => {
      this.setState({ savingData: false })
      let errorObject = JSON.parse(JSON.stringify(error));
      this.networkFailureAlert(errorObject.message)

    })

  }

  handleCanceOfficelShift =(selectedShiftId) =>{
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/cancel?shiftId=${selectedShiftId}&staffId=${GlobalFunctions.extractToken().STAFF_ID}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      if (client.status == 200) {
        this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd });
        swal("successfully cancelled!", " ", "success")
      }
    }).catch(error => {
      let errorObject = JSON.parse(JSON.stringify(error));
      this.networkFailureAlert(errorObject.message)
    })

  }

  handleApproveAppointments(appointmentId) {
    this.setState({ savingData: true });
    var pos=0;
    this.state.selectedListClientAppointmentIds.forEach(element=>{
      pos++;
      var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/approveAppointment?appointmentId=${element}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
        axios.get(url1).then(client => {
          if (pos==this.state.selectedListClientAppointmentIds.length) {
            this.setState({selectedListClientAppointmentIds : []})
            this.setState({ savingData: false })
            this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd });
            this.getAppointments({ shiftId: this.state.selectedShiftId, isCordinator: this.state.isCordinator });
            this.approveAlert();
          }
        }).catch(error => {
          this.setState({ savingData: false })
          let errorObject = JSON.parse(JSON.stringify(error));
          this.networkFailureAlert(errorObject.message)
        })
    })
   

  }
  confirmAppointmentAlert=(event)=>{
    swal({
      title: "Are about to confirm Selected Items!",
      text: "NOT cancelled shifts will become Open Shifts",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
       if(willDelete){
        this.handleConfirmAppointments();
       }
      })
  }

  handleConfirmAppointments(appointmentId) {
    this.setState({ savingData: true })
    var pos=0;
    this.state.selectedListClientAppointmentIds.forEach(element=>{
      pos++;
      var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/confirmAppointment?appointmentId=${element}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      axios.get(url1).then(client => {
        if (pos==this.state.selectedListClientAppointmentIds.length) {
          this.setState({selectedListClientAppointmentIds : []})
          this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
          this.getAppointments({ shiftId: this.state.selectedShiftId, isCordinator: this.state.isCordinator })
          this.setState({ savingData: false });
          this.confirmAppointmentAlert();
        }
      }).catch(error => {
        this.setState({ savingData: false })
        let errorObject = JSON.parse(JSON.stringify(error));
        this.networkFailureAlert(errorObject.message)
      })
    })

  }
  deleteAppointmentAlert=(event)=>{
    swal({
      title: "Are you sure you want to Delete the Client Appointments?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
       if(willDelete){
        this.handleDeleteAppointments();
       }
      })
  }
  handleDeleteAppointments(appointmentId) {
    this.setState({ savingData: true })
    var pos=0;
    this.state.selectedListClientAppointmentIds.forEach(element=>{
      pos++;
      var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/deleteAppointment?appointmentId=${element}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      axios.get(url1).then(client => {
        this.setState({ savingData: false })
        if (pos == this.state.selectedListClientAppointmentIds.length) {
          this.setState({selectedListClientAppointmentIds : []})
          this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
          this.getAppointments({ shiftId: this.state.selectedShiftId, isCordinator: this.state.isCordinator })
          swal("successfully deleted!", " ", "success")
          .then(() => {
            this.hideModal();
          })
        }
      }).catch(error => {
        this.setState({ savingData: false })
        let errorObject = JSON.parse(JSON.stringify(error));
        this.networkFailureAlert(errorObject.message)
      })
    })

  }

  handleConfirmShift(selectedShiftId) {
    this.setState({ savingData: true })
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/confirm?shiftId=${this.state.selectedShiftId}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      this.setState({ savingData: false })
      // this.setState({ clients: client.data });
      this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
      this.hideModal();
    })
  }
  cancelAppointmentAlert=(event)=>{
    swal({
      title: "You are about to Cancel an Client Shift?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
       if(willDelete){
        this.handleCancelClientAppointmentShift();
       }
      })
  }
  handleCancelClientAppointmentShift = () =>{
    var pos = 0;
    this.setState({ savingData: true });
    this.state.selectedListClientAppointmentIds.forEach(element=>{
      pos++;
      var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/cancelAppointment?appointmentId=${element}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      axios.get(url1).then(client => {
        if(pos==this.state.selectedListClientAppointmentIds.length){
          this.setState({selectedListClientAppointmentIds : []})
          this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
          this.getAppointments({ shiftId: this.state.selectedShiftId, isCordinator: this.state.isCordinator })
          this.setState({ savingData: false });
          swal("successfully cancelled!", " ", "success")
          .then(() => {
            this.hideAddModal();
            this.hideModal();
          })
        }
      }).catch(error => {
        this.setState({ savingData: false })
        let errorObject = JSON.parse(JSON.stringify(error));
        this.networkFailureAlert(errorObject.message)
      })
    })
    
  }
  calculateWeekRange() {
    let now = moment();
    const dayName = moment(now).format('dddd');
    var day = Number(now.weekday()) - 1
    var start = now.subtract(day, 'days').format('MM-DD-YYYY');
    var end = now.add(6, 'days').format('MM-DD-YYYY');
  
    if(dayName=='Sunday'){
      end = moment(start).format('MM-DD-YYYY');
      end = moment(end).subtract(1, 'days').format('MM-DD-YYYY');
      start = moment(end).subtract(6, 'days').format('MM-DD-YYYY'); 
    }
    this.setState({ weekStart: start, weekEnd: end });
    this.getShifts({ start: start, end: end });
  }

  getNextWeek() {
    var start = moment(this.state.weekEnd).format('MM-DD-YYYY');
    start = moment(start).add(1, 'days').format('MM-DD-YYYY');
    var end = moment(start).add(6, 'days').format('MM-DD-YYYY')

    this.getShifts({ start: start, end: end })
    this.setState({ weekStart: start, weekEnd: end })
  }

  getPreviousWeek() {
    var end = moment(this.state.weekStart).format('MM-DD-YYYY');
    end = moment(end).subtract(1, 'days').format('MM-DD-YYYY');
    var start = moment(end).subtract(6, 'days').format('MM-DD-YYYY');

    this.getShifts({ start: start, end: end })
    this.setState({ weekStart: start, weekEnd: end })
  }

  pasteShift(event) {


    const infor = {
      "staffId": Number(event.selectedStaffId),
      "location": this.state.selectedShiftDetails.clientLocation,
      "Date": event.selectedShiftDate,
      "startTime": this.state.selectedShiftDetails.startTime,
      "endTime": this.state.selectedShiftDetails.endTime,
      "breaking": this.state.selectedShiftDetails.breaking,
      "notes": this.state.selectedShiftDetails.notes,
      "allowance": Number(this.state.selectedShiftDetails.allowance)
    };

    if (Number(this.state.selectedStaffId) == -1) {

      axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/add2?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
        .then(res => {

          this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
          this.hideModal();

        }).catch(error => {
          let errorObject = JSON.parse(JSON.stringify(error));
          this.networkFailureAlert(errorObject.message)
        });
    } else {
      axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/add?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
        .then(res => {

          this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
          this.hideModal();

        }).catch(error => {
          let errorObject = JSON.parse(JSON.stringify(error));
          this.networkFailureAlert(errorObject.message)

        })
    }


  }


  calculatePasteWeek() {
    var start = moment(this.state.weekEnd).format('MM-DD-YYYY');
    start = moment(start).add(1, 'days').format('MM-DD-YYYY');
    var end = moment(start).add(6, 'days').format('MM-DD-YYYY')
    var arr = [];
    for (let index = 0; index < 9; index++) {
      var end = moment(start).add(6, 'days').format('MM-DD-YYYY');
      arr.push({ weekStart: start, weekEnd: end });
      start = moment(end).add(1, 'days').format('MM-DD-YYYY');
    }
    this.setState({ getNextWeeks: arr });

  }

  cancellShift = () =>{
    axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/updateAppointment?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&appointmentId=${98}`, {status : 0})
    .then(res => {
      this.setState({ savingData: false, isSubmitingInProgress: false })
      if (res.status == 200) {
        this.getAppointments({ shiftId: this.state.selectedShiftId, isCordinator: this.state.isCordinator })
        this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
        this.saveAppointmentAlert();
      }

    }).catch(error => {
      this.setState({ savingData: false, isSubmitingInProgress: false })
      let errorObject = JSON.parse(JSON.stringify(error));
      this.networkFailureAlert(errorObject.message)

    })
  }

  componentDidMount() {
    GlobalFunctions.extractToken();
    this.getSupportServices();
    this.getStaff();
    this.getClients();
    this.getFinSupport();
    this.getClientFinancials();
    this.calculateWeekRange();
    //this.cancellShift();
  }

  networkFailureAlert(event) {
    swal(event, " ", "warning")
      .then(() => {
        this.hideAddModal();
        this.hideModal();
      })
  }
  saveAlert() {
    swal("successfully saved!", " ", "success")
      .then(() => {
        this.hideAddModal();
        this.hideModal();
      })
  }
  confirmAppointmentAlert() {
    swal("successfully confirmed!", " ", "success")
      .then(() => {
        this.hideAddModal();
        this.hideModal();
      })
  }
  saveAppointmentAlert() {
    swal("successfully saved!", " ", "success")
      .then(() => {
      })
  }
  weekCopyAlert() {
    swal("Week successfully copied!", " ", "success")
      .then(() => {
        this.hideCopyShiftsModal();
        this.hideModal();
      })
  }
  approveAlert() {
    swal("successfully approved!", " ", "success")
      .then(() => {
        this.hideAddModal();
        this.hideModal();
      })
  }
  deleteSaveAlert() {
    swal("successfully deleted!", " ", "success")
      .then(() => {
        this.hideModal();
      })
  }
  deleteAppointmentAlert() {
    swal("successfully deleted!", " ", "success")
      .then(() => {
        this.hideModal();
      })
  }

  deleteAlert(event) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          this.deleteShift(event);
        }
      })
  }

  getStaff() {

    //FETCHING ALL STAFF FROM API
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/staff?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(staff => {



      this.setState({ staffList: staff.data });
      this.checkAvailability(staff.data);


    }).catch(error => {

    })
  }

  editRow(props) {

  }

  popoverHoverFocus = (event) => {
    return (
      <Popover id="popover-trigger-hover-focus" title="Popover bottom">
        <table class="table table-bordered">
          {
            event.map((element) => {
              return (
                <tr>
                  <td>{element.clientName}</td>
                  <td>{element.startTime} - {element.endTime}</td>
                </tr>
              )

            })
          }
        </table>
      </Popover>
    )
  }


  render() {

    const columns = [{
      Header: () => (
        <div>
          <form>
            <input
              defaultValue={this.state.searchInput}
              onChange={this.handleChange}
              id="input1"
              onMouseOver={() => {
                document.getElementById("input1").focus();
              }}
              className="form-control seach-bar"
              type="text"
              placeholder="Search"
              style={{
                height: "unset",
                lineHeight: 3
              }} />
          </form>
        </div>
      ),
      // accessor: 'staffName', // String-based value accessors!
      // id: 'staffName',
      Cell : row =>{
        return(
          <div
            onClick={()=>{
              console.log(row.original.staffId)
              this.state.selectedStaffIds.push(row.original.staffId)
            }}
          >
              <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"/>
              <label class="form-check-label" for="flexCheckChecked">
                {row.original.staffName}
              </label>
            </div>
          </div>
        )
      },
      minWidth: 230,
      sortable: false

    }, {
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Monday
          <br></br>
          {
            moment(moment(this.state.weekStart).format('MM-DD-YYYY')).add(0, 'days').format('DD-MM-YYYY')
          }
        </button>
      ),
      minWidth: 180,
      sortable: false,
      Cell: row => {
        var e = row.original;
        var staffId = e.staffId;
        var array = [];
        var display = [];
        array = e.shifts;
        var index = 0;
        index = array.findIndex(x => x.day === 'Monday');
        if (index >= 0) {
          array.forEach(element => {
            if (element.day == 'Monday') {
              display.push(element);
            }
          });

          var shiftPrefix = display[0]
          var clientList = [];
          if (isNaN(shiftPrefix.shiftId)) {
            display.forEach(element => {
              if (!clientList.includes(element.clientName)) {
                clientList.push(element);
              }
            });
          }


          if (isNaN(shiftPrefix.shiftId)) {
            return <div>
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={this.popoverHoverFocus(clientList)}
                onMouseEnter={() => {

                  this.setState({ clientList: clientList });
                }

                }
              >
                <a className="btn text-white btn-info btn-block btn-sm mb-1 text-left"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{display.length} Client shifts</span></small>
                      <br></br>
                    </div>

                    <div className="col-md-6">
                      <div className="btn-group">
                        <button
                          className="btn btn-outline-warning btn-sm btn-block dropdown-toggle"
                          type="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() => {
                            var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                            var today = moment(weekStart).add(0, 'days').format('MM-DD-YYYY')
                            this.setState({ isCordinator: false })
                            this.setState({ selectedStaffId: e.staffId });
                            this.setState({ selectedShiftDate: today });
                            this.setState({ staffAppointments: display });
                            this.showAddAppointmentModal();
                          }}
                        >
                          <small>View all</small>
                        </button>
                      </div>
                    </div>
                  </div>
                </a>
              </OverlayTrigger>
            </div>

          } else {
            return display.map(function (item, i) {
              return <div>

                <a className="btn text-white btn-success btn-block btn-sm mb-1 text-left"
                  key={i}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{item.clientLocation}</span></small>
                      <br></br>
                      <small>
                        {item.clientName}<span className="badge badge-danger ml-2 ">{item.startTime}-{item.endTime}</span>
                      </small>

                      {/* <button type="button" class="btn btn-default btn-tooltip" data-toggle="tooltip" data-placement="top" title="Tooltip on top" data-container="body" data-animation="true">On top</button> */}


                    </div>


                    <div className="col-md-6">
                      <div className="btn-group">
                        <button className="btn btn-outline-warning btn-sm btn-block dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <small>Actions</small>
                        </button>
                        <div className="dropdown-menu"  >
                          <h6 class="dropdown-header">Actions</h6>
                          <a className="dropdown-item"

                            onClick={() => {
                              this.setState({ isCordinator: true })
                              this.getAppointments({ shiftId: item.shiftId, isCordinator: true });
                              this.showAddAppointmentModal();
                              this.setState({ selectedShiftId: item.shiftId });
                              this.setState({ selectedShiftDate: item.date });
                              this.setState({ selectedStaffId: e.staffId });
                              this.setState({ selectedStaffName: e.staffName });

                              this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                            }}
                            href="#">Client shift <span class="badge badge-primary">{this.getAppointments.length}</span></a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              this.setState({ selectedShiftId: item.shiftId });
                              this.setState({ selectedShiftDate: item.date });
                              this.setState({ selectedStaffId: e.staffId });
                              this.setState({ selectedStaffName: e.staffName });

                              this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                            }}
                          >Copy shift
                        </a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {

                              this.pasteShift({ selectedShiftDate: item.date, selectedStaffId: e.staffId });
                            }}

                          >
                            Paste shift
                        </a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              this.setState({ selectedStaffId: e.staffId })
                              this.deleteAlert(item.shiftId)

                            }}
                          >
                            Delete shift
                        </a>
                        <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              this.setState({ selectedStaffId: e.staffId });
                              this.handleCanceOfficelShift(item.shiftId);
                            }}
                          >
                            Cancel shift
                        </a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              this.setState({ selectedShiftId: item.shiftId });
                              this.setState({ selectedShiftDate: item.date });
                              this.setState({ selectedStaffId: e.staffId });
                              this.setState({ selectedStaffName: e.staffName });

                              this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                              this.showModal()
                            }}
                          >
                            Edit shift
                        </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            }, this)
          }


        } else {
          var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
          var today = moment(weekStart).add(0, 'days').format('MM-DD-YYYY')
          var arr = [];
          arr = row.original.availability;
          var index = 0;
          if (arr != undefined) {
            index = arr.findIndex(x => x.day === 'Monday');
          }
          if (arr[index].avaible) {
            return (
              <div>
                <button
                  className="btn  btn-outline-secondary btn-block"
                >

                  <div class="btn-group">
                    <button class="btn btn-outline-warning btn-full btn-sm mr-1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      +
                  </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <h6 class="dropdown-header">Actions</h6>
                      <a
                        class="dropdown-item"
                        href="#"
                        onClick={() => {
                          this.setState({ staffAppointments: [] })
                          this.setState({ isCordinator: false })
                          var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                          var today = moment(weekStart).add(0, 'days').format('MM-DD-YYYY')
                          this.setState({ selectedStaffId: e.staffId });
                          this.setState({ selectedStaffName: e.staffName });
                          this.setState({ selectedShiftDate: today });
                          this.showAddAppointmentModal();
                        }}
                      >
                        Client shift
                    </a>

                      <a
                        class="dropdown-item"
                        href="#"
                        onClick={() => {
                          this.setState({ isCordinator: true })
                          var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                          var today = moment(weekStart).add(0, 'days').format('MM-DD-YYYY')
                          this.setState({ selectedStaffId: e.staffId });
                          this.setState({ selectedStaffName: e.staffName });
                          this.setState({ selectedShiftDate: today });
                          this.showAddModal();
                        }}
                      >
                        Office shift
                    </a>
                    </div>
                  </div>

                  <button
                    onClick={() => {
                      var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                      var today = moment(weekStart).add(0, 'days').format('MM-DD-YYYY')
                      this.setState({ selectedStaffId: e.staffId });
                      this.setState({ selectedStaffName: e.staffName });
                      this.setState({ selectedShiftDate: today });
                      this.pasteShift({ selectedShiftDate: today, selectedStaffId: e.staffId });
                    }}

                    className="btn  btn-outline-warning btn-full btn-sm"
                  >
                    Paste
              </button>

                </button>

              </div>
            )

          } else {

            return (
              <div>
                <button className="btn  btn-outline-secondary btn-block">
                  <button className="btn  btn-outline-secondary btn-full btn-sm">Not available
              </button>

                </button>

              </div>
            )

          }


        }
      }
    }, {
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Tuesday
          <br></br>
          {
            moment(moment(this.state.weekStart).format('MM-DD-YYYY')).add(1, 'days').format('DD-MM-YYYY')
          }
        </button>
      ),
      minWidth: 180,
      sortable: false,
      Cell: row => {
        var e = row.original;
        var staffId = e.staffId;
        var array = [];
        var display = [];
        array = e.shifts;
        var index = 0;
        index = array.findIndex(x => x.day === 'Tuesday');
        if (index >= 0) {
          array.forEach(element => {
            if (element.day == 'Tuesday') {
              display.push(element);
            }
          });

          var shiftPrefix = display[0]
          var clientList = [];
          if (isNaN(shiftPrefix.shiftId)) {
            display.forEach(element => {
              if (!clientList.includes(element.clientName)) {
                clientList.push(element);
              }
            });
          }


          if (isNaN(shiftPrefix.shiftId)) {
            return <div>
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={this.popoverHoverFocus(clientList)}
                onMouseEnter={() => {

                  this.setState({ clientList: clientList });
                }

                }
              >
                <a className="btn text-white btn-info btn-block btn-sm mb-1 text-left"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{display.length} Client shifts</span></small>
                      <br></br>
                    </div>

                    <div className="col-md-6">
                      <div className="btn-group">
                        <button
                          className="btn btn-outline-warning btn-sm btn-block dropdown-toggle"
                          type="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() => {
                            var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                            var today = moment(weekStart).add(1, 'days').format('MM-DD-YYYY')
                            this.setState({ isCordinator: false })
                            this.setState({ selectedStaffId: e.staffId });
                            this.setState({ selectedShiftDate: today });
                            this.setState({ staffAppointments: display });
                            this.showAddAppointmentModal();
                          }}
                        >
                          <small>View all</small>
                        </button>
                      </div>
                    </div>
                  </div>
                </a>
              </OverlayTrigger>
            </div>

          } else {
            return display.map(function (item, i) {
              return <div>
                <a className="btn text-white btn-success btn-block btn-sm mb-1 text-left"
                  key={i}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{item.clientLocation}</span></small>
                      <br></br>
                      <small>
                        {item.clientName}<span className="badge badge-danger ml-2 ">{item.startTime}-{item.endTime}</span>
                      </small>
                    </div>


                    <div className="col-md-6">
                      <div className="btn-group">
                        <button className="btn btn-outline-warning btn-sm btn-block dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <small>Actions</small>
                        </button>
                        <div className="dropdown-menu"  >
                          <h6 class="dropdown-header">Actions</h6>
                          <a className="dropdown-item"

                            onClick={() => {
                              this.setState({ isCordinator: true })
                              this.getAppointments({ shiftId: item.shiftId, isCordinator: true });
                              this.showAddAppointmentModal();
                              this.setState({ selectedShiftId: item.shiftId });
                              this.setState({ selectedShiftDate: item.date });
                              this.setState({ selectedStaffId: e.staffId });
                              this.setState({ selectedStaffName: e.staffName });

                              this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                            }}
                            href="#">Client shift <span class="badge badge-primary">{this.getAppointments.length}</span></a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              this.setState({ selectedShiftId: item.shiftId });
                              this.setState({ selectedShiftDate: item.date });
                              this.setState({ selectedStaffId: e.staffId });
                              this.setState({ selectedStaffName: e.staffName });

                              this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                            }}
                          >Copy shift
                        </a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {

                              this.pasteShift({ selectedShiftDate: item.date, selectedStaffId: e.staffId });
                            }}

                          >
                            Paste shift
                        </a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              this.setState({ selectedStaffId: e.staffId });
                              this.deleteAlert(item.shiftId);
                            

                            }}
                          >
                            Delete shift
                        </a>
                        <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              this.setState({ selectedStaffId: e.staffId });
                              this.handleCanceOfficelShift(item.shiftId);
                            }}
                          >
                            Cancel shift
                        </a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              this.setState({ selectedShiftId: item.shiftId });
                              this.setState({ selectedShiftDate: item.date });
                              this.setState({ selectedStaffId: e.staffId });
                              this.setState({ selectedStaffName: e.staffName });

                              this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                              this.showModal()
                            }}
                          >
                            Edit shift
                        </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>

              </div>
            }, this)
          }


        } else {
          var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
          var today = moment(weekStart).add(1, 'days').format('MM-DD-YYYY')
          var arr = [];
          arr = row.original.availability;
          var index = 0;
          if (arr != undefined) {
            index = arr.findIndex(x => x.day === 'Tuesday');
          }
          if (arr[index].avaible) {
            return (
              <div>
                <button
                  className="btn  btn-outline-secondary btn-block"
                >

                  <div class="btn-group">
                    <button class="btn btn-outline-warning btn-full btn-sm mr-1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      +
                  </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <h6 class="dropdown-header">Actions</h6>
                      <a
                        class="dropdown-item"
                        href="#"
                        onClick={() => {
                          this.setState({ staffAppointments: [] })
                          this.setState({ isCordinator: false })
                          var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                          var today = moment(weekStart).add(1, 'days').format('MM-DD-YYYY')
                          this.setState({ selectedStaffId: e.staffId });
                          this.setState({ selectedStaffName: e.staffName });
                          this.setState({ selectedShiftDate: today });
                          this.showAddAppointmentModal();
                        }}
                      >
                        Client shift
                    </a>

                      <a
                        class="dropdown-item"
                        href="#"
                        onClick={() => {
                          this.setState({ isCordinator: true })
                          var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                          var today = moment(weekStart).add(1, 'days').format('MM-DD-YYYY')
                          this.setState({ selectedStaffId: e.staffId });
                          this.setState({ selectedStaffName: e.staffName });
                          this.setState({ selectedShiftDate: today });
                          this.showAddModal();
                        }}
                      >
                        Office shift
                    </a>
                    </div>
                  </div>

                  <button
                    onClick={() => {
                      var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                      var today = moment(weekStart).add(1, 'days').format('MM-DD-YYYY')
                      this.setState({ selectedStaffId: e.staffId });
                      this.setState({ selectedStaffName: e.staffName });
                      this.setState({ selectedShiftDate: today });
                      this.pasteShift({ selectedShiftDate: today, selectedStaffId: e.staffId });
                    }}

                    className="btn  btn-outline-warning btn-full btn-sm"
                  >
                    Paste
              </button>

                </button>

              </div>
            )

          } else {

            return (
              <div>
                <button className="btn  btn-outline-secondary btn-block">
                  <button className="btn  btn-outline-secondary btn-full btn-sm">Not available
              </button>

                </button>

              </div>
            )

          }


        }
      }
    }, {
      id: 'starting_date', // Required because our accessor is not a string
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Wednesday
          <br></br>
          {
            moment(moment(this.state.weekStart).format('MM-DD-YYYY')).add(2, 'days').format('DD-MM-YYYY')
          }
        </button>
      ),
      minWidth: 180,
      sortable: false,
      Cell: row => {
        var e = row.original;
        var staffId = e.staffId;
        var array = [];
        var display = [];
        array = e.shifts;
        var index = 0;
        index = array.findIndex(x => x.day === 'Wednesday');
        if (index >= 0) {
          array.forEach(element => {
            if (element.day == 'Wednesday') {
              display.push(element);
            }
          });

          var shiftPrefix = display[0]
          var clientList = [];
          if (isNaN(shiftPrefix.shiftId)) {
            display.forEach(element => {
              if (!clientList.includes(element.clientName)) {
                clientList.push(element);
              }
            });
          }


          if (isNaN(shiftPrefix.shiftId)) {
            return <div>
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={this.popoverHoverFocus(clientList)}
                onMouseEnter={() => {

                  this.setState({ clientList: clientList });
                }

                }
              >
                <a className="btn text-white btn-info btn-block btn-sm mb-1 text-left"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{display.length} Client shifts</span></small>
                      <br></br>
                    </div>

                    <div className="col-md-6">
                      <div className="btn-group">
                        <button
                          className="btn btn-outline-warning btn-sm btn-block dropdown-toggle"
                          type="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() => {
                            var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                            var today = moment(weekStart).add(2, 'days').format('MM-DD-YYYY')
                            this.setState({ isCordinator: false })
                            this.setState({ selectedStaffId: e.staffId });
                            this.setState({ selectedShiftDate: today });
                            this.setState({ staffAppointments: display });
                            this.showAddAppointmentModal();
                          }}
                        >
                          <small>View all</small>
                        </button>
                      </div>
                    </div>
                  </div>
                </a>
              </OverlayTrigger>
            </div>

          } else {
            return display.map(function (item, i) {
              return <div>
                <a className="btn text-white btn-success btn-block btn-sm mb-1 text-left"
                  key={i}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{item.clientLocation}</span></small>
                      <br></br>
                      <small>
                        {item.clientName}<span className="badge badge-danger ml-2 ">{item.startTime}-{item.endTime}</span>
                      </small>
                    </div>


                    <div className="col-md-6">
                      <div className="btn-group">
                        <button className="btn btn-outline-warning btn-sm btn-block dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <small>Actions</small>
                        </button>
                        <div className="dropdown-menu"  >
                          <h6 class="dropdown-header">Actions</h6>
                          <a className="dropdown-item"

                            onClick={() => {
                              this.setState({ isCordinator: true })
                              this.getAppointments({ shiftId: item.shiftId, isCordinator: true });
                              this.showAddAppointmentModal();
                              this.setState({ selectedShiftId: item.shiftId });
                              this.setState({ selectedShiftDate: item.date });
                              this.setState({ selectedStaffId: e.staffId });
                              this.setState({ selectedStaffName: e.staffName });

                              this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                            }}
                            href="#">Client shift <span class="badge badge-primary">{this.getAppointments.length}</span></a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              this.setState({ selectedShiftId: item.shiftId });
                              this.setState({ selectedShiftDate: item.date });
                              this.setState({ selectedStaffId: e.staffId });
                              this.setState({ selectedStaffName: e.staffName });

                              this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                            }}
                          >Copy shift
                        </a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {

                              this.pasteShift({ selectedShiftDate: item.date, selectedStaffId: e.staffId });
                            }}

                          >
                            Paste shift
                        </a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              this.setState({ selectedStaffId: e.staffId })
                              this.deleteAlert(item.shiftId)

                            }}
                          >
                            Delete shift
                        </a>
                        <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              this.setState({ selectedStaffId: e.staffId });
                              this.handleCanceOfficelShift(item.shiftId);
                            }}
                          >
                            Cancel shift
                        </a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              this.setState({ selectedShiftId: item.shiftId });
                              this.setState({ selectedShiftDate: item.date });
                              this.setState({ selectedStaffId: e.staffId });
                              this.setState({ selectedStaffName: e.staffName });

                              this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                              this.showModal()
                            }}
                          >
                            Edit shift
                        </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>

              </div>
            }, this)
          }


        } else {
          var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
          var today = moment(weekStart).add(2, 'days').format('MM-DD-YYYY')
          var arr = [];
          arr = row.original.availability;
          var index = 0;
          if (arr != undefined) {
            index = arr.findIndex(x => x.day === 'Wednesday');
          }
          if (arr[index].avaible) {
            return (
              <div>
                <button
                  className="btn  btn-outline-secondary btn-block"
                >

                  <div class="btn-group">
                    <button class="btn btn-outline-warning btn-full btn-sm mr-1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      +
                  </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <h6 class="dropdown-header">Actions</h6>
                      <a
                        class="dropdown-item"
                        href="#"
                        onClick={() => {
                          this.setState({ staffAppointments: [] })
                          this.setState({ isCordinator: false })
                          var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                          var today = moment(weekStart).add(2, 'days').format('MM-DD-YYYY')
                          this.setState({ selectedStaffId: e.staffId });
                          this.setState({ selectedStaffName: e.staffName });
                          this.setState({ selectedShiftDate: today });
                          this.showAddAppointmentModal();
                        }}
                      >
                        Client shift
                    </a>

                      <a
                        class="dropdown-item"
                        href="#"
                        onClick={() => {
                          this.setState({ isCordinator: true })
                          var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                          var today = moment(weekStart).add(2, 'days').format('MM-DD-YYYY')
                          this.setState({ selectedStaffId: e.staffId });
                          this.setState({ selectedStaffName: e.staffName });
                          this.setState({ selectedShiftDate: today });
                          this.showAddModal();
                        }}
                      >
                        Office shift
                    </a>
                    </div>
                  </div>

                  <button
                    onClick={() => {
                      var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                      var today = moment(weekStart).add(2, 'days').format('MM-DD-YYYY')
                      this.setState({ selectedStaffId: e.staffId });
                      this.setState({ selectedStaffName: e.staffName });
                      this.setState({ selectedShiftDate: today });
                      this.pasteShift({ selectedShiftDate: today, selectedStaffId: e.staffId });
                    }}

                    className="btn  btn-outline-warning btn-full btn-sm"
                  >
                    Paste
              </button>

                </button>

              </div>
            )

          } else {

            return (
              <div>
                <button className="btn  btn-outline-secondary btn-block">
                  <button className="btn  btn-outline-secondary btn-full btn-sm">Not available
              </button>

                </button>

              </div>
            )

          }


        }
      }
    }, {
      id: 'ending_date', // Required because our accessor is not a string
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Thursday
          <br></br>
          {
            moment(moment(this.state.weekStart).format('MM-DD-YYYY')).add(3, 'days').format('DD-MM-YYYY')
          }
        </button>
      ),
      minWidth: 180,
      sortable: false,
      Cell: row => {
        var e = row.original;
        var staffId = e.staffId;
        var array = [];
        var display = [];
        array = e.shifts;
        var index = 0;
        index = array.findIndex(x => x.day === 'Thursday');
        if (index >= 0) {
          array.forEach(element => {
            if (element.day == 'Thursday') {
              display.push(element);
            }
          });

          var shiftPrefix = display[0]
          var clientList = [];
          if (isNaN(shiftPrefix.shiftId)) {
            display.forEach(element => {
              if (!clientList.includes(element.clientName)) {
                clientList.push(element);
              }
            });
          }


          if (isNaN(shiftPrefix.shiftId)) {
            return <div>
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={this.popoverHoverFocus(clientList)}
                onMouseEnter={() => {

                  this.setState({ clientList: clientList });
                }

                }
              >
                <a className="btn text-white btn-info btn-block btn-sm mb-1 text-left"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{display.length} Client shifts</span></small>
                      <br></br>
                    </div>

                    <div className="col-md-6">
                      <div className="btn-group">
                        <button
                          className="btn btn-outline-warning btn-sm btn-block dropdown-toggle"
                          type="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() => {
                            var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                            var today = moment(weekStart).add(3, 'days').format('MM-DD-YYYY');
                            this.setState({ isCordinator: false })
                            this.setState({ selectedStaffId: e.staffId });
                            this.setState({ selectedShiftDate: today });
                            this.setState({ staffAppointments: display });
                            this.showAddAppointmentModal();
                          }}
                        >
                          <small>View all</small>
                        </button>
                      </div>
                    </div>
                  </div>
                </a>
              </OverlayTrigger>
            </div>

          } else {
            return display.map(function (item, i) {
              return <div>
                <a className="btn text-white btn-success btn-block btn-sm mb-1 text-left"
                  key={i}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{item.clientLocation}</span></small>
                      <br></br>
                      <small>
                        {item.clientName}<span className="badge badge-danger ml-2 ">{item.startTime}-{item.endTime}</span>
                      </small>
                    </div>


                    <div className="col-md-6">
                      <div className="btn-group">
                        <button className="btn btn-outline-warning btn-sm btn-block dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <small>Actions</small>
                        </button>
                        <div className="dropdown-menu"  >
                          <h6 class="dropdown-header">Actions</h6>
                          <a className="dropdown-item"

                            onClick={() => {
                              this.setState({ isCordinator: true })
                              this.getAppointments({ shiftId: item.shiftId, isCordinator: true });
                              this.showAddAppointmentModal();
                              this.setState({ selectedShiftId: item.shiftId });
                              this.setState({ selectedShiftDate: item.date });
                              this.setState({ selectedStaffId: e.staffId });
                              this.setState({ selectedStaffName: e.staffName });

                              this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                            }}
                            href="#">Client shift <span class="badge badge-primary">{this.getAppointments.length}</span></a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              this.setState({ selectedShiftId: item.shiftId });
                              this.setState({ selectedShiftDate: item.date });
                              this.setState({ selectedStaffId: e.staffId });
                              this.setState({ selectedStaffName: e.staffName });

                              this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                            }}
                          >Copy shift
                        </a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {

                              this.pasteShift({ selectedShiftDate: item.date, selectedStaffId: e.staffId });
                            }}

                          >
                            Paste shift
                        </a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              this.setState({ selectedStaffId: e.staffId })
                              this.deleteAlert(item.shiftId)

                            }}
                          >
                            Delete shift
                        </a>
                        <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              this.setState({ selectedStaffId: e.staffId });
                              this.handleCanceOfficelShift(item.shiftId);
                            }}
                          >
                            Cancel shift
                        </a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              this.setState({ selectedShiftId: item.shiftId });
                              this.setState({ selectedShiftDate: item.date });
                              this.setState({ selectedStaffId: e.staffId });
                              this.setState({ selectedStaffName: e.staffName });

                              this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                              this.showModal()
                            }}
                          >
                            Edit shift
                        </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>

              </div>
            }, this)
          }


        } else {
          var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
          var today = moment(weekStart).add(3, 'days').format('MM-DD-YYYY')
          var arr = [];
          arr = row.original.availability;
          var index = 0;
          if (arr != undefined) {
            index = arr.findIndex(x => x.day === 'Thursday');
          }
          if (arr[index].avaible) {
            return (
              <div>
                <button
                  className="btn  btn-outline-secondary btn-block"
                >

                  <div class="btn-group">
                    <button class="btn btn-outline-warning btn-full btn-sm mr-1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      +
                  </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <h6 class="dropdown-header">Actions</h6>
                      <a
                        class="dropdown-item"
                        href="#"
                        onClick={() => {
                          this.setState({ staffAppointments: [] })
                          this.setState({ isCordinator: false })
                          var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                          var today = moment(weekStart).add(3, 'days').format('MM-DD-YYYY')
                          this.setState({ selectedStaffId: e.staffId });
                          this.setState({ selectedStaffName: e.staffName });
                          this.setState({ selectedShiftDate: today });
                          this.showAddAppointmentModal();
                        }}
                      >
                        Client shift
                    </a>

                      <a
                        class="dropdown-item"
                        href="#"
                        onClick={() => {
                          this.setState({ isCordinator: true })
                          var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                          var today = moment(weekStart).add(3, 'days').format('MM-DD-YYYY')
                          this.setState({ selectedStaffId: e.staffId });
                          this.setState({ selectedStaffName: e.staffName });
                          this.setState({ selectedShiftDate: today });
                          this.showAddModal();
                        }}
                      >
                        Office shift
                    </a>
                    </div>
                  </div>

                  <button
                    onClick={() => {
                      var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                      var today = moment(weekStart).add(3, 'days').format('MM-DD-YYYY')
                      this.setState({ selectedStaffId: e.staffId });
                      this.setState({ selectedStaffName: e.staffName });
                      this.setState({ selectedShiftDate: today });
                      this.pasteShift({ selectedShiftDate: today, selectedStaffId: e.staffId });
                    }}

                    className="btn  btn-outline-warning btn-full btn-sm"
                  >
                    Paste
              </button>

                </button>

              </div>
            )

          } else {

            return (
              <div>
                <button className="btn  btn-outline-secondary btn-block">
                  <button className="btn  btn-outline-secondary btn-full btn-sm">Not available
              </button>

                </button>

              </div>
            )

          }


        }
      }
    }, {
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Friday
          <br></br>
          {
            moment(moment(this.state.weekStart).format('MM-DD-YYYY')).add(4, 'days').format('DD-MM-YYYY')
          }
        </button>
      ),
      minWidth: 180,
      sortable: false,
      Cell: row => {
        var e = row.original;
        var staffId = e.staffId;
        var array = [];
        var display = [];
        array = e.shifts;
        var index = 0;
        index = array.findIndex(x => x.day === 'Friday');
        if (index >= 0) {
          array.forEach(element => {
            if (element.day == 'Friday') {
              display.push(element);
            }
          });

          var shiftPrefix = display[0]
          var clientList = [];
          if (isNaN(shiftPrefix.shiftId)) {
            display.forEach(element => {
              if (!clientList.includes(element.clientName)) {
                clientList.push(element);
              }
            });
          }


          if (isNaN(shiftPrefix.shiftId)) {
            return <div>
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={this.popoverHoverFocus(clientList)}
                onMouseEnter={() => {

                  this.setState({ clientList: clientList });
                }

                }
              >
                <a className="btn text-white btn-info btn-block btn-sm mb-1 text-left"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{display.length} Client shifts</span></small>
                      <br></br>
                    </div>

                    <div className="col-md-6">
                      <div className="btn-group">
                        <button
                          className="btn btn-outline-warning btn-sm btn-block dropdown-toggle"
                          type="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() => {
                            var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                            var today = moment(weekStart).add(4, 'days').format('MM-DD-YYYY');
                            this.setState({ isCordinator: false })
                            this.setState({ selectedStaffId: e.staffId });
                            this.setState({ selectedShiftDate: today });
                            this.setState({ staffAppointments: display });
                            this.showAddAppointmentModal();
                          }}
                        >
                          <small>View all</small>
                        </button>
                      </div>
                    </div>
                  </div>
                </a>
              </OverlayTrigger>
            </div>

          } else {
            return display.map(function (item, i) {
              return <div>
                <a className="btn text-white btn-success btn-block btn-sm mb-1 text-left"
                  key={i}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{item.clientLocation}</span></small>
                      <br></br>
                      <small>
                        {item.clientName}<span className="badge badge-danger ml-2 ">{item.startTime}-{item.endTime}</span>
                      </small>
                    </div>


                    <div className="col-md-6">
                      <div className="btn-group">
                        <button className="btn btn-outline-warning btn-sm btn-block dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <small>Actions</small>
                        </button>
                        <div className="dropdown-menu"  >
                          <h6 class="dropdown-header">Actions</h6>
                          <a className="dropdown-item"

                            onClick={() => {
                              this.setState({ isCordinator: true })
                              this.getAppointments({ shiftId: item.shiftId, isCordinator: true });
                              this.showAddAppointmentModal();
                              this.setState({ selectedShiftId: item.shiftId });
                              this.setState({ selectedShiftDate: item.date });
                              this.setState({ selectedStaffId: e.staffId });
                              this.setState({ selectedStaffName: e.staffName });

                              this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                            }}
                            href="#">Client shift <span class="badge badge-primary">{this.getAppointments.length}</span></a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              this.setState({ selectedShiftId: item.shiftId });
                              this.setState({ selectedShiftDate: item.date });
                              this.setState({ selectedStaffId: e.staffId });
                              this.setState({ selectedStaffName: e.staffName });

                              this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                            }}
                          >Copy shift
                        </a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {

                              this.pasteShift({ selectedShiftDate: item.date, selectedStaffId: e.staffId });
                            }}

                          >
                            Paste shift
                        </a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              this.setState({ selectedStaffId: e.staffId })
                              this.deleteAlert(item.shiftId)

                            }}
                          >
                            Delete shift
                        </a>
                        <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              this.setState({ selectedStaffId: e.staffId });
                              this.handleCanceOfficelShift(item.shiftId);
                            }}
                          >
                            Cancel shift
                        </a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              this.setState({ selectedShiftId: item.shiftId });
                              this.setState({ selectedShiftDate: item.date });
                              this.setState({ selectedStaffId: e.staffId });
                              this.setState({ selectedStaffName: e.staffName });

                              this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                              this.showModal()
                            }}
                          >
                            Edit shift
                        </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>

              </div>
            }, this)
          }


        } else {
          var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
          var today = moment(weekStart).add(4, 'days').format('MM-DD-YYYY');
          var arr = [];
          arr = row.original.availability;
          var index = 0;
          if (arr != undefined) {
            index = arr.findIndex(x => x.day === 'Friday');
          }
          if (arr[index].avaible) {
            return (
              <div>
                <button
                  className="btn  btn-outline-secondary btn-block"
                >

                  <div class="btn-group">
                    <button class="btn btn-outline-warning btn-full btn-sm mr-1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      +
                  </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <h6 class="dropdown-header">Actions</h6>
                      <a
                        class="dropdown-item"
                        href="#"
                        onClick={() => {
                          this.setState({ staffAppointments: [] })
                          this.setState({ isCordinator: false })
                          var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                          var today = moment(weekStart).add(4, 'days').format('MM-DD-YYYY');
                          this.setState({ selectedStaffId: e.staffId });
                          this.setState({ selectedStaffName: e.staffName });
                          this.setState({ selectedShiftDate: today });
                          this.showAddAppointmentModal();
                        }}
                      >
                        Client shift
                    </a>

                      <a
                        class="dropdown-item"
                        href="#"
                        onClick={() => {
                          this.setState({ isCordinator: true })
                          var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                          var today = moment(weekStart).add(4, 'days').format('MM-DD-YYYY');
                          this.setState({ selectedStaffId: e.staffId });
                          this.setState({ selectedStaffName: e.staffName });
                          this.setState({ selectedShiftDate: today });
                          this.showAddModal();
                        }}
                      >
                        Office shift
                    </a>
                    </div>
                  </div>

                  <button
                    onClick={() => {
                      var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                      var today = moment(weekStart).add(4, 'days').format('MM-DD-YYYY');
                      this.setState({ selectedStaffId: e.staffId });
                      this.setState({ selectedStaffName: e.staffName });
                      this.setState({ selectedShiftDate: today });
                      this.pasteShift({ selectedShiftDate: today, selectedStaffId: e.staffId });
                    }}

                    className="btn  btn-outline-warning btn-full btn-sm"
                  >
                    Paste
              </button>

                </button>

              </div>
            )

          } else {

            return (
              <div>
                <button className="btn  btn-outline-secondary btn-block">
                  <button className="btn  btn-outline-secondary btn-full btn-sm">Not available
              </button>

                </button>

              </div>
            )

          }


        }
      }
    }, {
      id: 'ending_time', // Required because our accessor is not a string
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Saturday
          <br></br>
          {
            moment(moment(this.state.weekStart).format('MM-DD-YYYY')).add(5, 'days').format('DD-MM-YYYY')
          }
        </button>
      ),
      minWidth: 180,
      sortable: false,
      Cell: row => {
        var e = row.original;
        var staffId = e.staffId;
        var array = [];
        var display = [];
        array = e.shifts;
        var index = 0;
        index = array.findIndex(x => x.day === 'Saturday');
        if (index >= 0) {
          array.forEach(element => {
            if (element.day == 'Saturday') {
              display.push(element);
            }
          });

          var shiftPrefix = display[0]
          var clientList = [];
          if (isNaN(shiftPrefix.shiftId)) {
            display.forEach(element => {
              if (!clientList.includes(element.clientName)) {
                clientList.push(element);
              }
            });
          }


          if (isNaN(shiftPrefix.shiftId)) {
            return <div>
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={this.popoverHoverFocus(clientList)}
                onMouseEnter={() => {

                  this.setState({ clientList: clientList });
                }

                }
              >
                <a className="btn text-white btn-info btn-block btn-sm mb-1 text-left"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{display.length} Client shifts</span></small>
                      <br></br>
                    </div>

                    <div className="col-md-6">
                      <div className="btn-group">
                        <button
                          className="btn btn-outline-warning btn-sm btn-block dropdown-toggle"
                          type="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() => {
                            var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                            var today = moment(weekStart).add(5, 'days').format('MM-DD-YYYY')
                            this.setState({ isCordinator: false })
                            this.setState({ selectedStaffId: e.staffId });
                            this.setState({ selectedShiftDate: today });
                            this.setState({ staffAppointments: display });
                            this.showAddAppointmentModal();
                          }}
                        >
                          <small>View all</small>
                        </button>
                      </div>
                    </div>
                  </div>
                </a>
              </OverlayTrigger>
            </div>

          } else {
            return display.map(function (item, i) {
              return <div>
                <a className="btn text-white btn-success btn-block btn-sm mb-1 text-left"
                  key={i}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{item.clientLocation}</span></small>
                      <br></br>
                      <small>
                        {item.clientName}<span className="badge badge-danger ml-2 ">{item.startTime}-{item.endTime}</span>
                      </small>
                    </div>


                    <div className="col-md-6">
                      <div className="btn-group">
                        <button className="btn btn-outline-warning btn-sm btn-block dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <small>Actions</small>
                        </button>
                        <div className="dropdown-menu"  >
                          <h6 class="dropdown-header">Actions</h6>
                          <a className="dropdown-item"

                            onClick={() => {
                              this.setState({ isCordinator: true })
                              this.getAppointments({ shiftId: item.shiftId, isCordinator: true });
                              this.showAddAppointmentModal();
                              this.setState({ selectedShiftId: item.shiftId });
                              this.setState({ selectedShiftDate: item.date });
                              this.setState({ selectedStaffId: e.staffId });
                              this.setState({ selectedStaffName: e.staffName });

                              this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                            }}
                            href="#">Client shift <span class="badge badge-primary">{this.getAppointments.length}</span></a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              this.setState({ selectedShiftId: item.shiftId });
                              this.setState({ selectedShiftDate: item.date });
                              this.setState({ selectedStaffId: e.staffId });
                              this.setState({ selectedStaffName: e.staffName });

                              this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                            }}
                          >Copy shift
                        </a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {

                              this.pasteShift({ selectedShiftDate: item.date, selectedStaffId: e.staffId });
                            }}

                          >
                            Paste shift
                        </a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              this.setState({ selectedStaffId: e.staffId })
                              this.deleteAlert(item.shiftId)

                            }}
                          >
                            Delete shift
                        </a>
                        <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              this.setState({ selectedStaffId: e.staffId });
                              this.handleCanceOfficelShift(item.shiftId);
                            }}
                          >
                            Cancel shift
                        </a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              this.setState({ selectedShiftId: item.shiftId });
                              this.setState({ selectedShiftDate: item.date });
                              this.setState({ selectedStaffId: e.staffId });
                              this.setState({ selectedStaffName: e.staffName });

                              this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                              this.showModal()
                            }}
                          >
                            Edit shift
                        </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>

              </div>
            }, this)
          }


        } else {
          var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
          var today = moment(weekStart).add(5, 'days').format('MM-DD-YYYY')
          var arr = [];
          arr = row.original.availability;
          var index = 0;
          if (arr != undefined) {
            index = arr.findIndex(x => x.day === 'Saturday');
          }
          if (arr[index].avaible) {
            return (
              <div>
                <button
                  className="btn  btn-outline-secondary btn-block"
                >

                  <div class="btn-group">
                    <button class="btn btn-outline-warning btn-full btn-sm mr-1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      +
                  </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <h6 class="dropdown-header">Actions</h6>
                      <a
                        class="dropdown-item"
                        href="#"
                        onClick={() => {
                          this.setState({ staffAppointments: [] })
                          this.setState({ isCordinator: false })
                          var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                          var today = moment(weekStart).add(5, 'days').format('MM-DD-YYYY')
                          this.setState({ selectedStaffId: e.staffId });
                          this.setState({ selectedStaffName: e.staffName });
                          this.setState({ selectedShiftDate: today });
                          this.showAddAppointmentModal();
                        }}
                      >
                        Client shift
                    </a>

                      <a
                        class="dropdown-item"
                        href="#"
                        onClick={() => {
                          this.setState({ isCordinator: true })
                          var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                          var today = moment(weekStart).add(5, 'days').format('MM-DD-YYYY')
                          this.setState({ selectedStaffId: e.staffId });
                          this.setState({ selectedStaffName: e.staffName });
                          this.setState({ selectedShiftDate: today });
                          this.showAddModal();
                        }}
                      >
                        Office shift
                    </a>
                    </div>
                  </div>

                  <button
                    onClick={() => {
                      var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                      var today = moment(weekStart).add(5, 'days').format('MM-DD-YYYY')
                      this.setState({ selectedStaffId: e.staffId });
                      this.setState({ selectedStaffName: e.staffName });
                      this.setState({ selectedShiftDate: today });
                      this.pasteShift({ selectedShiftDate: today, selectedStaffId: e.staffId });
                    }}

                    className="btn  btn-outline-warning btn-full btn-sm"
                  >
                    Paste
              </button>

                </button>

              </div>
            )

          } else {

            return (
              <div>
                <button className="btn  btn-outline-secondary btn-block">
                  <button className="btn  btn-outline-secondary btn-full btn-sm">Not available
              </button>

                </button>

              </div>
            )

          }


        }
      }
    }, {
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Sunday
          <br></br>
          {
            moment(moment(this.state.weekStart).format('MM-DD-YYYY')).add(6, 'days').format('DD-MM-YYYY')
          }
        </button>
      ),
      minWidth: 180,
      sortable: false,
      Cell: row => {
        var e = row.original;
        var staffId = e.staffId;
        var array = [];
        var display = [];
        array = e.shifts;
        var index = 0;
        index = array.findIndex(x => x.day === 'Sunday');
        if (index >= 0) {
          array.forEach(element => {
            if (element.day == 'Sunday') {
              display.push(element);
            }
          });

          var shiftPrefix = display[0]
          var clientList = [];
          if (isNaN(shiftPrefix.shiftId)) {
            display.forEach(element => {
              if (!clientList.includes(element.clientName)) {
                clientList.push(element);
              }
            });
          }


          if (isNaN(shiftPrefix.shiftId)) {
            return <div>
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={this.popoverHoverFocus(clientList)}
                onMouseEnter={() => {

                  this.setState({ clientList: clientList });
                }

                }
              >
                <a className="btn text-white btn-info btn-block btn-sm mb-1 text-left"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{display.length} Client shifts</span></small>
                      <br></br>
                    </div>

                    <div className="col-md-6">
                      <div className="btn-group">
                        <button
                          className="btn btn-outline-warning btn-sm btn-block dropdown-toggle"
                          type="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() => {
                            var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                            var today = moment(weekStart).add(6, 'days').format('MM-DD-YYYY')
                            this.setState({ isCordinator: false })
                            this.setState({ selectedStaffId: e.staffId });
                            this.setState({ selectedShiftDate: today });
                            this.setState({ staffAppointments: display });
                            this.showAddAppointmentModal();
                          }}
                        >
                          <small>View all</small>
                        </button>
                      </div>
                    </div>
                  </div>
                </a>
              </OverlayTrigger>
            </div>

          } else {
            return display.map(function (item, i) {
              return <div>
                <a className="btn text-white btn-success btn-block btn-sm mb-1 text-left"
                  key={i}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <small><span style={{ display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "13ch" }}>{item.clientLocation}</span></small>
                      <br></br>
                      <small>
                        {item.clientName}<span className="badge badge-danger ml-2 ">{item.startTime}-{item.endTime}</span>
                      </small>
                    </div>


                    <div className="col-md-6">
                      <div className="btn-group">
                        <button className="btn btn-outline-warning btn-sm btn-block dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <small>Actions</small>
                        </button>
                        <div className="dropdown-menu"  >
                          <h6 class="dropdown-header">Actions</h6>
                          <a className="dropdown-item"

                            onClick={() => {
                              this.setState({ isCordinator: true })
                              this.getAppointments({ shiftId: item.shiftId, isCordinator: true });
                              this.showAddAppointmentModal();
                              this.setState({ selectedShiftId: item.shiftId });
                              this.setState({ selectedShiftDate: item.date });
                              this.setState({ selectedStaffId: e.staffId });
                              this.setState({ selectedStaffName: e.staffName });

                              this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                            }}
                            href="#">Client shift <span class="badge badge-primary">{this.getAppointments.length}</span></a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              this.setState({ selectedShiftId: item.shiftId });
                              this.setState({ selectedShiftDate: item.date });
                              this.setState({ selectedStaffId: e.staffId });
                              this.setState({ selectedStaffName: e.staffName });

                              this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                            }}
                          >Copy shift
                        </a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {

                              this.pasteShift({ selectedShiftDate: item.date, selectedStaffId: e.staffId });
                            }}

                          >
                            Paste shift
                        </a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              this.setState({ selectedStaffId: e.staffId })
                              this.deleteAlert(item.shiftId)

                            }}
                          >
                            Delete shift
                        </a>
                        <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              this.setState({ selectedStaffId: e.staffId });
                              this.handleCanceOfficelShift(item.shiftId);
                            }}
                          >
                            Cancel shift
                        </a>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              this.setState({ selectedShiftId: item.shiftId });
                              this.setState({ selectedShiftDate: item.date });
                              this.setState({ selectedStaffId: e.staffId });
                              this.setState({ selectedStaffName: e.staffName });

                              this.setState({ selectedShiftDetails: { shiftId: item.shiftId, hourlChage: item.hourlChage, startTime: item.startTime, endTime: item.endTime, clientId: item.clientId, clientLocation: item.clientLocation, supportAreaId: item.supportAreaId, supportServiceId: item.supportServiceId, staffId: item.staffId, notes: item.notes, breaking: item.breaking, isApproved: item.isApproved, financialPackageId: item.financialPackageId, isCompleted: item.isCompleted, allowance: item.allowance } });
                              this.showModal()
                            }}
                          >
                            Edit shift
                        </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>

              </div>
            }, this)
          }


        } else {
          var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
          var today = moment(weekStart).add(6, 'days').format('MM-DD-YYYY')
          var arr = [];
          arr = row.original.availability;
          var index = 0;
          if (arr != undefined) {
            index = arr.findIndex(x => x.day === 'Sunday');
          }

          if (arr[index].avaible) {
            return (
              <div
              >
                <button
                  className="btn  btn-outline-secondary btn-block"
                >

                  <div class="btn-group">
                    <button class="btn btn-outline-warning btn-full btn-sm mr-1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      +
                  </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <h6 class="dropdown-header">Actions</h6>
                      <a
                        class="dropdown-item"
                        href="#"
                        onClick={() => {
                          this.setState({ staffAppointments: [] })
                          this.setState({ isCordinator: false })
                          var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                          var today = moment(weekStart).add(6, 'days').format('MM-DD-YYYY')
                          this.setState({ selectedStaffId: e.staffId });
                          this.setState({ selectedStaffName: e.staffName });
                          this.setState({ selectedShiftDate: today });
                          this.showAddAppointmentModal();
                        }}
                      >
                        Client shift
                    </a>

                      <a
                        class="dropdown-item"
                        href="#"
                        onClick={() => {
                          this.setState({ isCordinator: true })
                          var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                          var today = moment(weekStart).add(6, 'days').format('MM-DD-YYYY')
                          this.setState({ selectedStaffId: e.staffId });
                          this.setState({ selectedStaffName: e.staffName });
                          this.setState({ selectedShiftDate: today });
                          this.showAddModal();
                        }}
                      >
                        Office shift
                    </a>
                    </div>
                  </div>

                  <button
                    onClick={() => {
                      var weekStart = moment(this.state.weekStart).format('MM-DD-YYYY');
                      var today = moment(weekStart).add(6, 'days').format('MM-DD-YYYY')
                      this.setState({ selectedStaffId: e.staffId });
                      this.setState({ selectedStaffName: e.staffName });
                      this.setState({ selectedShiftDate: today });
                      this.pasteShift({ selectedShiftDate: today, selectedStaffId: e.staffId });
                    }}

                    className="btn  btn-outline-warning btn-full btn-sm"
                  >
                    Paste
              </button>

                </button>

              </div>
            )

          } else {

            return (
              <div>
                <button className="btn  btn-outline-secondary btn-block">
                  <button className="btn  btn-outline-secondary btn-full btn-sm">Not available
              </button>

                </button>

              </div>
            )

          }


        }
      }
    }]
    const tableAppointments = [
      {
        Header : () =>(
          <>
            <button 
              className="btn text-white btn-warning" 
              type="button"
              onClick = {()=>{
                var arr = [];
                arr = this.state.selectedListClientAppointmentIds;
                if(arr.length==0){
                  this.state.staffAppointments.forEach(element => {
                    arr.push(element.shiftId);
                  });
                }else{
                  arr = [];
                }
                this.setState({ selectedListClientAppointmentIds : arr})
              }}
              >&#10003;</button>
            </>
        ),
        Cell: row => {

          return (
            <div
            >
              <input
                onClick={(event) => {
                  console.log(row.original)
                  var arr = [];
                  arr = this.state.selectedListClientAppointmentIds;
                  const index = arr.findIndex(x => x == row.original.shiftId);
                  if (index >= 0) {
                    arr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));
                  } else {
                    arr.push(row.original.shiftId);
                  }
                  this.setState({ selectedListClientAppointmentIds: arr });
                }
                }
                type="checkbox" className="ml-2" 
                checked={ this.state.selectedListClientAppointmentIds.includes(row.original.shiftId) ? true : false}
                >
              </input>
            </div >
          )
        },
        maxWidth : 50,
        sortable : false    
      },
      {
        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Client name
          </button>
        ),
        // accessor: 'clientName',
        maxWidth: 160,
        sortable: false,
        Cell : row =>{
          return (
            row.original.isConcelled?
              <del>{row.original.clientName}</del>
              :
              <div>{row.original.clientName}</div>
          )
        }

      }, {
        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Start
          </button>
        ),
        // accessor: 'startTime',
        maxWidth: 70,
        Cell : row =>{
          return (
            row.original.isConcelled?
              <del>{row.original.startTime}</del>
              :
              <div>{row.original.startTime}</div>
          )
        }

      }, {
        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            End
          </button>
        ),
        // accessor: 'endTime',
        maxWidth: 70,
        Cell : row =>{
          return (
            row.original.isConcelled?
              <del>{row.original.endTime}</del>
              :
              <div>{row.original.endTime}</div>
          )
        }
      }, {
        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Cost/Hr
          </button>
        ),
        // accessor: 'costPerHour',
        maxWidth: 90,
        Cell : row =>{
          return (
            row.original.isConcelled?
              <del>{row.original.costPerHour}</del>
              :
              <div>{row.original.costPerHour}</div>
          )
        }
      }, {

        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Location
          </button>
        ),
        accessor: 'clientLocaton',
        maxWidth: 170,
        Cell : row =>{
          return (
            row.original.isConcelled?
              <del>{row.original.clientLocaton}</del>
              :
              <div>{row.original.clientLocaton}</div>
          )
        }

      }, {

        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Notes
          </button>
        ),
        // accessor: 'notes',
        maxWidth: 250,
        Cell : row =>{
          return (
            row.original.isConcelled?
              <del>{row.original.notes}</del>
              :
              <div>{row.original.notes}</div>
          )
        }
      }, 
      {
        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Actions
          </button>
        ),
        sortable: false,
        Cell: row => {
          return (
            <div>
              {
                
                row.original.isApproved ? <button
                  type="button"
                  class="btn btn-success btn-sm mr-1"
                >
                  <small>Approved</small>
                </button>
                  :
                 ""

              }
              {
                row.original.isComplete ? <button
                  type="button"
                  class="btn btn-info btn-sm mr-1"
                >
                  <small>Completed</small>
                </button>
                  :
                  ""

              }
              {
                row.original.isConfirmed ?
                  <button
                    type="button"
                    class="btn btn-warning btn-sm mr-1"

                  >
                    <small>Confirmed</small>
                  </button>
                  :
                  ""
              }
            </div>
          )
        }
      }
    ]

    const tableEditAppointments = [
      {

        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Client name
          </button>
        ),
        accessor: 'clientName',
        Cell : row =>{
          return (
                <select
                  onChange={(event)=>{
                    const id = this.state.editedAppointments.appointmentId;
                    const rowId = row.original.appointmentId != undefined ? row.original.appointmentId : row.original.shiftId;
                    if (!rowId == id) {
                      this.state.editedAppointments.startTime = undefined
                      this.state.editedAppointments.endTime = undefined
                      this.state.editedAppointments.location = undefined
                      this.state.editedAppointments.notes = undefined
                      this.state.editedAppointments.serviceId = 0
                      this.state.editedAppointments.clientId = event.target.value
                      
                    } else {
                      this.state.editedAppointments.clientId = event.target.value;
                    }
                    this.state.editedAppointments.appointmentId = row.original.appointmentId != undefined ? row.original.appointmentId : row.original.shiftId;
                    
                  }}
                  onFocus={(event)=>{
                    const id = this.state.editedAppointments.appointmentId;
                    const rowId = row.original.appointmentId != undefined ? row.original.appointmentId : row.original.shiftId;
                    if (!rowId == id) {
                      this.state.editedAppointments.startTime = undefined
                      this.state.editedAppointments.endTime = undefined
                      this.state.editedAppointments.location = undefined
                      this.state.editedAppointments.notes = undefined
                      this.state.editedAppointments.serviceId = 0
                      this.state.editedAppointments.clientId = event.target.value
                      
                    } else {
                      this.state.editedAppointments.clientId = event.target.value;
                    }
                    this.state.editedAppointments.appointmentId = row.original.appointmentId != undefined ? row.original.appointmentId : row.original.shiftId;
                    
                  }}
                  className="form-control"
                  style={{ "width": "98%", "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis" }}
                >
                  {
                    this.state.clients.map((element)=>
                      <option value={element.id} selected={element.id==row.original.clientId?`selected`:``}>{element.clientname}</option>
                    )
                  }
            </select>
            )
        },
        maxWidth: 160

      }, {
        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            staff Name
          </button>
        ),
        sortable: false,
        Cell: row => {
          return (
            <div>
              <select
                className="form-control"
                onChange={(event) => {
                  const rowId = row.original.appointmentId != undefined ? row.original.appointmentId : row.original.shiftId;
                  const id = this.state.editedAppointments.appointmentId;
                  if (!(rowId == id)) {
                    this.state.editedAppointments.staffId = event.target.value
                    this.state.editedAppointments.startTime = undefined
                    this.state.editedAppointments.endTime = undefined
                    this.state.editedAppointments.location = undefined
                    this.state.editedAppointments.notes = undefined
                    this.state.editedAppointments.serviceId = 0
                    this.state.editedAppointments.clientId = 0
                  } else {
                    this.state.editedAppointments.staffId = event.target.value
                  }
                  this.state.editedAppointments.appointmentId = row.original.appointmentId != undefined ? row.original.appointmentId : row.original.shiftId
                }}
              >
                {
                  this.state.staffList.map(element => {
                    return (
                      <option
                        value={element.id}
                        selected={element.id == row.original.staffId ? "selected" : ''}
                      >
                        {element.lastname} {element.firstname}
                      </option>
                    )
                  })
                }
              </select>
            </div>
          )
        },
        accessor: 'clientName',
        maxWidth: 160

      }, {
        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Start
          </button>
        ),
        Cell: row => {
          return (
            <div>
              {
                <input
                  required
                  type="time"
                  defaultValue={((row.original.appointmentId == this.state.editedAppointments.appointmentId) ||
                    (row.original.shiftId == this.state.editedAppointments.appointmentId)) && this.state.editedAppointments.startTime ? this.state.editedAppointments.startTime : row.original.startTime}
                  className="form-control"
                  onChange={(event) => {
                    const rowId = row.original.appointmentId != undefined ? row.original.appointmentId : row.original.shiftId;
                    const id = this.state.editedAppointments.appointmentId;
                    if (!(rowId == id)) {
                      this.state.editedAppointments.startTime = event.target.value
                      this.state.editedAppointments.endTime = undefined
                      this.state.editedAppointments.location = undefined
                      this.state.editedAppointments.notes = undefined
                      this.state.editedAppointments.serviceId = 0
                      this.state.editedAppointments.clientId=0
                    } else {
                      this.state.editedAppointments.startTime = event.target.value
                    }
                    this.state.editedAppointments.appointmentId = row.original.appointmentId != undefined ? row.original.appointmentId : row.original.shiftId
                  }}
                  name="startTime" />
              }
            </div>
          )
        },
        maxWidth: 140

      }, {
        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            End
          </button>
        ),
        Cell: row => {
          return (
            <div>
              {
                <input
                  required type="time"
                  defaultValue={((row.original.appointmentId == this.state.editedAppointments.appointmentId) ||
                    (row.original.shiftId == this.state.editedAppointments.appointmentId)) && this.state.editedAppointments.endTime ? this.state.editedAppointments.endTime : row.original.endTime}
                  twelveHour="false"
                  className="form-control"
                  onChange={(event) => {
                    const rowId = row.original.appointmentId != undefined ? row.original.appointmentId : row.original.shiftId;
                    const id = this.state.editedAppointments.appointmentId;
                    if (!rowId == id) {
                      this.state.editedAppointments.startTime = undefined
                      this.state.editedAppointments.endTime = event.target.value
                      this.state.editedAppointments.location = undefined
                      this.state.editedAppointments.notes = undefined
                      this.state.editedAppointments.serviceId = 0
                      this.state.editedAppointments.clientId=0
                    } else {
                      this.state.editedAppointments.endTime = event.target.value
                    }
                    this.state.editedAppointments.appointmentId = row.original.appointmentId != undefined ? row.original.appointmentId : row.original.shiftId;
                  }}
                  name="endTime"
                />
              }
            </div>
          )
        },
        maxWidth: 140
      }, {
        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Services
          </button>
        ),
        Cell: row => {
          return (
            <div>
              <select
                onChange={(event)=>{
                  const id = this.state.editedAppointments.appointmentId;
                  const rowId = row.original.appointmentId != undefined ? row.original.appointmentId : row.original.shiftId;
                  if (!rowId == id) {
                    this.state.editedAppointments.startTime = undefined
                    this.state.editedAppointments.endTime = undefined
                    this.state.editedAppointments.location = undefined
                    this.state.editedAppointments.notes = undefined
                    this.state.editedAppointments.clientId =0
                    this.state.editedAppointments.serviceId = event.target.value
                  } else {
                    this.state.editedAppointments.serviceId = event.target.value;
                  }
                  this.state.editedAppointments.appointmentId = row.original.appointmentId != undefined ? row.original.appointmentId : row.original.shiftId;
                  
                }}
                onFocus={(event)=>{
                  const id = this.state.editedAppointments.appointmentId;
                  const rowId = row.original.appointmentId != undefined ? row.original.appointmentId : row.original.shiftId;
                  if (!rowId == id) {
                    this.state.editedAppointments.startTime = undefined
                    this.state.editedAppointments.endTime = undefined
                    this.state.editedAppointments.location = undefined
                    this.state.editedAppointments.notes = undefined
                    this.state.editedAppointments.clientId=0
                    this.state.editedAppointments.serviceId = event.target.value
                  } else {
                    this.state.editedAppointments.serviceId = event.target.value;
                  }
                  this.state.editedAppointments.appointmentId = row.original.appointmentId != undefined ? row.original.appointmentId : row.original.shiftId;
                  
                }}
                className="form-control"
                style={{ "width": "98%", "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis" }}
              >
                {
                  this.state.supportServices.map((element)=>
                    <option value={element.supportServiceId} selected={element.supportServiceId==row.original.serviceId?`selected`:``}>{element.supportService}</option>
                  )
                }
              </select>
             
            </div >
          )
        },
      }, {

        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Notes
          </button>
        ),
        Cell: row => {
          return (
            <div>
              {
                <textarea
                  type="text"
                  defaultValue={((row.original.appointmentId == this.state.editedAppointments.appointmentId) ||
                    (row.original.shiftId == this.state.editedAppointments.appointmentId)) && this.state.editedAppointments.notes ? this.state.editedAppointments.notes : row.original.notes}
                  className="form-control"
                  onChange={(event) => {
                    const rowId = row.original.appointmentId != undefined ? row.original.appointmentId : row.original.shiftId;
                    const id = this.state.editedAppointments.appointmentId;
                    if (!(rowId == id)) {
                      this.state.editedAppointments.startTime = undefined
                      this.state.editedAppointments.endTime = undefined
                      this.state.editedAppointments.location = undefined
                      this.state.editedAppointments.notes = event.target.value
                      this.state.editedAppointments.serviceId = 0
                      this.state.editedAppointments.clientId=0
                    } else {
                      this.state.editedAppointments.notes = event.target.value
                    }
                    this.state.editedAppointments.appointmentId = row.original.appointmentId != undefined ? row.original.appointmentId : row.original.shiftId
                  }}
                  name="notes" />
              }
            </div>
          )
        },
        maxWidth: 200
      }, {

        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            &nbsp;
          </button>
        ),
        Cell: row => {
          return (
            <div>
              {
                ((row.original.appointmentId == this.state.editedAppointments.appointmentId) ||
                  (row.original.shiftId == this.state.editedAppointments.appointmentId)) && this.state.isSubmitingInProgress ?
                  <button
                    type="button"
                    className="btn btn-sm btn-success btn-block mr-2"
                    dissabled
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                      Saving data...
                </button>
                  :
                  <button
                    onClick={() => {
                      this.setState({ isSubmitingInProgress: true })
                      this.handleSubmitEditedAppointment()
                    }
                    }
                    type="button"
                    className="btn btn-sm btn-success btn-block mr-2"
                  >
                    save
                </button>
              }
            </div>
          )
        },
        maxWidth: 60
      }]

    return (
      <div >
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 ml-2 mr-2">
          <h1 className="h2">Schedule</h1>
          <div className="btn-toolbar mb-2 mb-md-0">

            <button
              type="button"
              className="btn btn-sm btn-success mr-2"
              onClick={this.handleApproveTimesheets123}
            >
              Approve all
          </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-success mr-2"
            >

              {this.state.totalShifts.totalAdminConfirmed} Admin confirmed
          </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-success mr-2"
            >

              {this.state.totalShifts.totalCompleted} Completed

          </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-success mr-2"
            >

              {this.state.totalShifts.totalApproved} Approved

          </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-success mr-2"
            >
              {this.state.totalShifts.totalShifts} Shifts
          </button>

            <div className="btn-group mr-2">
              <button onClick={this.getPreviousWeek} type="button" className="btn btn-sm btn-outline-secondary">&#10094;</button>
              <button type="button" className="btn btn-sm btn-outline-secondary">
                <span data-feather="calendar"></span>{moment(this.state.weekStart).format('DD-MM-YYYY')} - {moment(this.state.weekEnd).format('DD-MM-YYYY')}</button>
              <button onClick={this.getNextWeek} type="button" className="btn btn-sm btn-outline-secondary">&#10095;</button>
            </div>
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary mr-2"
              onClick={() => {
                this.showCopyShiftsModal();
                this.calculatePasteWeek();
              }}
            >
              Copy week
          </button>

            <div className="btn-group mr-2">
              <a href={`${GlobalFunctions.extractToken().API_URL}/shifts/exportExcel?start=${moment(this.state.weekStart).format('MM-DD-YYYY')}&end=${moment(this.state.weekEnd).format('MM-DD-YYYY')}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`}
                type="button"
                className="btn btn-sm btn-outline-secondary"
              >
                &#8645; Export 
                </a>
            </div>
            <button

              type="button"
              className="btn btn-sm btn-outline-secondary mr-2"
              onClick={() => {
                this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
              }}
            >
              &#8635; Refresh
          </button>


          </div>
        </div>
        <div

        >
          <ReactTable
            data={this.state.DatabaseData}
            loading={this.state.loadingData}
            columns={columns}
            style={{
              // height: "600px" // This will force the table body to overflow and scroll, since there is not enough room

              height: "80vh",
              backgroundSize: "cover"
            }}
            defaultPageSize={50}
            getTdProps={(state, rowInfo, column) => {
              return {
                style: {
                  overflow: 'visible',
                  // background: 'LightGreen'
                  // background: rowInfo && rowInfo.row.staffId == -1 ? 'red' : null,
                }
              }
            }}
          />
        </div>

        {/* EDIT SHIFT MODAL */}
        <Modal show={this.state.ShowModal} onHide={true} size="lg" >
          <Modal.Header>
            <Modal.Title><h5 className="modal-title mr-5" id="exampleModalLabel">Edit shift on {this.state.selectedShiftDate}</h5></Modal.Title>
            <button
                className="btn btn-sm btn-outline-secondary mr-2"
                onClick={() => {
                  this.cancelAppointmentAlert()
                }}
              >
                Cancel Shift
            </button>
            {this.state.selectedShiftDetails.isApproved ? <button type="button" className="btn btn-outline-success btn-sm mr-3">Approved</button> : <button type="button" className="btn btn-outline-secondary btn-sm mr-3">Not approved</button>}
            {this.state.selectedShiftDetails.isApproved && this.state.selectedShiftDetails.isCompleted ? <button type="button" className="btn btn-outline-warning btn-sm">Shift completed</button> : <button type="button" className="btn btn-outline-secondary btn-sm">uncompleted shift</button>}

            <button type="button" className="close" onClick={this.hideModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row">

              <div className="col-md-12">
                <form>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor="inputEmail4">Who is working?</label>
                      <input className="form-control" name="staffId" value={this.state.selectedStaffName}></input>
                    </div>

                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor="inputAddress">Location</label>
                      <input defaultValue={this.state.selectedShiftDetails.clientLocation} type="text" className="form-control" name="address" onChange={event => this.setTitle(event.target)} placeholder="Location or Department" />
                    </div>

                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-4">
                      <label htmlFor="inputCity">Start time</label>
                      <input defaultValue={this.state.selectedShiftDetails.startTime} type="time" className="form-control" onChange={event => this.setTitle(event.target)} name="startTime" />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputCity">End time</label>
                      <input defaultValue={this.state.selectedShiftDetails.endTime} type="time" className="form-control" onChange={event => this.setTitle(event.target)} name="endTime" />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputCity">Break(min)</label>
                      <input defaultValue={this.state.selectedShiftDetails.breaking} type="number" className="form-control" onChange={event => this.setTitle(event.target)} name="breakMin" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleFormControlTextarea1">Notes</label>
                    <textarea defaultValue={this.state.selectedShiftDetails.notes} className="form-control" onChange={event => this.setTitle(event.target)} name="notes" rows="3"></textarea>
                  </div>
                </form>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {this.state.selectedShiftDetails.isApproved ? '' : <button onClick={this.handleApproveShift} type="button" className="btn btn-success">Approve shift</button>}
            {
              this.state.selectedShiftDetails.isCompleted ? ' ' :
                this.state.savingData ?
                  <button
                    className="btn btn-info"
                    onClick={this.handleEditShift}
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                      Saving data...
            </button>
                  :
                  <button
                    className="btn btn-info"
                    onClick={this.handleEditShift}
                  >
                    Save changes
            </button>
            }
          </Modal.Footer>
        </Modal>

        {/* ADD SHIFT MODAL */}
        <Modal show={this.state.ShowAddModal} onHide={true} size="lg" >
          <form onSubmit={this.handleSubmit}>
            <Modal.Header>
              <Modal.Title>
                <h5 className="modal-title" >Add office shift on {this.state.selectedShiftDate}</h5></Modal.Title>
              <button type="button" className="close" onClick={this.hideAddModal}>
                <span aria-hidden="true">&times;</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="row">

                <div className="col-md-12">

                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor="inputEmail4">Who is working?</label>
                      <input required className="form-control" name="staffId" value={this.state.selectedStaffName}></input>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor="inputAddress">Location</label>
                      <input required type="text" className="form-control" name="address" onChange={event => this.setTitle(event.target)} placeholder="Location or Department" />

                    </div>

                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-4">
                      <label htmlFor="inputCity">Start time</label>
                      <input required type="time" className="form-control" onChange={event => this.setTitle(event.target)} name="startTime" />

                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputCity">End time</label>
                      <input required type="time" className="form-control" onChange={event => this.setTitle(event.target)} name="endTime" />

                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputCity">Break(min)</label>
                      <input type="number" className="form-control" onChange={event => this.setTitle(event.target)} name="breakMin" />

                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleFormControlTextarea1">Notes1</label>
                    <textarea
                      className="form-control"

                      onChange={event => this.setTitle(event.target)} name="notes" rows="3"></textarea>

                  </div>

                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {
                this.state.savingData ?
                  <button
                    className="btn btn-info"
                    type="Submit">
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                      Saving data...
              </button>
                  :
                  <button
                    className="btn btn-info"
                    type="Submit">
                    Save shift
              </button>
              }

            </Modal.Footer>
          </form>
        </Modal>

        {/* ADD CLIENT SHIFT MODAL */}
        <Modal show={this.state.ShowAddAppointmentModal} onHide={true} size="xl"  >

          <Modal.Header>
            <Modal.Title>
              <h5 className="modal-title" >{this.state.selectedStaffName}
                <span className="modal-title" >[{moment(this.state.selectedShiftDate).format('DD-MM-YYYY')}]</span>
              </h5>
            </Modal.Title>
            <button type="button" className="close" onClick={this.hideAddAppointmentModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div class="row">
            <ul class="nav nav-tabs col-md-9" id="myTab" role="tablist">
              <li class="nav-item">
                <a 
                onClick={()=>{
                  this.setState({clientShiftOptionsShow:true})
                }}
                class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Clients shifts</a>
              </li>
              <li class="nav-item">
                <a 
                onClick={()=>{
                  this.setState({clientShiftOptionsShow:false})
                }}
                class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Add client shift</a>
              </li>
              <li class="nav-item">
                <a 
                onClick={()=>{
                  this.setState({clientShiftOptionsShow:false})
                }}
                class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Edit client shift</a>
              </li>
            </ul>
            {
              this.state.clientShiftOptionsShow?
              <div className="col-md-3" >
                <button
                    type="button"
                    class="btn btn-outline-success btn-sm mr-1"
                    onClick={() => {
                      this.handleApproveAppointments();
                    }}
                  >
                    <small >Approve</small>
                </button>
                <button
                    type="button"
                    class="btn btn-outline-primary btn-sm mr-1"
                    onClick={() => {
                      this.cancelAppointmentAlert();
                    }}
                  >
                    <small>Cancel</small>
                </button>
                <button
                    type="button"
                    class="btn btn-outline-warning btn-sm mr-1"
                    onClick={() => {
                      this.confirmAppointmentAlert();
                    }}
                  >
                    <small>Confirm</small>
                </button>
                <button
                      type="button"
                      class="btn btn-outline-danger btn-sm"
                      onClick={() => {
                        this.deleteAppointmentAlert()
                      }}
                    >
                      <small>Delete</small>
                </button>
              </div>
              :
              ''
            }
            </div>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <ReactTable
                  data={this.state.staffAppointments}
                  loading={this.state.loadingData}
                  columns={tableAppointments}
                  defaultPageSize={10}
                />
              </div>


              <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <form onSubmit={this.handleAddAppointment}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-row mt-2">
                        <div className="form-group col-md-6">
                          <label htmlFor="inputEmail4">Clients</label>
                          <select
                            required
                            className="form-control"
                            name="clientId"
                            onFocus={
                              (event) => {
                                this.setTitle(event.target);
                                var clientId = event.target.value
                                var supportAreaIds = [];
                                var supportServices = [];
                                var clientLocation = "";
                                this.setState({ clientId: clientId })

                                this.state.clients.forEach((element) => {
                                  if (element.id == clientId) {
                                    clientLocation = element.chaddress
                                  }
                                })
                                this.setState({ clientLocation: clientLocation })

                                this.state.supportServices.forEach((element) => {

                                  if (element.clientId == clientId) {
                                    supportServices.push(element);
                                  }
                                })
                                this.setState({ linkedSupportService: supportServices })
                              }
                            }
                            onChange={
                              (event) => {
                                this.setTitle(event.target);
                                var clientId = event.target.value
                                var supportAreaIds = [];
                                var supportServices = [];
                                var clientLocation = "";
                                this.setState({ clientId: clientId })

                                this.state.clients.forEach((element) => {
                                  if (element.id == clientId) {
                                    clientLocation = element.chaddress
                                  }
                                })
                                this.setState({ clientLocation: clientLocation })

                                this.state.supportServices.forEach((element) => {

                                  if (element.clientId == clientId) {
                                    supportServices.push(element);
                                  }
                                })

                                this.setState({ linkedSupportService: supportServices })

                              }
                            }
                          >
                            {
                              this.state.clients.map((element) =>
                                <option value={element.id}>{element.clientname}</option>
                              )
                            }
                          </select>
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="inputEmail4">Client services</label>
                          <select
                            required
                            onChange={(event) => {
                              this.setTitle(event.target)
                              var serviceId = event.target.value
                              var arr = []
                              arr = this.state.supportServices;
                              var index = arr.findIndex(x => x.supportServiceId == serviceId);
                              if (index >= 0) {
                                this.setState({ costPerHour: arr[index].servicePrice })
                              }
                            }
                            }
                            onFocus={(event) => {
                              this.setTitle(event.target)
                              var serviceId = event.target.value
                              var arr = []
                              arr = this.state.supportServices;
                              var index = arr.findIndex(x => x.supportServiceId == serviceId);
                              if (index >= 0) {
                                this.setState({ costPerHour: arr[index].servicePrice })
                              }

                            }
                            }
                            required
                            className="form-control"
                            name="clientServiceId" >
                            {
                              this.state.supportServices.map((element) =>
                                <option value={element.supportServiceId}>{element.supportService}</option>
                              )
                            }

                          </select>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <label htmlFor="inputAddress">Home Address</label>
                          <input required type="text" className="form-control" name="clientLocation" onChange={event => this.setTitle(event.target)} defaultValue={this.state.clientLocation}  />

                        </div>

                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <label htmlFor="inputCity">Start time</label>
                          <input required defaultValue={this.state.startTime} type="time" className="form-control" onChange={event => this.setTitle(event.target)} name="startTime" />

                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="inputCity">End time</label>
                          <input
                            required
                            type="time"
                            defaultValue={this.state.endTime}
                            className="form-control"
                            onChange={(event) => {
                              this.setTitle(event.target)
                              this.calculateDuration({ startTime: this.state.startTime, endTime: event.target.value })
                            }}
                            name="endTime" />
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="inputCity">Service cost(${parseFloat(this.state.costPerHour).toFixed(2)}/hour)</label>
                          <input disabled Value={parseFloat(parseFloat(this.state.costPerHour) * this.state.calculatedDuration).toFixed(2)} type="text" className="form-control" onChange={event => this.setTitle(event.target)} name="costPerHour" />
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleFormControlTextarea1">Notes</label>
                        <textarea defaultValue={this.state.notes} className="form-control" onChange={event => this.setTitle(event.target)} name="notes" rows="3"></textarea>

                      </div>
                    </div>
                  </div>
                  {
                    this.state.savingData ?
                      <button
                        className="btn btn-warning"
                        type="Submit"
                        disabled >
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      Saving data...
                   </button>
                      :
                      <button
                        className="btn btn-warning"
                        type="Submit">
                        Save appointment
                   </button>
                  }

                </form>
              </div>

              <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
  
                <ReactTable
                  data={this.state.staffAppointments}
                  loading={this.state.loadingData}
                  columns={tableEditAppointments}
                  defaultPageSize={10}
                  getTdProps={() => {
                    return {
                      style: {
                        overflow: 'visible'
                      }
                    }
                  }}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>

          </Modal.Footer>

        </Modal>


        {/* OPEN SHIFTS MODAL */}
        <Modal show={this.state.ShowOpenShiftsModal} onHide={true} size="lg" >
          <Modal.Header>
            <Modal.Title><h5 className="modal-title" >Edit shift on {this.state.selectedShiftDate}</h5></Modal.Title>
            <button type="button" className="close" onClick={this.hideOpenShiftsModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-3">
                <div className="media mb-3">
                  <span className="align-self-center mr-3" ></span>
                  <div className="media-body">
                    <h6 className="mt-0 ">0</h6>
                    <p className="mb-0">shift cost</p>
                  </div>
                </div>
                <div className="media">
                  <span className="align-self-center mr-3" ></span>
                  <div className="media-body">
                    <h6 className="mt-0 ">7.50</h6>
                    <p className="mb-0">Shift length</p>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <form>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="inputEmail4">Who is working?</label>
                      <input disabled="true" className="form-control" name="staffId" value={this.state.selectedStaffName}></input>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="inputPassword4">Working on who?</label>
                      <select onChange={event => this.setTitle(event.target)} name="clientName" className="form-control">

                        <option>dickon kudzai</option>
                        <option>englon kavhuru</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-4">
                      <label htmlFor="inputPassword4">Financial Package</label>
                      <select name="clientName" className="form-control">
                        <option>dickon kudzai</option>
                        <option>englon kavhuru</option>
                      </select>
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputPassword4">Support Area</label>
                      <select name="clientName" className="form-control">
                        <option>dickon kudzai</option>
                        <option>englon kavhuru</option>
                      </select>
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputPassword4">Support Service Area</label>
                      <select name="clientName" className="form-control">
                        <option>dickon kudzai</option>
                        <option>englon kavhuru</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="inputAddress">Home Address</label>
                      <input value={this.state.selectedShiftId} type="text" className="form-control" name="address" onChange={event => this.setTitle(event.target)} placeholder="Location" />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-4">
                      <label htmlFor="inputCity">Start time</label>
                      <input type="time" className="form-control" onChange={event => this.setTitle(event.target)} name="startTime" />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputCity">End time</label>
                      <input type="time" className="form-control" onChange={event => this.setTitle(event.target)} name="endTime" />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="inputCity">Break(min)</label>
                      <input type="number" className="form-control" onChange={event => this.setTitle(event.target)} name="breakMin" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleFormControlTextarea1">Notes</label>
                    <textarea className="form-control" onChange={event => this.setTitle(event.target)} name="notes" rows="3"></textarea>
                  </div>
                </form>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-info" onClick={this.handleSubmit}>Save shift</button>
          </Modal.Footer>
        </Modal>

        {/* COPY SHIFTS MODAL */}
        <Modal show={this.state.ShowCopyShiftsModal} onHide={true}  >
          <Modal.Header>
            <Modal.Title><h5 className="modal-title" >Copy shifts</h5></Modal.Title>
            <button type="button" className="close" onClick={this.hideCopyShiftsModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="row">

              <div className="col-md-12" style={{ height: "250px", overflowY: "scroll" }}>
                <form>
                  <div class="form-group">
                    {
                      this.state.getNextWeeks.map((element) =>
                        <div class="form-check border rounded mb-1 p-1">
                          <input
                            onClick={() => {
                              var arr = [];
                              arr = this.state.copiedWeekShifts;
                              var index = arr.findIndex(x => x.weekStart === element.weekStart);
                              if (index >= 0) {
                                arr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));

                              } else {
                                arr.push({ weekStart: element.weekStart, weekEnd: element.weekEnd });
                              }
                              this.setState({ copiedWeekShifts: arr });

                              arr = [];
                            }}
                            class="form-check-input ml-1"
                            type="checkbox"
                            id="gridCheck" />
                          <label class="form-check-label ml-4" for="gridCheck">
                            {moment(element.weekStart).format("DD-MM-YYYY")} to {moment(element.weekEnd).format("DD-MM-YYYY")}
                          </label>
                        </div>
                      )
                    }
                  </div>
                </form>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-warning" onClick={this.handleWeekSubmit}>Paste shifts</button>
          </Modal.Footer>
        </Modal>

      </div>

    )
  }
}



{/* ME COMPONENT */ }
class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DatabaseData: [],
      clients: [],
      finSupport: [],
      supportServices: [],
      linkedSupportAreas: [],
      linkedSupportService: [],
      linkedFinancialPackages: [],
      clientFin: [],
      servicePrice: 0,
      serviceCost: 0,

      clientId: 0,
      packageId: 0,
      supportAreaId: 0,
      supportServicesId: 0,
      staffId: 0,
      selectedStaffId: 0,
      selectedShiftId: 0,
      weekStart: '',
      weekEnd: '',
      selectedShiftDetails: {},
      financialPackageId: 0,
      staffList: [],
      timesheets: [],
      selectedTimesheetId: [],
      userDetails: {},
      selectedOpenShiftDetails: {},
      openShifts: [],
      weekStart: "",
      weekEnd: "",
      loadingData: true,
      savingData: false,
      staffAppointments: [],
      appointmentId: 0,
      totalTimesheetHours: 0

    };
    GlobalFunctions.extractToken();
    GlobalFunctions.requestUserFromAPI();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.editRow = this.editRow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.setTitle = this.setTitle.bind(this);
    this.getStaff = this.getStaff.bind(this);
    this.showAddModal = this.showAddModal.bind(this);
    this.hideAddModal = this.hideAddModal.bind(this);
    this.hideOpenShiftsModal = this.hideOpenShiftsModal.bind(this);
    this.showOpenShiftsModal = this.showOpenShiftsModal.bind(this);
    this.getClients = this.getClients.bind(this);
    this.deleteShift = this.deleteShift.bind(this);
    this.calculateWeekRange = this.calculateWeekRange.bind(this);
    this.getNextWeek = this.getNextWeek.bind(this);
    this.getPreviousWeek = this.getPreviousWeek.bind(this);
    this.handleEditShift = this.handleEditShift.bind(this);
    this.handleConfirmAttendance = this.handleConfirmAttendance.bind(this);
    this.handleConfirmShift = this.handleConfirmShift.bind(this);
    this.pasteShift = this.pasteShift.bind(this);
    this.getTimesheets = this.getTimesheets.bind(this);
    this.saveAlert = this.saveAlert.bind(this);
    this.deleteAlert = this.deleteAlert.bind(this);
    this.showAddAllowanceModal = this.showAddAllowanceModal.bind(this);
    this.hideAddAllowanceModal = this.hideAddAllowanceModal.bind(this);
    this.handleAddAllowance = this.handleAddAllowance.bind(this);
    
    this.confirmAlert = this.confirmAlert.bind(this);
    this.showClaimModal = this.showClaimModal.bind(this);
    this.hideClaimModal = this.hideClaimModal.bind(this);
    this.getOpenShifts = this.getOpenShifts.bind(this);
    this.handleClaimOpenShift = this.handleClaimOpenShift.bind(this);
    this.claimShiftAlert = this.claimShiftAlert.bind(this);
    this.ShowAddAppointmentModal = this.ShowAddAppointmentModal.bind(this);
    this.hideAddAppointmentModal = this.hideAddAppointmentModal.bind(this);
    this.getAppointments = this.getAppointments.bind(this);
    this.handleCompleteAppointments = this.handleCompleteAppointments.bind(this);
    this.networkFailureAlert = this.networkFailureAlert.bind(this);

  }

  getAppointments(event) {
    this.setState({ loadingData: true })

    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/getAppointments?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&shiftId=${event}`;
    axios.get(url1).then(client => {
      this.setState({ staffAppointments: client.data, loadingData: false });
    })
  }
  handleCompleteAppointments(event) {

    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/completeAppointment?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&appointmentId=${event.appointmentId}`;
    axios.get(url1).then(client => {
      if (client.status == 200) {
        this.getAppointments(event.shiftId)
        swal("shift successfully completed!", "", "success")
          .then(() => {

          })
      }

    }).catch(error => {
      let errorObject = JSON.parse(JSON.stringify(error));
      this.networkFailureAlert(errorObject.message)
    })
  }


  getOpenShifts(event) {
    this.setState({ loadingData: true })
    var url = `${GlobalFunctions.extractToken().API_URL}/shifts/all/openShifts?start=${event.start}&end=${event.end}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url)
      .then(res => {
        this.setState({ openShifts: res.data, loadingData: false })
      }).catch(error => {

      });
  }

  handleClaimOpenShift(event) {
    this.setState({ savingData: true });

    const infor = {
      "staffId": Number(GlobalFunctions.extractToken().STAFF_ID),
      "shiftId": Number(event.shiftId),
      "location": event.clientLocation,
      "Date": event.date,
      "startTime": event.startTime,
      "endTime": event.endTime,
      "notes": event.notes,
      "allowance": 0

    };
    if (!isNaN(event.shiftId)) {
      axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/add3?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
      .then(res => {
        this.setState({ savingData: false });
        if (res.status == 201) {
          this.claimShiftAlert();
        }

      }).catch(error => {
        this.setState({ savingData: false });
        let errorObject = JSON.parse(JSON.stringify(error));
        this.networkFailureAlert(errorObject.message)

      })
    }
      if(isNaN(event.shiftId)){
        var url = `${GlobalFunctions.extractToken().API_URL}/shifts/updateAppointment?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&appointmentId=${event.shiftId}`;
        axios.post(url, {staffId : Number(GlobalFunctions.extractToken().STAFF_ID)}).then(res=>{
          this.setState({ savingData: false });
          this.claimShiftAlert();
        }).catch(error => {
          this.setState({ savingData: false });
          let errorObject = JSON.parse(JSON.stringify(error));
          this.networkFailureAlert(errorObject.message)
        })
      }


  }

  networkFailureAlert(event) {
    swal(event, "", "warning")
      .then(() => {

      })
  }
  saveAlert() {
    swal("shift successfully saved!", "", "success")
      .then(() => {
        this.hideAddModal();
        this.hideAddAllowanceModal();
      })
  }
  claimShiftAlert() {
    swal("shift successfully claimed!", "", "success")
      .then(() => {
        this.hideAddModal();
        this.hideClaimModal();

        this.hideAddModal();
        this.hideAddAllowanceModal();

        this.getOpenShifts({ start: this.state.weekStart, end: this.state.weekEnd })

      })
  }

  deleteAlert() {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          swal("Poof! Your imaginary file has been deleted!", {
            icon: "success",
          });
        } else {
          swal("Your imaginary file is safe!");
        }
      })
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.name === 'isGoing' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    this.setState({ savingData: true })

    event.preventDefault();

    const infor = {
      "staffId": GlobalFunctions.extractToken().STAFF_ID,
      "location": this.state.address,
      "Date": this.state.date,
      "startTime": this.state.startTime,
      "endTime": this.state.endTime,
      "breaking": this.state.breakMin,
      "notes": this.state.notes,
      "allowance": 0,

    };

    this.state.address = undefined
    this.state.date = undefined
    this.state.startTime = undefined
    this.state.endTime = undefined
    this.state.breakMin = undefined
    this.state.notes = undefined

    axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/add?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
      .then(res => {
        this.setState({ savingData: false })
        if (res.status == 201) {
          this.saveAlert();
        }


      }).catch(error => {
        let errorObject = JSON.parse(JSON.stringify(error));
        this.networkFailureAlert(errorObject.message)

      })
  }

  handleAddAllowance(event) {
    this.setState({ savingData: true })

    event.preventDefault();

    if (this.state.appointmentId == 0) {

      var infor;
      if (isNaN(this.state.selectedShiftId)) {
        infor = {
          "appointmentId": Number(this.state.selectedShiftId.split("_")[1]),
          "description": this.state.description,
          "amount": this.state.amount,

        };
      } else {
        infor = {
          "shiftId": Number(this.state.selectedShiftId),
          "description": this.state.description,
          "amount": this.state.amount,

        };
      }


      this.state.amount = undefined
      this.state.description = undefined



      axios.post(`${GlobalFunctions.extractToken().API_URL}/timeSheets/allowance/add?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
        .then(res => {
          this.setState({ savingData: false })
          if (res.status == 201) {
            this.saveAlert();
          }
          this.state.amount = undefined
          this.state.description = undefined
          // this.hideAddModal();


        }).catch(error => {
          this.setState({ savingData: false })
          let errorObject = JSON.parse(JSON.stringify(error));
          this.networkFailureAlert(errorObject.message)

        })
    } else {
      const infor = {
        "appointmentId": Number(this.state.appointmentId),
        "description": this.state.description,
        "amount": this.state.amount,

      };

      this.state.amount = undefined
      this.state.description = undefined



      axios.post(`${GlobalFunctions.extractToken().API_URL}/timeSheets/allowance/add?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
        .then(res => {
          this.setState({ savingData: false })
          if (res.status == 201) {
            this.saveAlert();
          }
          this.state.amount = undefined
          this.state.description = undefined
          // this.hideAddModal();


        }).catch(error => {
          this.setState({ savingData: false })
          let errorObject = JSON.parse(JSON.stringify(error));
          this.networkFailureAlert(errorObject.message)

        })

      this.setState({ appointmentId: 0 })
    }


  }

  handleEditShift(event) {


    const infor = {
      "clientId": Number(this.state.clientId) == 0 ? Number(this.state.selectedShiftDetails.clientId) : Number(this.state.clientId),
      "location": this.state.address == undefined ? this.state.selectedShiftDetails.clientLocation : this.state.address,
      "Date": this.state.date == undefined ? this.state.selectedShiftDate : this.state.date,
      "startTime": this.state.startTime == undefined ? this.state.selectedShiftDetails.startTime : this.state.startTime,
      "endTime": this.state.endTime == undefined ? this.state.selectedShiftDetails.endTime : this.state.endTime,
      "breaking": this.state.breakMin == undefined ? this.state.selectedShiftDetails.breaking : this.state.breakMin,
      "notes": this.state.notes == undefined ? this.state.selectedShiftDetails.notes : this.state.notes,
      "hourlChage": this.state.serviveCost == undefined ? this.state.selectedShiftDetails.hourlChage : this.state.serviceCost,

      "supportAreaId": Number(this.state.supportAreaId) == 0 ? Number(this.state.selectedShiftDetails.supportAreaId) : Number(this.state.supportAreaId),
      "supportServiceId": Number(this.state.supportServicesId) == 0 ? Number(this.state.selectedShiftDetails.supportServiceId) : Number(this.state.supportServicesId),
      "packageId": Number(this.state.financialPackageId) == 0 ? Number(this.state.selectedShiftDetails.financialPackageId) : Number(this.state.financialPackageId)

    };




    axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/edit/${this.state.selectedShiftId}?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
      .then(res => {

        this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
        this.hideModal();

      }).catch(error => {
        let errorObject = JSON.parse(JSON.stringify(error));
        this.networkFailureAlert(errorObject.message)
      });
  }



  showModal() {
    this.setState({ ShowModal: true });
  }

  hideModal() {
    this.setState({ ShowModal: false });
  }
  showAddModal() {
    this.setState({ ShowAddModal: true });
  }
  showAddAllowanceModal() {
    this.setState({ ShowAddAllowanceModal: true });
  }
  hideAddAllowanceModal() {
    var url = `${GlobalFunctions.extractToken().API_URL}/timeSheets/timeSheets/one?staffId=${GlobalFunctions.extractToken().STAFF_ID}&start=${this.state.weekStart}&end=${this.state.weekEnd}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url).then(timesheet => {

      this.setState({ timesheets: timesheet.data })


    }).catch(e => {

    })
    this.setState({ ShowAddAllowanceModal: false });
  }

  hideAddModal() {
    this.setState({ ShowAddModal: false });
  }
  hideClaimModal() {
    this.setState({ ShowClaimModal: false });
  }
  hideAddAppointmentModal() {
    this.setState({ ShowAddAppointmentModal: false });
  }
  showOpenShiftsModal() {
    this.setState({ ShowOpenShiftsModal: true });
  }
  ShowAddAppointmentModal() {
    this.setState({ ShowAddAppointmentModal: true });
  }
  showClaimModal() {
    this.setState({ ShowClaimModal: true });
  }

  hideOpenShiftsModal() {
    this.setState({ ShowOpenShiftsModal: false });
  }

  setTitle(event) {
    this.setState({ [event.name]: event.value })


  }

  getTimesheets(event) {
    this.setState({ loadingData: true })
    var url = `${GlobalFunctions.extractToken().API_URL}/timeSheets/timeSheets/one?staffId=${GlobalFunctions.extractToken().STAFF_ID}&start=${event.weekStart}&end=${event.weekEnd}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url).then(timesheet => {
      var total = 0;
      timesheet.data.forEach(element => {
        total += element.duration
      })
      this.setState({ timesheets: timesheet.data, loadingData: false, totalTimesheetHours: total })

    }).catch(e => {

    })
  }


  calculateWeekRange() {
    let now = moment();
    const dayName = moment(now).format('dddd');
    var day = Number(now.weekday())
    var start = now.subtract(day - 1, 'days').format('MM-DD-YYYY');
    var end = now.add(6, 'days').format('MM-DD-YYYY');
    if(dayName=='Sunday'){
      end = moment(start).format('MM-DD-YYYY');
      end = moment(end).subtract(1, 'days').format('MM-DD-YYYY');
      start = moment(end).subtract(6, 'days').format('MM-DD-YYYY'); 
    }
    this.setState({ weekStart: start, weekEnd: end })
    this.getTimesheets({ weekStart: start, weekEnd: end });
    this.getOpenShifts({ start: start, end: end });

  }



  getClients() {
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/clients?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      this.setState({ clients: client.data });
    })

  }

  getFinSupport() {
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/finsupport?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      this.setState({ finSupport: client.data });
    })

  }

  getSupportServices() {
    var url1 = '${GlobalFunctions.extractToken().API_URL}/shifts/support-services';
    axios.get(url1).then(client => {
      this.setState({ supportServices: client.data });
    })

  }
  getClientFinancials() {
    var url1 = '${GlobalFunctions.extractToken().API_URL}/shifts/clientFin';
    axios.get(url1).then(client => {
      this.setState({ clientFin: client.data });
    })

  }

  deleteShift(selectedShiftId) {
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/delete/${this.state.selectedShiftId}?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      // this.setState({ clients: client.data });
      this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
      this.hideModal();
    })

  }
  cancelAppointmentAlert=(event)=>{
    swal({
      title: "You are about to Cancel an Client Shift?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
       if(willDelete){
        this.handleCancelClientAppointmentShift();
       }
      })
  }

  handleCancelClientAppointmentShift = () =>{
    var pos = 0;
    this.state.selectedTimesheetId.forEach(element=>{
      pos++;
      var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/cancelAppointment?appointmentId=${element}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
      axios.get(url1).then(client => {
        if(pos==this.state.selectedTimesheetId.length){
          this.getTimesheets({ weekStart: this.state.weekStart, weekEnd: this.state.weekEnd });
          swal("successfully cancelled!", " ", "success")
        }
      }).catch(error => {
        let errorObject = JSON.parse(JSON.stringify(error));
        this.networkFailureAlert(errorObject.message)
      })
    })
    
  }

  handleConfirmAttendance(selectedShiftId) {

    this.state.selectedTimesheetId.forEach(id => {
      if (isNaN(id)) {
        var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/completeAppointment?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&appointmentId=${id}`;
        axios.get(url1).then(client => {
          if (client.status == 200) {
            this.confirmAlert();
            this.getTimesheets({ weekStart: this.state.weekStart, weekEnd: this.state.weekEnd });

          }
        }).catch(error => {
          let errorObject = JSON.parse(JSON.stringify(error));
          this.networkFailureAlert(errorObject.message)

        })
      } else {
        var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/confirm?shiftId=${id}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
        axios.get(url1).then(client => {
          if (client.status == 200) {
            this.confirmAlert();
            this.getTimesheets({ weekStart: this.state.weekStart, weekEnd: this.state.weekEnd });

          }
        }).catch(error => {
          let errorObject = JSON.parse(JSON.stringify(error));
          this.networkFailureAlert(errorObject.message)

        })
      }
    }
    )
    this.setState({ selectedTimesheetId: [] })

  }

  confirmAlert() {
    swal("successfully confirmed!", " ", "success")
      .then(() => {
        this.hideModal();
      })
  }

  handleConfirmShift(selectedShiftId) {
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/confirm?shiftId=${this.state.selectedShiftId}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(client => {
      // this.setState({ clients: client.data });
      this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
      this.hideModal();
    }).catch(error => {
      let errorObject = JSON.parse(JSON.stringify(error));
      this.networkFailureAlert(errorObject.message)

    })

  }

  getNextWeek() {
    var start = moment(this.state.weekEnd).format('MM-DD-YYYY');
    start = moment(start).add(1, 'days').format('MM-DD-YYYY');
    var end = moment(start).add(6, 'days').format('MM-DD-YYYY')

    this.setState({ weekStart: start, weekEnd: end })
    this.getTimesheets({ weekStart: start, weekEnd: end });

  }

  getPreviousWeek() {
    var end = moment(this.state.weekStart).format('MM-DD-YYYY');
    end = moment(end).subtract(1, 'days').format('MM-DD-YYYY');
    var start = moment(end).subtract(6, 'days').format('MM-DD-YYYY');


    this.setState({ weekStart: start, weekEnd: end })
    this.getTimesheets({ weekStart: start, weekEnd: end });

  }

  pasteShift() {


    const infor = {
      "staffId": Number(this.state.selectedStaffId),
      "clientId": Number(this.state.selectedShiftDetails.clientId),
      "location": this.state.selectedShiftDetails.clientLocation,
      "Date": this.state.date,
      "startTime": this.state.selectedShiftDetails.startTime,
      "endTime": this.state.selectedShiftDetails.endTime,
      "breaking": this.state.selectedShiftDetails.breaking,
      "notes": this.state.selectedShiftDetails.notes,
      "allowance": 0
    };

    axios.post(`${GlobalFunctions.extractToken().API_URL}/shifts/add?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
      .then(res => {

        this.getShifts({ start: this.state.weekStart, end: this.state.weekEnd })
        this.hideModal();

      }).catch(error => {


      });
  }

  componentDidMount() {
    GlobalFunctions.requestUserFromAPI();
    console.log(GlobalFunctions.extractToken().API_URL);
    GlobalFunctions.extractToken();
    this.calculateWeekRange();
    this.getStaff();
    this.getClients();


  }

  getStaff() {

    //FETCHING ALL STAFF FROM API
    var url1 = `${GlobalFunctions.extractToken().API_URL}/shifts/staff?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
    axios.get(url1).then(staff => {

      this.setState({ staffList: staff.data });


    }).catch(error => {

    })
  }

  editRow(props) {

  }



  render() {


    const columns = [{
      Header: () => (
        <button
          onClick={() => {
            var arr = [];
            arr = this.state.selectedTimesheetId;
            if (arr.length == 0) {
              this.state.timesheets.forEach(element => {
                arr.push(element.shiftId)
              });
              this.setState({ selectedTimesheetId: arr });
            } else {
              this.setState({ selectedTimesheetId: [] });
            }
          }}
          className="btn text-white btn-warning btn-block"
          type="button">
          &#10003;
        </button>
      ),
      maxWidth: 50,
      Cell: row => {

        return (
          <div

          >
            <input
              onClick={() => {
                var arr = [];
                arr = this.state.selectedTimesheetId;
                var index = arr.findIndex(x => x == row.original.shiftId);
                if (index >= 0) {
                  arr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));
                } else {
                  arr.push(row.original.shiftId)
                }
                this.setState({ selectedTimesheetId: arr })
              }
              }
              type="checkbox" className="ml-2" checked={this.state.selectedTimesheetId.includes(row.original.shiftId) ? true : false}>
            </input>
          </div >
        )
      }

    },
    {
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          ClientName
        </button>
      ),
      accessor: "clientName",
      Cell : row =>{
        return (
          row.original.isConcelled?
            <del>{row.original.clientName}</del>
            :
            <div>{row.original.clientName}</div>
        )
      },
      maxWidth: 150,

    }, {

      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Date
        </button>
      ),
      Cell: row => {
        var date = row.original.date;
        date = moment(date).format('llll');
        date = date.split(" ");
        return (
          row.original.isConcelled?
          <del>
            <div>
              {
                date[0] + " " + date[1] + " " + date[2] + " " + date[3]
              }
            </div>
          </del>
          :
          <div>
            {
              date[0] + " " + date[1] + " " + date[2] + " " + date[3]
            }
          </div>

          
        )
      },
      maxWidth: 150,

    }, {
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Start
        </button>
      ),
      maxWidth: 70,
      Cell : row =>{
        return (
          row.original.isConcelled?
            <del>{row.original.startTime}</del>
            :
            <div>{row.original.startTime}</div>
        )
      },
      // accessor: 'startTime'

    }, {
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          End
        </button>
      ),
      maxWidth: 70,
      Cell : row =>{
        return (
          row.original.isConcelled?
            <del>{row.original.endTime}</del>
            :
            <div>{row.original.endTime}</div>
        )
      },
      // accessor: 'endTime'
    }, {

      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Location
        </button>
      ),
      minWidth: 185,
      Cell : row =>{
        return (
          row.original.isConcelled?
            <del>{row.original.location}</del>
            :
            <div>{row.original.location}</div>
        )
      },
      // accessor: 'location'
    }, {
      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Notes
        </button>
      ),
      minWidth: 185,
      Cell : row =>{
        return (
          row.original.isConcelled?
            <del>{row.original.notes}</del>
            :
            <div>{row.original.notes}</div>
        )
      },
      // accessor: 'notes'
    }, {

      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Hours
        </button>
      ),
      maxWidth: 75,
      Cell : row =>{
        return (
          row.original.isConcelled?
            <del>{row.original.duration}</del>
            :
            <div>{row.original.duration}</div>
        )
      },
      // accessor: 'duration'
    }, {

      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Allowance
        </button>
      ),
      maxWidth: 105,
      Cell: row => {

        return (
          <div>

            {
              row.original.allowance == 0 ?
                <button
                    onClick={() => {
                      this.setState({ selectedShiftId: row.original.shiftId })
                      this.showAddAllowanceModal()
                    }
                    }
                    type="button"
                    className="btn btn-sm btn-outline-success btn-block mr-2"
                  >
                    &#43;
              </button>
                :
                row.original.allowance
            }
          </div>
        )
      }
    }, {

      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          Status
        </button>
      ),
      minWidth: 150,
      
      Cell: row => {

        return (
          <div>
            {
              row.original.isApproved ? <a className="label label-info"><small><span className="badge badge-success ml-2">Approved</span></small></a> : <a className="label label-info"><small><span className="badge badge-danger ml-2">Not approved</span></small></a>
            }
            {
              row.original.isCompleted ? <a className="label label-info"><small><span className="badge badge-warning ml-2">Completed</span></small></a> : <a className="label label-info"><small><span className="badge badge-danger ml-2">Uncompleted</span></small></a>
            }
            {
              row.original.isAdminConfirmed ? <a className="label label-info"><small><span className="badge badge-info ml-2">Admin confirmed</span></small></a> : <a className="label label-info"><small><span className="badge badge-danger ml-2">Unconfirmed</span></small></a>
            }
          </div>
        )
      }
    }, {

      Header: () => (
        <button className="btn text-white btn-warning btn-block" type="button">
          &nbsp;
        </button>
      ),
      maxWidth: 300,
      Cell: row => {

        return (
          <div>

            {
              <button
                onClick={() => {
                  this.setState({ selectedShiftId: row.original.shiftId });
                  this.getAppointments(row.original.shiftId);
                  this.ShowAddAppointmentModal();

                }
                }
                type="button"
                className="btn btn-sm btn-outline-success mr-2"
              >
                View all
          </button>
            }
          </div>
        )
      },
      maxWidth: 80
    }]

    const tableAppointments = [
      {
        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Client name
          </button>
        ),
        accessor: 'clientName',
        maxWidth: 160

      }, {
        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Start
          </button>
        ),
        accessor: 'startTime',
        maxWidth: 70

      }, {
        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            End
          </button>
        ),
        accessor: 'endTime',
        maxWidth: 70
      }, {

        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Location
          </button>
        ),
        accessor: 'clientLocaton',
        maxWidth: 200

      }, {

        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Notes
          </button>
        ),
        accessor: 'notes',
        maxWidth: 250
      }, {
        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Actions
          </button>
        ),
        Cell: row => {
          return (
            <div>
              {
                <button
                  type="button"
                  class="btn btn-outline-dark btn-sm mr-1"
                  onClick={() => {
                    var id = row.original.appointmentId != undefined ? row.original.appointmentId : row.original.shiftId
                    this.setState({ appointmentId: id });
                    this.showAddAllowanceModal();

                  }}
                >
                  <small>&#43; Allowance</small>
                </button>

              }
              {

                row.original.isApproved ? <button
                  type="button"
                  class="btn btn-success btn-sm mr-1"
                >
                  <small>Approved</small>
                </button>
                  :
                  <button
                    type="button"
                    class="btn btn-outline-success btn-sm mr-1"
                  >
                    <small>UnApproved</small>
                  </button>

              }
              {
                row.original.isConfirmed ?
                  <button
                    type="button"
                    class="btn btn-warning btn-sm mr-1"

                  >
                    <small>Confirmed</small>
                  </button>
                  :
                  <button
                    type="button"
                    class="btn btn-outline-warning btn-sm mr-1"
                  >
                    <small>UnConfirmed</small>
                  </button>
              }

              {
                row.original.isComplete ?
                  <button
                    type="button"
                    class="btn btn-danger btn-sm"
                  >
                    <small>Completed</small>
                  </button>
                  :
                  <button
                    type="button"
                    class="btn btn-outline-danger btn-sm"
                    onClick={() => {
                      var id = row.original.appointmentId != undefined ? row.original.appointmentId : row.original.shiftId
                      this.handleCompleteAppointments({ appointmentId: id, shiftId: row.original.shiftId })
                    }}
                  >
                    <small>Complete</small>
                  </button>
              }

            </div>
          )
        }
      }]

    const columns2 = [
      {
        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Date
          </button>
        ),
        accessor: 'date'

      }, {
        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Start time
          </button>
        ),
        accessor: 'startTime'

      }, {
        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            End time
          </button>
        ),
        accessor: 'endTime'
      }, {

        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Location
          </button>
        ),
        accessor: 'clientLocation'

      }, {

        Header: () => (
          <button className="btn text-white btn-warning btn-block" type="button">
            Actions
          </button>
        ),
        Cell: row => {

          return (
            <div>
              {
                this.state.savingData ?
                  <button
                    disabled
                    type="button"
                    className="btn btn-sm btn-outline-success btn-block mr-2"
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                      Claiming...
                </button>
                  :
                  <button
                    onClick={() => {
                      this.handleClaimOpenShift(row.original)
                    }
                    }
                    type="button"
                    className="btn btn-sm btn-outline-success btn-block mr-2"
                  >
                    Claim shift
                </button>
              }
            </div>
          )
        }
      }]





    return (
      <div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 ml-2 mr-2">
          <h1 className="h2">Me</h1>
          <div className="btn-toolbar mb-2 mb-md-0">
            <button
              type="button"
              className="btn btn-sm btn-outline-success mr-2"
            >
              Total: {parseFloat(this.state.totalTimesheetHours).toFixed(2)} Hrs
          </button>
            <div className="btn-group mr-2">
              <button onClick={this.getPreviousWeek} type="button" className="btn btn-sm btn-outline-secondary">&#10094;</button>
              <button type="button" className="btn btn-sm btn-outline-secondary">
                <span data-feather="calendar"></span>{moment(this.state.weekStart).format('DD-MM-YYYY')} - {moment(this.state.weekEnd).format('DD-MM-YYYY')}</button>
              <button onClick={this.getNextWeek} type="button" className="btn btn-sm btn-outline-secondary">&#10095;</button>
            </div>

            <button
              onClick={() => {
                this.getOpenShifts({ start: this.state.weekStart, end: this.state.weekEnd });
                this.showClaimModal();
              }
              }
              type="button"
              className="btn btn-sm btn-outline-secondary mr-2"
            >
              Claim open shift(s)
          </button>
            <button
              onClick={this.showAddModal}
              type="button"
              className="btn btn-sm btn-outline-secondary mr-2"
            >
              &#43; Add shift
          </button>
            <button
              type="button"
              onClick={() => {
                this.cancelAppointmentAlert(this.state.selectedTimesheetId);
              }}
              className="btn btn-sm btn-outline-secondary mr-2"
            > &#10003; Cancel Shift(s)
          </button>
            <button
              type="button"
              onClick={this.handleConfirmAttendance}
              className="btn btn-sm btn-outline-secondary mr-2"
            > &#10003; Completed
          </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary mr-2"
            >

              {this.state.timesheets.length} Total shifts

          </button>
            <button

              type="button"
              className="btn btn-sm btn-outline-secondary mr-2"
              onClick={() => {
                this.getTimesheets({ weekStart: this.state.weekStart, weekEnd: this.state.weekEnd });
              }}
            >
              &#8635; Refresh
          </button>

          </div>
        </div>
        <div className="form-row">
          <div className="col-md-12">
            <ReactTable
              data={this.state.timesheets}
              loading={this.state.loadingData}
              columns={columns}
              defaultPageSize={10}
            />
          </div>
          <div className="col-md-6">
            <LinearChart />
          </div>
          <div className="col-md-6">
            <BarChart />
          </div>
          


          {/* CLAIM SHIFT MODAL */}
          <Modal show={this.state.ShowClaimModal} onHide={true} size="lg" >
            <Modal.Header>
              <Modal.Title><h5 className="modal-title" >Claim shift(s)</h5></Modal.Title>
              <button type="button" className="close" onClick={this.hideClaimModal}>
                <span aria-hidden="true">&times;</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="row">

                <div className="col-md-12">
                  <ReactTable
                    data={this.state.openShifts}
                    loading={this.state.loadingData}
                    columns={columns2}
                    defaultPageSize={10}
                  />
                </div>

              </div>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
          </Modal>
          {/* ADD SHIFT MODAL */}
          <Modal show={this.state.ShowAddModal} onHide={true} size="lg" >
            <form onSubmit={this.handleSubmit}>
              <Modal.Header>
                <Modal.Title><h5 className="modal-title" >Add shift</h5></Modal.Title>
                <button type="button" className="close" onClick={this.hideAddModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Header>
              <Modal.Body>
                <div className="row">

                  <div className="col-md-12">



                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="inputAddress">Location</label>
                        <input required type="text" className="form-control" name="address" onChange={event => this.setTitle(event.target)} placeholder="Location" />

                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="inputAddress">Date</label>
                        <input required type="date" className="form-control" name="date" onChange={event => this.setTitle(event.target)} />

                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label htmlFor="inputCity">Start time</label>
                        <input required type="time" className="form-control" onChange={event => this.setTitle(event.target)} name="startTime" />

                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="inputCity">End time</label>
                        <input required type="time" className="form-control" onChange={event => this.setTitle(event.target)} name="endTime" />

                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="inputCity">Break(min)</label>
                        <input type="number" className="form-control" onChange={event => this.setTitle(event.target)} name="breakMin" />

                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleFormControlTextarea1">Notes</label>
                      <textarea value={this.state.notes} className="form-control" onChange={event => this.setTitle(event.target)} name="notes" rows="3"></textarea>

                    </div>

                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {
                  this.state.savingData ?
                    <button
                      className="btn btn-warning"
                      type="Submit"
                      disabled>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Saving data...
                </button>
                    :
                    <button
                      className="btn btn-warning"
                      type="Submit">
                      Save shift
                </button>
                }

              </Modal.Footer>
            </form>
          </Modal>

          {/* ADD ALLOWANCE MODAL */}

          <Modal show={this.state.ShowAddAllowanceModal} onHide={true} size="lg" >
            <form onSubmit={this.handleAddAllowance}>
              <Modal.Header>
                <Modal.Title><h5 className="modal-title" >Add Allowance</h5></Modal.Title>
                <button type="button" className="close" onClick={this.hideAddAllowanceModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12">

                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="inputCity">Amount</label>
                        <input required type="number" className="form-control" onChange={event => this.setTitle(event.target)} name="amount" />


                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleFormControlTextarea1">Description</label>
                      <textarea required className="form-control" onChange={event => this.setTitle(event.target)} name="description" rows="3"></textarea>


                    </div>

                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {
                  this.state.savingData ?
                    <button
                      className="btn btn-warning"
                      type="Submit"
                      disabled
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Saving data...
                </button>
                    :
                    <button
                      className="btn btn-warning"
                      type="Submit"
                    >
                      Add allowance
                </button>
                }

              </Modal.Footer>
            </form>
          </Modal>

          {/* ADD CLIENT SHIFT MODAL */}
          <Modal show={this.state.ShowAddAppointmentModal} onHide={true} size="xl"  >

            <Modal.Header>
              <Modal.Title><h5 className="modal-title" >Client shifts</h5></Modal.Title>
              <button type="button" className="close" onClick={this.hideAddAppointmentModal}>
                <span aria-hidden="true">&times;</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Clients shifts</a>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">

                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <ReactTable
                    data={this.state.staffAppointments}
                    loading={this.state.loadingData}
                    columns={tableAppointments}
                    defaultPageSize={10}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>

          </Modal>

        </div>

      </div >
    )
  }
}

function LinearChart() {
  const data = React.useMemo(
    () => [
      {
        label: 'Series 1',
        data: [[0, 1], [1, 2], [2, 4], [3, 2], [4, 7]]
      }
    ],
    []
  )

  const axes = React.useMemo(
    () => [
      { primary: true, type: 'linear', position: 'bottom' },
      { type: 'linear', position: 'left' }
    ],
    []
  )

  return (
    <div
      style={{
        width: '400px',
        height: '300px'
      }}
    >
      <Chart data={data} axes={axes} tooltip />
    </div>
  )
}

function BarChart() {

  const data = React.useMemo(
    () => [
      {
        label: 'Series 1',
        data: [[0, 1], [1, 2], [2, 4], [3, 2], [4, 7]]
      }
    ],
    []
  )
  const series = React.useMemo(
    () => ({
      type: 'bar'
    }),
    []
  )
  const axes = React.useMemo(
    () => [
      { primary: true, type: 'ordinal', position: 'bottom' },
      { position: 'left', type: 'linear', stacked: false }
    ],
    []
  )
  return (
    <>
      <Chart data={data} series={series} axes={axes} tooltip />
    </>
  )
}



